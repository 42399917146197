<template>
  <v-card class="mx-auto" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <span
            class="text-h6 ml-1"
            v-text="
              `${$route.meta.title} ${
                loading
                  ? ''
                  : this.data_table && this.data_table.length > 0
                  ? `(${this.data_table.length})`
                  : ''
              }`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip v-if="login.role_id == 1 && data_table.length > 0" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                color="success"
                class="ml-1"
                @click="createXlsx"
              >
                <v-icon v-text="'mdi-table-arrow-down'" />
              </v-btn>
            </template>
            <span v-text="'Descargar XLSX'" />
          </v-tooltip>
          <v-tooltip bottom v-if="!user_view">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                color="info"
                class="ml-1"
                @click="video_dialog = true"
              >
                <v-icon v-text="'mdi-help'" />
              </v-btn>
            </template>
            <span v-text="'Mostrar el video de ayuda'" />
          </v-tooltip>
          <v-tooltip bottom v-if="user_view">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                color="grey"
                dark
                class="ml-1"
                @click="downloadZip()"
              >
                <v-icon v-text="'mdi-download'" />
              </v-btn>
            </template>
            <span v-text="`Descargar documentación de casos`" />
          </v-tooltip>
          <v-tooltip bottom v-if="user_view">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                color="warning"
                dark
                class="ml-1"
                @click="insurancePaymentsUnknowns()"
              >
                <v-icon v-text="'mdi-list-status'" />
              </v-btn>
            </template>
            <span v-text="'Núm. trámites no identificados'" />
          </v-tooltip>
          <v-tooltip bottom v-if="user_view">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                :color="select_all ? '' : 'teal'"
                dark
                class="ml-1"
                @click="selectAll()"
              >
                <v-icon v-text="'mdi-check-all'" />
              </v-btn>
            </template>
            <span
              v-text="`${select_all ? 'Des' : 'S'}eleccionar todos los pagos`"
            />
          </v-tooltip>
          <v-tooltip
            bottom
            v-if="
              user_view &&
              (login.role_id == 1 ||
                (login.permissions.rha_bills_send_insurance &&
                  login.permissions.rha_bills_send_insurance.update))
            "
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                dark
                color="pink darken-3"
                class="ml-1"
                :to="{
                  name: 'facturacion.envio_aseguradora',
                }"
              >
                <v-icon> mdi-clipboard-text-clock </v-icon>
              </v-btn>
            </template>
            <span v-text="'Envio aseguradora'" />
          </v-tooltip>
          <v-tooltip bottom v-if="user_view">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                dark
                color="brown darken-3"
                @click="insurance_pay_dialog = true"
                class="ml-1"
              >
                <v-icon v-text="'mdi-cash-refund'" />
              </v-btn>
            </template>
            <span v-text="'Aseguradora pago'" />
          </v-tooltip>
          <v-tooltip bottom v-if="user_view">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                dark
                color="orange darken-3"
                @click="payDialog"
                class="ml-1"
              >
                <v-icon v-text="'mdi-account-cash'" />
              </v-btn>
            </template>
            <span v-text="'Pago hospital(es)'" />
          </v-tooltip>
          <v-tooltip bottom v-if="user_view">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                dark
                color="teal darken-3"
                class="ml-1"
                :to="{
                  name: 'facturacion.ordenes_pago',
                }"
              >
                <v-icon v-text="'mdi-cash-sync'" />
              </v-btn>
            </template>
            <span v-text="'Ordenes de pago'" />
          </v-tooltip>
          <v-tooltip v-if="user_view" bottom>
            <template v-slot:activator="{ on }">
              <v-badge
                overlap
                :color="
                  (provider_comments_pending > 0 ? 'deep-orange' : 'cyan') +
                  ' darken-1'
                "
                :content="provider_comments_pending"
                :dot="provider_comments_pending == 0"
              >
                <v-btn
                  v-on="on"
                  fab
                  dark
                  x-small
                  class="ml-1"
                  :to="{
                    name: 'facturacion.providers.comments',
                  }"
                  :color="
                    (provider_comments_pending > 0 ? 'deep-orange' : 'cyan') +
                    ' darken-1'
                  "
                  :loading="provider_comments_pending_ldg"
                >
                  <v-icon> mdi-comment-question </v-icon>
                </v-btn>
              </v-badge>
            </template>
            <span v-text="'Fact. Ingresos | Preguntas o comentarios'" />
          </v-tooltip>
          <v-tooltip v-else bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                dark
                x-small
                class="ml-1"
                color="info"
                :to="{
                  name: 'facturacion.providers.comments',
                }"
              >
                <v-icon> mdi-comment-question </v-icon>
              </v-btn>
            </template>
            <span v-text="'Preguntas o comentarios'" />
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-badge
                overlap
                :color="
                  (bill_comments_pending > 0 ? 'deep-orange' : 'cyan') +
                  ' darken-4'
                "
                :content="bill_comments_pending"
                :dot="bill_comments_pending == 0"
              >
                <v-btn
                  v-on="on"
                  fab
                  dark
                  x-small
                  class="ml-1"
                  :to="{
                    name: 'rha_bills.bill_comments',
                  }"
                  :color="
                    (bill_comments_pending > 0 ? 'deep-orange' : 'cyan') +
                    ' darken-4'
                  "
                  :loading="bill_comments_pending_ldg"
                >
                  <v-icon> mdi-comment-check </v-icon>
                </v-btn>
              </v-badge>
            </template>
            <span v-text="'Fact. | Comentarios aseguradoras'" />
          </v-tooltip>
          <v-btn
            v-if="!user_view"
            small
            :disabled="loading"
            color="primary"
            @click="searchDialog"
          >
            Busqueda de ingreso hospitalario
            <v-icon right> mdi-magnify </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" xs="12" md="4">
          <v-select
            label="Selecciona una opción..."
            v-model="filter"
            :items="filter_options"
            item-value="id"
            :item-text="(item) => item.value"
            dense
            @change="getData"
          />
        </v-col>
        <v-col
          cols="12"
          xs="12"
          md="3"
          v-if="user_view && filter && data_table.length > 0"
        >
          <v-autocomplete
            label="Aseguradora(s)*"
            v-model="insurances_selected"
            :items="insurances_multi"
            item-value="id"
            :item-text="(v) => v.name"
            :loading="insurances_multi_loading"
            dense
            multiple
            @change="getData"
          />
        </v-col>
        <v-col cols="12" xs="12" md="5" v-if="data_table.length > 0">
          <v-text-field
            v-model="search_table"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
            dense
          />
        </v-col>
        <v-col
          cols="12"
          v-if="user_view && filter == 6 && data_table.length > 0"
        >
          <v-select
            label="Selección de estado de pagos"
            v-model="daily_payments"
            :items="daily_payments_multi"
            item-value="id"
            :item-text="(v) => v.name"
            dense
            multiple
            @change="getData"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" v-if="id">
          <v-alert type="info" dismissible dense>
            <div
              class="text-center"
              v-text="
                `Documentación para el ingreso ${rha_folio} registrada | F. de recepción ${
                  reception_date ? reception_date : 'PENDIENTE'
                } | F. probable de pago ${pay_date ? pay_date : 'PENDIENTE'}`
              "
            />
          </v-alert>
        </v-col>
        <v-col cols="12" v-if="bill_error_alert">
          <v-alert type="error" v-model="bill_error_alert" dismissible dense>
            <v-row align="center">
              <v-col class="grow">
                <div class="text-center" v-text="`${bill_error}`" />
              </v-col>
              <v-col class="shrink">
                <v-tooltip
                  bottom
                  v-if="user_view && (bill_error_0x006 || bill_error_0x007)"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      x-small
                      fab
                      color="info"
                      @click="amount_dialog = true"
                    >
                      <v-icon v-text="'mdi-content-save-edit'" />
                    </v-btn>
                  </template>
                  <span v-text="'Editar información'" />
                </v-tooltip>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
        <v-col cols="12" v-if="alert_cancel">
          <v-alert type="error" v-model="alert_cancel" dismissible dense>
            <div class="text-center" v-text="`${alert_cancel_msg}`" />
          </v-alert>
        </v-col>
        <v-col cols="12" xs="12">
          <v-data-table
            :headers="headers"
            :items="data_table"
            :loading="loading"
            :search="search_table"
            item-key="rha_bill_id"
            :items-per-page="15"
            dense
          >
            <template v-slot:item.folio="{ item }">
              <div>
                <span class="font-weight-bold" v-text="item.folio" />
              </div>
            </template>
            <template v-slot:item.episode="{ item }">
              <div>
                <span
                  :class="item.episode ? '' : 'orange--text text--darken-3'"
                  v-text="item.episode ? item.episode : 'NO REG.'"
                />
              </div>
            </template>
            <template v-slot:item.enrollment="{ item }">
              <div>
                <span
                  :class="item.insured_id ? '' : 'orange--text text--darken-3'"
                  v-text="item.enrollment"
                />
              </div>
            </template>
            <template v-slot:item.full_name="{ item }">
              <div>
                <span
                  :class="item.insured_id ? '' : 'orange--text text--darken-3'"
                  v-text="item.full_name"
                />
              </div>
            </template>
            <template v-slot:item.rha_bill_consignment="{ item }">
              <div>
                <span
                  v-text="
                    item.rha_bill_consignment ? item.rha_bill_consignment : '-'
                  "
                />
              </div>
            </template>
            <template v-slot:item.rha_bill_reception_date="{ item }">
              <div>
                <span
                  :class="
                    item.rha_bill_reception_date
                      ? ''
                      : 'orange--text text--darken-3'
                  "
                  v-text="
                    item.rha_bill_reception_date
                      ? item.rha_bill_reception_date
                      : item.rha_bill_id
                      ? '-'
                      : 'NO REGISTRADA'
                  "
                />
              </div>
            </template>
            <template v-slot:item.rha_bill_pay_date="{ item }">
              <div>
                <span
                  :class="
                    item.rha_bill_pay_date ? '' : 'orange--text text--darken-3'
                  "
                  v-text="
                    item.rha_bill_pay_date
                      ? item.rha_bill_pay_date
                      : item.rha_bill_id
                      ? '-'
                      : 'NO REGISTRADA'
                  "
                />
              </div>
            </template>
            <template v-slot:item.rha_bill_pay_amount="{ item }">
              <div>
                <span
                  :class="
                    parseFloat(item.rha_bill_pay_amount)
                      ? ''
                      : 'orange--text text--darken-3'
                  "
                  v-text="
                    parseFloat(item.rha_bill_pay_amount)
                      ? numberFormat(item.rha_bill_pay_amount) +
                        (item.complement_payment ? '*' : '')
                      : item.rha_bill_id
                      ? '-'
                      : 'NO REG.'
                  "
                />
              </div>
            </template>
            <template v-slot:item.rha_bill_bills_amount="{ item }">
              <div>
                <span
                  :class="
                    item.rha_bill_bills_amount
                      ? ''
                      : 'orange--text text--darken-3'
                  "
                  v-text="
                    parseFloat(item.rha_bill_bills_amount)
                      ? numberFormat(item.rha_bill_bills_amount)
                      : item.rha_bill_id
                      ? '-'
                      : 'NO REG.'
                  "
                />
              </div>
            </template>
            <template v-slot:item.rha_bill_notes_amount="{ item }">
              <div>
                <span
                  :class="
                    item.rha_bill_notes_amount
                      ? ''
                      : 'orange--text text--darken-3'
                  "
                  v-text="
                    parseFloat(item.rha_bill_notes_amount)
                      ? numberFormat(item.rha_bill_notes_amount)
                      : item.rha_bill_id
                      ? '-'
                      : 'NO REG.'
                  "
                />
              </div>
            </template>
            <template v-slot:item.rha_bill_paid_amount="{ item }">
              <div>
                <span
                  v-text="
                    item.rha_bill_paid_amount
                      ? numberFormat(item.rha_bill_paid_amount)
                      : ''
                  "
                />
              </div>
            </template>
            <template
              v-slot:item.rha_bill_insurance_reception_date_time="{ item }"
            >
              <div>
                <span
                  :class="
                    item.rha_bill_insurance_reception_date_time
                      ? ''
                      : 'orange--text text--darken-3'
                  "
                  v-text="
                    item.rha_bill_insurance_reception_date_time
                      ? item.rha_bill_insurance_reception_date_time
                      : item.rha_bill_id
                      ? '-'
                      : 'NO REG.'
                  "
                />
              </div>
            </template>
            <template v-slot:item.rha_bill_insurance_pay_date="{ item }">
              <div>
                <span
                  :class="
                    item.rha_bill_insurance_pay_date
                      ? ''
                      : 'orange--text text--darken-3'
                  "
                  v-text="
                    item.rha_bill_insurance_pay_date
                      ? item.rha_bill_insurance_pay_date
                      : item.rha_bill_id
                      ? '-'
                      : 'NO REG.'
                  "
                />
              </div>
            </template>
            <template
              v-slot:item.rha_bill_insurance_payment_paid_date="{ item }"
            >
              <span v-if="item.rha_bill_insurance_payment_paid_date">
                {{ item.rha_bill_insurance_payment_paid_date }}
                <span
                  v-if="item.rha_bill_insurance_payment_manual"
                  v-text="'*'"
                />
              </span>
              <span v-else v-text="'-'" />
            </template>
            <template
              v-slot:item.rha_bill_insurance_payment_refused_date="{ item }"
            >
              <span v-if="item.rha_bill_insurance_payment_refused_date">
                {{ item.rha_bill_insurance_payment_refused_date }}
                <span
                  v-if="item.rha_bill_insurance_payment_manual"
                  v-text="'*'"
                />
              </span>
              <span v-else v-text="'-'" />
            </template>
            <template v-slot:item.rha_bill_insurance_procedure="{ item }">
              <div>
                <span
                  :class="
                    item.rha_bill_insurance_procedure
                      ? ''
                      : 'orange--text text--darken-3'
                  "
                  v-text="
                    item.rha_bill_insurance_procedure
                      ? item.rha_bill_insurance_procedure
                      : item.rha_bill_id
                      ? '-'
                      : 'NO REG.'
                  "
                />
              </div>
              <div v-if="item.insurance_payment_refused">
                <small class="red--text" v-text="'RECHAZADO'" />
              </div>
            </template>
            <template v-slot:item.rha_bill_insurance_paid_date="{ item }">
              <div>
                <span
                  :class="
                    item.rha_bill_insurance_paid_date
                      ? ''
                      : 'orange--text text--darken-3'
                  "
                  v-text="
                    item.rha_bill_insurance_paid_date
                      ? item.rha_bill_insurance_paid_date
                      : item.rha_bill_id
                      ? '-'
                      : 'NO REG.'
                  "
                />
              </div>
            </template>
            <template v-slot:item.rha_bill_insurance_pay_amount="{ item }">
              <div>
                <span
                  v-text="numberFormat(item.rha_bill_insurance_pay_amount)"
                />
              </div>
            </template>
            <template v-slot:item.rha_bill_insurance_paid_amount="{ item }">
              <div>
                <span
                  :class="
                    parseFloat(item.rha_bill_insurance_paid_amount) ==
                    parseFloat(item.rha_bill_insurance_pay_amount)
                      ? ''
                      : 'orange--text text--darken-3'
                  "
                  v-text="
                    parseFloat(item.rha_bill_insurance_paid_amount)
                      ? numberFormat(item.rha_bill_insurance_paid_amount)
                      : item.rha_bill_id
                      ? '-'
                      : 'NO REG.'
                  "
                />
              </div>
            </template>

            <template v-slot:item.contractor="{ item }">
              <div>
                <span
                  :class="item.insured_id ? '' : 'orange--text text--darken-3'"
                  v-text="item.contractor"
                />
              </div>
            </template>
            <template v-slot:item.rha_bill_observation="{ item }">
              <div>
                <span
                  v-text="
                    item.rha_bill_observation ? item.rha_bill_observation : '-'
                  "
                />
              </div>
            </template>
            <template v-slot:item.select="{ item }">
              <span
                v-if="item.rha_bill_payment_item"
                v-text="
                  item.rha_bill_paid_date
                    ? item.rha_bill_complement_folio
                      ? 'Terminado'
                      : 'Pagado | Pendiente complemento de pago'
                    : 'En proceso de pago'
                "
              />
              <div v-else>
                <!-- v-if=" user_view && !item.paid_date &&
                parseFloat(item.rha_bill_insurance_paid_amount) >=
                parseFloat(item.rha_bill_insurance_pay_amount) " -->
                <v-simple-checkbox
                  v-if="user_view"
                  v-model="item.select"
                  :ripple="false"
                />
              </div>
            </template>
            <template v-slot:item.download_docs="{ item }">
              <v-simple-checkbox
                v-if="user_view"
                v-model="item.download_docs"
                :ripple="false"
              />
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip left v-if="user_view">
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    v-on="on"
                    fab
                    x-small
                    color="info"
                    @click.prevent="rhaDocumentsDialog(item.id, item.folio)"
                  >
                    <v-icon v-text="'mdi-file'" />
                  </v-btn>
                </template>
                <span v-text="`Ver documentación del ingreso`" />
              </v-tooltip>
              <v-tooltip left v-if="!item.rha_bill_to_stamp">
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    v-on="on"
                    fab
                    x-small
                    :color="
                      item.rha_bill_observation
                        ? item.rha_bill_provider_response
                          ? 'red darken-1'
                          : 'error'
                        : 'warning'
                    "
                    @click.prevent="documentationDialog(item.rha_bill_id)"
                  >
                    <v-icon v-text="'mdi-folder-alert'" />
                  </v-btn>
                </template>
                <span
                  v-text="
                    `${
                      item.rha_bill_observation
                        ? item.rha_bill_provider_response
                          ? 'Respuesta rechazo'
                          : 'Rechazo'
                        : 'Revisión'
                    } de documentación`
                  "
                />
              </v-tooltip>
              <v-tooltip left v-if="item.rha_bill_to_stamp">
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    v-on="on"
                    fab
                    dark
                    x-small
                    :color="
                      item.rha_bill_observation ? 'red darken-3' : 'success'
                    "
                    @click.prevent="documentationDialog(item.rha_bill_id)"
                  >
                    <v-icon v-text="'mdi-folder-eye'" />
                  </v-btn>
                </template>
                <span
                  v-text="
                    `${
                      item.rha_bill_observation ? 'Observación en ' : 'Ver'
                    } documentación`
                  "
                />
              </v-tooltip>
              <v-tooltip
                left
                v-if="
                  user_view &&
                  item.rha_bill_to_stamp &&
                  item.rha_bill_stamp_id == null &&
                  item.bill_generated
                "
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    v-on="on"
                    fab
                    x-small
                    color="warning"
                    @click.prevent="billDialog(item, false)"
                  >
                    <v-icon v-text="'mdi-file-check'" />
                  </v-btn>
                </template>
                <span v-text="'Generar factura'" />
              </v-tooltip>
              <v-tooltip left v-if="user_view && item.rha_bill_stamp_id">
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    v-on="on"
                    fab
                    x-small
                    dark
                    color="cyan darken-3"
                    @click.prevent="downloadFile(item.rha_bill_stamp_id, 'pdf')"
                  >
                    <v-icon v-text="'mdi-file-download'" />
                  </v-btn>
                </template>
                <span v-text="'Descargar PDF'" />
              </v-tooltip>
              <v-tooltip left v-if="user_view && item.rha_bill_stamp_id">
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    v-on="on"
                    fab
                    x-small
                    dark
                    color="teal darken-2"
                    @click.prevent="downloadFile(item.rha_bill_stamp_id, 'xml')"
                  >
                    <v-icon v-text="'mdi-xml'" />
                  </v-btn>
                </template>
                <span v-text="'Descargar XML'" />
              </v-tooltip>
              <v-tooltip
                left
                v-if="
                  user_view &&
                  (item.rha_bill_stamp_id ||
                    (item.rha_bill_to_stamp && !item.bill_generated))
                "
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    v-on="on"
                    fab
                    x-small
                    dark
                    :color="
                      !item.insurance_payment_refused
                        ? item.rha_bill_insurance_procedure
                          ? item.rha_bill_insurance_paid_date
                            ? 'deep-purple darken-2'
                            : 'pink darken-2'
                          : 'grey'
                        : 'error'
                    "
                    @click.prevent="
                      documentationInsuranceDialog(item.rha_bill_id)
                    "
                  >
                    <v-icon v-text="'mdi-folder-star-multiple'" />
                  </v-btn>
                </template>
                <span v-text="'Documentación aseguradora'" />
              </v-tooltip>
              <v-tooltip left v-if="item.rha_bill_paid_date">
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    v-on="on"
                    fab
                    x-small
                    dark
                    :color="
                      item.rha_bill_complement_folio ? 'teal darken-2' : 'grey'
                    "
                    @click.prevent="complementDialog(item)"
                  >
                    <v-icon v-text="'mdi-file-sign'" />
                  </v-btn>
                </template>
                <span v-text="'Complemento de pago'" />
              </v-tooltip>
              <v-tooltip
                left
                v-if="item.rha_bill_clone_amount && login.role_id == 1"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    v-on="on"
                    fab
                    x-small
                    dark
                    color="warning"
                    @click.prevent="rhaBillCloneDialog(item)"
                  >
                    <v-icon v-text="'mdi-receipt-text-plus-outline'" />
                  </v-btn>
                </template>
                <span v-text="'Generar un nuevo pago'" />
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- DIALOGS -->
    <v-dialog v-model="search_dialog" scrollable persistent max-width="1400px">
      <v-card
        tile
        v-if="search_dialog"
        :disabled="search_dialog_loading"
        :loading="search_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title v-text="`Busqueda de ingreso hospitalario`" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="search_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row dense class="pt-5">
            <v-col cols="12" xs="12" md="3">
              <v-text-field
                v-model="search.folio"
                label="Folio"
                v-on:keyup.enter="searchRhaBills"
                hide-details
                dense
              />
            </v-col>
            <v-col cols="12" xs="12" md="3">
              <v-text-field
                v-model="search.episode"
                label="Número de episodio"
                v-on:keyup.enter="searchRhaBills"
                hide-details
                dense
              />
            </v-col>
            <v-col cols="12" xs="12" md="3">
              <v-text-field
                v-model="search.enrollment"
                label="ID SM / Póliza"
                v-on:keyup.enter="searchRhaBills"
                hide-details
                dense
              />
            </v-col>
            <v-col cols="12" xs="12" md="3">
              <v-btn
                block
                small
                :loading="loading"
                :disabled="loading"
                color="primary"
                @click="searchRhaBills"
              >
                Buscar
                <v-icon right v-text="'mdi-magnify'" />
              </v-btn>
            </v-col>
            <v-col cols="12" v-if="rha_bill_search_alert">
              <v-alert
                type="warning"
                v-model="rha_bill_search_alert"
                dismissible
                dense
              >
                <v-row align="center">
                  <v-col class="grow">
                    <div
                      class="text-center"
                      v-text="`${rha_bill_search_message}`"
                    />
                  </v-col>
                  <v-col class="shrink">
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          x-small
                          fab
                          color="success"
                          @click="rhaStoreDialog"
                        >
                          <v-icon v-text="'mdi-content-save-edit'" />
                        </v-btn>
                      </template>
                      <span v-text="'Crear ingreso hospitalario'" />
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
          </v-row>
          <v-row v-if="rha_id">
            <v-col cols="12">
              <RhaData :rha_id="rha_id" />
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="headers_search"
                :items="search_data_table"
                item-key="rha_bill_id"
              >
                <template v-slot:item.folio="{ item }">
                  <div>
                    <span class="font-weight-bold" v-text="item.folio" />
                  </div>
                </template>
                <template v-slot:item.episode="{ item }">
                  <div>
                    <span
                      :class="item.episode ? '' : 'orange--text text--darken-3'"
                      v-text="item.episode ? item.episode : 'NO REG.'"
                    />
                  </div>
                </template>
                <template v-slot:item.enrollment="{ item }">
                  <div>
                    <span
                      :class="
                        item.insured_id ? '' : 'orange--text text--darken-3'
                      "
                      v-text="item.enrollment"
                    />
                  </div>
                </template>
                <template v-slot:item.full_name="{ item }">
                  <div>
                    <span
                      :class="
                        item.insured_id ? '' : 'orange--text text--darken-3'
                      "
                      v-text="item.full_name"
                    />
                  </div>
                </template>
                <template v-slot:item.rha_bill_reception_date="{ item }">
                  <div>
                    <span
                      :class="
                        item.rha_bill_reception_date
                          ? ''
                          : 'orange--text text--darken-3'
                      "
                      v-text="
                        item.rha_bill_reception_date
                          ? item.rha_bill_reception_date
                          : item.rha_bill_id
                          ? '-'
                          : 'NO REGISTRADA'
                      "
                    />
                  </div>
                </template>
                <template v-slot:item.rha_bill_pay_date="{ item }">
                  <div>
                    <span
                      :class="
                        item.rha_bill_pay_date
                          ? ''
                          : 'orange--text text--darken-3'
                      "
                      v-text="
                        item.rha_bill_pay_date
                          ? item.rha_bill_pay_date
                          : item.rha_bill_id
                          ? '-'
                          : 'NO REGISTRADA'
                      "
                    />
                  </div>
                </template>
                <template v-slot:item.rha_bill_pay_amount="{ item }">
                  <div>
                    <span
                      :class="
                        item.rha_bill_id &&
                        item.to_stamp &&
                        item.rha_bill_pay_amount
                          ? ''
                          : 'orange--text text--darken-3'
                      "
                      v-text="
                        item.rha_bill_id &&
                        item.to_stamp &&
                        item.rha_bill_pay_amount
                          ? numberFormat(item.rha_bill_pay_amount)
                          : item.rha_bill_id
                          ? '-'
                          : 'NO REG.'
                      "
                    />
                  </div>
                </template>
                <template v-slot:item.rha_bill_paid_date="{ item }">
                  <div>
                    <span
                      :class="
                        item.rha_bill_paid_date
                          ? ''
                          : 'orange--text text--darken-3'
                      "
                      v-text="
                        item.rha_bill_paid_date ? item.rha_bill_paid_date : '-'
                      "
                    />
                  </div>
                </template>
                <template
                  v-slot:item.rha_bill_insurance_reception_date_time="{ item }"
                >
                  <div>
                    <span
                      :class="
                        item.rha_bill_insurance_reception_date_time
                          ? ''
                          : 'orange--text text--darken-3'
                      "
                      v-text="
                        item.rha_bill_insurance_reception_date_time
                          ? item.rha_bill_insurance_reception_date_time
                          : item.rha_bill_id
                          ? '-'
                          : 'NO REG.'
                      "
                    />
                  </div>
                </template>
                <template v-slot:item.rha_bill_insurance_pay_date="{ item }">
                  <div>
                    <span
                      :class="
                        item.rha_bill_insurance_pay_date
                          ? ''
                          : 'orange--text text--darken-3'
                      "
                      v-text="
                        item.rha_bill_insurance_pay_date
                          ? item.rha_bill_insurance_pay_date
                          : item.rha_bill_id
                          ? '-'
                          : 'NO REG.'
                      "
                    />
                  </div>
                </template>
                <template v-slot:item.rha_bill_insurance_procedure="{ item }">
                  <div>
                    <span
                      :class="
                        item.rha_bill_insurance_procedure
                          ? ''
                          : 'orange--text text--darken-3'
                      "
                      v-text="
                        item.rha_bill_insurance_procedure
                          ? item.rha_bill_insurance_procedure
                          : item.rha_bill_id
                          ? '-'
                          : 'NO REG.'
                      "
                    />
                  </div>
                </template>
                <template v-slot:item.rha_bill_insurance_paid_date="{ item }">
                  <div>
                    <span
                      :class="
                        item.rha_bill_insurance_paid_date
                          ? ''
                          : 'orange--text text--darken-3'
                      "
                      v-text="
                        item.rha_bill_insurance_paid_date
                          ? item.rha_bill_insurance_paid_date
                          : item.rha_bill_id
                          ? '-'
                          : 'NO REG.'
                      "
                    />
                  </div>
                </template>
                <template v-slot:item.rha_bill_insurance_paid_amount="{ item }">
                  <div>
                    <span
                      :class="
                        parseFloat(item.rha_bill_insurance_paid_amount) ==
                        parseFloat(item.rha_bill_insurance_pay_amount)
                          ? ''
                          : 'orange--text text--darken-3'
                      "
                      v-text="
                        parseFloat(item.rha_bill_insurance_paid_amount)
                          ? numberFormat(item.rha_bill_insurance_paid_amount)
                          : item.rha_bill_id
                          ? '-'
                          : 'NO REG.'
                      "
                    />
                  </div>
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12">
              <v-btn
                block
                small
                color="success"
                :to="{
                  name: 'facturacion.documentos',
                  params: { rha_id: rha_id },
                }"
              >
                Agregar nueva documentación de factura
                <v-icon right v-text="'mdi-plus'" />
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="amount_dialog" scrollable persistent max-width="1200px">
      <v-card
        tile
        :disabled="amount_dialog_loading"
        :loading="amount_dialog_loading"
        v-if="amount_dialog"
      >
        <v-toolbar dark dense>
          <v-toolbar-title
            v-text="
              `${
                amount_dialog_loading ? '' : `${amount.rha_folio} | `
              }Ingreso Hospitalario | Monto autorizado`
            "
          />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="amount_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="amount">
          <v-form ref="form_amount" lazy-validation>
            <v-row class="pt-3">
              <v-col cols="12">
                <v-card class="mx-auto">
                  <v-card-title>
                    <h2 class="text-caption" v-text="'DETALLE'" />
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" sm="12" md="6">
                        <ViewData
                          label="Respuesta"
                          :value="
                            amount.rha_amount_response.rha_amount_response
                          "
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="6" v-if="!amount_store">
                        <ViewData
                          label="Monto"
                          :value="numberFormat(amount.authorized_amount)"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="6" v-if="amount_store">
                        <v-text-field
                          v-model="amount.authorized_amount"
                          label="Monto*"
                          type="number"
                          dense
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        v-if="
                          amount_store && amount.type == 1 && !amount.cpt_letter
                        "
                      >
                        <v-autocomplete
                          label="Procedimiento definitivo (CPT)"
                          v-model="amount.cpt_id"
                          :rules="rules.required"
                          :items="amount_cpts"
                          item-value="id"
                          :item-text="(v) => `${v.code} | ${v.cpt}`"
                          :loading="amount_cpts_loading"
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card class="mx-auto">
                  <v-card-title>
                    <h2 class="text-caption" v-text="'DOCUMENTOS'" />
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12">
                        <v-row
                          dense
                          v-for="(document, i) in amount.documents"
                          :key="i"
                          v-if="document.active"
                        >
                          <v-col cols="12" sm="12" md="4" v-if="!amount_store">
                            <ViewData
                              :label="`Tipo (${i + 1})`"
                              :value="
                                document.document
                                  ? document.document.document
                                  : ''
                              "
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="6" v-if="!amount_store">
                            <ViewData
                              label="Descripción"
                              :value="document.description"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="2" v-if="!amount_store">
                            <v-tooltip left>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  class="ml-1"
                                  v-on="on"
                                  fab
                                  x-small
                                  color="warning"
                                  dark
                                  :href="
                                    url_documents +
                                    '/rha_amounts/' +
                                    document.url
                                  "
                                  target="_blank"
                                >
                                  <v-icon v-text="'mdi-download'" />
                                </v-btn>
                              </template>
                              <span v-text="'Descargar'" />
                            </v-tooltip>
                          </v-col>
                          <v-col cols="12" v-if="amount_store">
                            <v-row dense>
                              <v-col cols="12" sm="12" md="3">
                                <v-autocomplete
                                  label="Tipo*"
                                  v-model="document.document_id"
                                  :rules="rules.required"
                                  :items="amount_documents"
                                  item-value="id"
                                  :item-text="(v) => v.document"
                                  :loading="amount_documents_loading"
                                  dense
                                />
                              </v-col>
                              <v-col cols="12" sm="12" md="4">
                                <v-text-field
                                  label="Descripción"
                                  v-model="document.description"
                                  :rules="rules.required"
                                  type="text"
                                  dense
                                />
                              </v-col>
                              <v-col cols="12" sm="12" md="4">
                                <v-file-input
                                  label="Archivo digital*"
                                  v-model="document.file"
                                  accept=".pdf, .docx, .doc, .txt, .xlsx, .xls"
                                  :rules="data.id ? [] : rules.required"
                                  show-size
                                  dense
                                />
                              </v-col>
                              <v-col cols="12" sm="12" md="1">
                                <v-btn
                                  text
                                  icon
                                  size="small"
                                  color="red darken-2"
                                  tLabel="Eliminar"
                                  tPosition="bottom"
                                  @click="amountDocumentDelete(i)"
                                >
                                  <v-icon medium v-text="'mdi-close'" />
                                </v-btn>
                              </v-col>
                              <v-col cols="12">
                                <v-divider />
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" v-if="amount_store">
                        <v-btn
                          block
                          color="warning"
                          x-small
                          @click.prevent="amountDocumentAdd"
                        >
                          Agregar documento
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card class="mx-auto">
                  <v-card-title>
                    <h2 class="text-caption" v-text="'Números'" />
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row dense>
                      <v-col
                        cols="12"
                        xs="12"
                        md="6"
                        v-if="rha.insurance.bill_visible_sinester"
                      >
                        <v-text-field
                          v-model="amount.sinester"
                          label="Siniestro"
                          type="text"
                          dense
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        md="6"
                        v-if="rha.insurance.bill_visible_letter_auth"
                      >
                        <v-text-field
                          v-model="amount.letter_auth"
                          label="Carta autorización"
                          type="text"
                          dense
                          :rules="rules.required"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  color="success"
                  small
                  @click.prevent="amountSubmit"
                >
                  Guardar información
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="documentation_dialog"
      scrollable
      persistent
      max-width="1800px"
    >
      <v-card
        tile
        :loading="documentation_dialog_loading"
        :disabled="documentation_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title
            v-text="
              `${
                data ? `${data.rha_folio} | ` : ''
              }Ingreso Hospitalario | Documentación`
            "
          />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="documentation_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="data && rha">
          <v-row class="pt-3" dense>
            <v-col cols="12">
              <RhaData :rha_id="data.rha_id" />
            </v-col>
            <v-col cols="12" v-if="!data.to_stamp">
              <v-alert type="warning" dense>
                <div
                  class="text-center"
                  v-text="'La documentación esta en un proceso de revisión'"
                />
                <div
                  v-if="!data.valid_receiver_code_taxs"
                  class="text-center"
                  v-text="
                    '*El RFC del receptor de una o varias facturas no es correcta'
                  "
                />
                <div
                  v-if="!data.valid_receiver_names"
                  class="text-center"
                  v-text="
                    '*La razón social del receptor de una o varias facturas no es correcta'
                  "
                />
                <div
                  v-if="!data.valid_amounts"
                  class="text-center"
                  v-text="
                    '*El monto del formato o el ingresado no concuerda con el total de una o varias facturas'
                  "
                />
                <div
                  v-if="data.authorized_amount === null"
                  class="text-center"
                  v-text="
                    '*El ingreso hospitalario no cuenta con monto autorizado'
                  "
                />
                <div
                  v-if="data.authorized_amount === 0"
                  class="text-center"
                  v-text="
                    '*El edo. cuenta monto con IVA total supera al monto autorizado'
                  "
                />
                <div
                  v-if="data.provider_validation_1 === 0"
                  class="text-center"
                  v-text="
                    `*El porcentaje de descuento fijo en la(s) nota(s) de crédito es menor al porcentaje a pagar, el monto mínimo indicado con IVA es ${numberFormat(
                      data.min_discount_amount
                    )}`
                  "
                />
                <div
                  v-if="data.provider_validation_2 === 0"
                  class="text-center"
                  v-text="
                    `*El porcentaje de descuento fijo en la(s) factura(s) es menor al porcentaje a pagar, el monto mínimo indicado con IVA es ${numberFormat(
                      data.min_discount_amount
                    )}`
                  "
                />
                <div
                  v-if="data.bills_with_notes === 0"
                  class="text-center"
                  v-text="'*No se están integrando notas de crédito'"
                />
              </v-alert>
            </v-col>
            <v-col cols="12" v-if="data.to_stamp">
              <v-alert
                :type="data.reception_date ? 'success' : 'warning'"
                dense
              >
                <div
                  class="text-center"
                  v-text="
                    data.reception_date
                      ? `F. recepción ${data.reception_date} | F. pago ${data.pay_date}`
                      : 'F. recepción y F.pago no han sido calculadas'
                  "
                />
              </v-alert>
            </v-col>
            <v-col cols="12" v-if="data.observation">
              <v-alert type="error" dense>
                <div class="text-center">
                  <b v-text="'Documentación rechazada'" />
                </div>
                <div class="text-center" v-text="data.observation" />
              </v-alert>
            </v-col>
            <v-col cols="12" v-if="data.provider_response">
              <v-alert type="warning" dense>
                <div class="text-center">
                  <b v-text="'Respuesta rechazo'" />
                </div>
                <div class="text-center" v-text="data.provider_response" />
              </v-alert>
            </v-col>
            <v-col cols="12">
              <v-card
                tile
                :loading="loading ? 'grey lighten-1' : false"
                :disabled="loading"
              >
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span
                      class="text-caption"
                      v-text="
                        `DOC. ID ${data.document} | Servicio: ${data.provider_type.provider_type}`
                      "
                    />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items>
                    <v-tooltip
                      left
                      v-if="user_view && !data.rha_amount_bill_status_account"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="warning"
                          class="ml-1"
                          icon
                          @click="rhaAmountDlg"
                        >
                          <v-icon> mdi-cash-plus </v-icon>
                        </v-btn>
                      </template>
                      <span v-text="'Agregar Edo. Cuenta Factura'" />
                    </v-tooltip>
                    <v-tooltip
                      left
                      v-if="user_view && data.rha_amount_bill_status_account"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="success"
                          class="ml-1"
                          icon
                          @click="rhaStatusAccountDlg"
                        >
                          <v-icon> mdi-cash-check </v-icon>
                        </v-btn>
                      </template>
                      <span v-text="'Ver Edo. Cuenta Factura'" />
                    </v-tooltip>
                    <v-tooltip
                      left
                      v-if="user_view && data.insurance_payment_rejected"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="warning"
                          class="ml-1"
                          icon
                          @click="deleteRhaBillInsurancePayment"
                        >
                          <v-icon v-text="'mdi-folder-remove'" />
                        </v-btn>
                      </template>
                      <span v-text="'Eliminar documentación aseguradora'" />
                    </v-tooltip>
                    <v-tooltip
                      left
                      v-if="
                        user_view &&
                        data.rha_bill_insurance_rejecteds.length > 0
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="blue darken-3"
                          class="ml-1"
                          icon
                          @click="rejects_payments_dialog = true"
                        >
                          <v-icon v-text="'mdi-folder-table'" />
                        </v-btn>
                      </template>
                      <span
                        v-text="
                          'Historial de documentación aseguradora eliminada'
                        "
                      />
                    </v-tooltip>
                    <v-tooltip left v-if="user_view">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="blue-grey darken-3"
                          class="ml-1"
                          icon
                          @click="rhaReportDialog"
                        >
                          <v-icon v-text="'mdi-update'" />
                        </v-btn>
                      </template>
                      <span v-text="'Editar fechas linea 12'" />
                    </v-tooltip>
                    <v-tooltip left v-if="user_view">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="brown darken-3"
                          class="ml-1"
                          icon
                          @click="providerTypeDialog"
                        >
                          <v-icon v-text="'mdi-hospital-building'" />
                        </v-btn>
                      </template>
                      <span v-text="'Cambiar el tipo de servicio'" />
                    </v-tooltip>
                    <v-tooltip left v-if="user_view && !data.paid_date">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="cyan darken-3"
                          class="ml-1"
                          icon
                          @click="calcPayDayDialog"
                        >
                          <v-icon v-text="'mdi-calendar-refresh'" />
                        </v-btn>
                      </template>
                      <span v-text="'Recalcular día de recepción y pago'" />
                    </v-tooltip>
                    <v-tooltip
                      left
                      v-if="user_view && !data.stamp_id && last_amount"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="pink darken-3"
                          class="ml-1"
                          icon
                          @click="amountUpdate"
                        >
                          <v-icon v-text="'mdi-credit-card-edit'" />
                        </v-btn>
                      </template>
                      <span v-text="'Editar último monto autorizado'" />
                    </v-tooltip>
                    <v-tooltip
                      left
                      v-if="
                        user_view && !data.stamp_id && !data.authorized_amount
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="orange darken-3"
                          class="ml-1"
                          icon
                          @click="amountStore"
                        >
                          <v-icon v-text="'mdi-credit-card-plus'" />
                        </v-btn>
                      </template>
                      <span v-text="'Crear monto autorizado'" />
                    </v-tooltip>
                    <!-- <v-tooltip left v-if="provider_validation"> -->
                    <v-tooltip
                      left
                      v-if="login.role_id == 1 && user_view && !data.stamp_id"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          class="ml-1"
                          color="teal darken-3"
                          icon
                          @click.prevent="billAmounts"
                        >
                          <v-icon v-text="'mdi-currency-usd'" />
                        </v-btn>
                      </template>
                      <span v-text="'Editar montos'" />
                    </v-tooltip>
                    <v-tooltip v-if="user_view && login.role_id == 1" bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          :color="
                            data.taxed_amount == null
                              ? ''
                              : 'light-green darken-3'
                          "
                          class="ml-1"
                          icon
                          @click="taxedDlg"
                        >
                          <v-icon> mdi-file-table </v-icon>
                        </v-btn>
                      </template>
                      <span v-text="'Gravado y Exento'" />
                    </v-tooltip>
                    <v-tooltip left v-if="user_view">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="error"
                          class="ml-1"
                          icon
                          @click.prevent="billObservation"
                        >
                          <v-icon v-text="'mdi-folder-alert'" />
                        </v-btn>
                      </template>
                      <span v-text="'Observación rechazo'" />
                    </v-tooltip>
                    <v-tooltip
                      left
                      v-if="data.observation && !data.provider_response"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="warning"
                          class="ml-1"
                          icon
                          @click.prevent="billProviderResponse"
                        >
                          <v-icon v-text="'mdi-folder-arrow-up'" />
                        </v-btn>
                      </template>
                      <span v-text="'Respuesta a rechazo'" />
                    </v-tooltip>
                    <v-tooltip left v-if="user_view">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="lime darken-3"
                          class="ml-1"
                          icon
                          @click.prevent="billObservationDetail"
                        >
                          <v-icon v-text="'mdi-message-draw'" />
                        </v-btn>
                      </template>
                      <span v-text="'Observación administrativa'" />
                    </v-tooltip>
                    <v-tooltip
                      left
                      v-if="
                        user_view &&
                        data.consignment &&
                        !data.consignment_verify
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="warning"
                          class="ml-1"
                          icon
                          @click="setConsigmentVerify"
                        >
                          <v-icon v-text="'mdi-file-check'" />
                        </v-btn>
                      </template>
                      <span v-text="'Validar'" />
                    </v-tooltip>
                    <v-tooltip
                      left
                      v-if="
                        (user_view && !data.stamp_id) ||
                        (!user_view &&
                          (!data.to_stamp ||
                            (data.observation && !data.provider_response)))
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="info"
                          class="ml-1"
                          icon
                          :to="{
                            name: 'facturacion.documentos_revision',
                            params: {
                              rha_id: data.rha_id,
                              rha_bill_id: data.id,
                            },
                          }"
                        >
                          <v-icon v-text="'mdi-pencil'" />
                        </v-btn>
                      </template>
                      <span v-text="'Editar documentación'" />
                    </v-tooltip>
                  </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" v-if="user_view && data.user_validate">
                      <ViewData
                        label="Verificado por"
                        :value="data.user_validate.email"
                      />
                      <v-divider />
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="user_view && data.observation_detail"
                    >
                      <ViewData
                        label="Observación Administrativa"
                        :value="data.observation_detail"
                      />
                      <v-divider />
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'Identificación'"
                      />
                      <v-icon
                        right
                        small
                        :color="data.url_credential ? 'success' : 'warning'"
                        v-text="data.url_credential ? 'mdi-check' : 'mdi-alert'"
                      />
                      <v-tooltip bottom v-if="data.url_credential">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            small
                            v-on="on"
                            :href="
                              url_documents +
                              '/rha_bills/' +
                              data.url_credential
                            "
                            target="_blank"
                          >
                            <v-icon small>mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span v-text="'Ver'" />
                      </v-tooltip>
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'Total factura(s)'"
                      />
                      <span v-text="numberFormat(data.bills_amount)" />
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'Total IVA'"
                      />
                      <span v-text="numberFormat(data.bills_tax_amount)" />
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'Total Nota(s) de Crédito'"
                      />
                      <span v-text="numberFormat(data.notes_amount)" />
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'Total a pagar'"
                      />
                      <span v-text="numberFormat(data.pay_amount)" />
                    </v-col>
                    <v-col cols="12" sm="12" md="3" v-if="user_view">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'Cobro Aseg.'"
                      />
                      <span v-text="numberFormat(data.insurance_pay_amount)" />
                    </v-col>
                    <v-col cols="12" sm="12" md="3" v-if="user_view">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'Desc. factura'"
                      />
                      <span v-text="numberFormat(data.bills_discount_amount)" />
                    </v-col>
                    <v-col cols="12" sm="12" md="3" v-if="user_view">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'Deducible Obs.'"
                      />
                      <span v-text="numberFormat(data.deductibles_amount)" />
                    </v-col>
                    <v-col cols="12" sm="12" md="3" v-if="user_view">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'Coaseguro Obs.'"
                      />
                      <span v-text="numberFormat(data.coinsurances_amount)" />
                    </v-col>
                    <v-col cols="12" sm="12" md="3" v-if="user_view">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'% IVA Calc.'"
                      />
                      <span v-text="data.taxed" />
                    </v-col>
                    <v-col cols="12" sm="12" md="3" v-if="user_view">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'Desc. Calc. Obs.'"
                      />
                      <span v-text="numberFormat(data.discounts_amount)" />
                    </v-col>
                    <v-col cols="12" sm="12" md="3" v-if="user_view">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'Tipo descuento'"
                      />
                      <span
                        v-text="
                          data.rha_bill_discount_type.rha_bill_discount_type
                        "
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="3" v-if="user_view">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'Remesa'"
                      />
                      <span
                        v-text="data.consignment ? data.consignment : '-'"
                      />
                      <v-icon
                        v-if="data.consignment && !data.consignment_verify"
                        right
                        small
                        color="warning"
                        v-text="'mdi-alert'"
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      v-for="(document, i) in data.documents"
                      :key="i"
                    >
                      <v-row dense>
                        <v-col cols="12" class="grey darken-3 white--text">
                          <h4
                            v-text="`Factura (${i + 1}) | ${document.folio}`"
                            class="pl-1"
                          />
                        </v-col>
                        <v-col cols="12" sm="12" md="2">
                          <span
                            class="text-caption font-weight-bold mr-1"
                            v-text="'Factura PDF'"
                          />
                          <v-icon
                            right
                            small
                            :color="document.url_bill ? 'success' : 'warning'"
                            v-text="
                              document.url_bill ? 'mdi-check' : 'mdi-alert'
                            "
                          />
                          <v-tooltip bottom v-if="document.url_bill">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                small
                                v-on="on"
                                :href="
                                  url_documents +
                                  '/rha_bill_statuses/' +
                                  document.url_bill
                                "
                                target="_blank"
                              >
                                <v-icon small>mdi-eye</v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Ver'" />
                          </v-tooltip>
                        </v-col>
                        <v-col cols="12" sm="12" md="2">
                          <span
                            class="text-caption font-weight-bold mr-1"
                            v-text="'Factura XML'"
                          />
                          <v-icon
                            right
                            small
                            :color="document.xml_valid ? 'success' : 'warning'"
                            v-text="
                              document.xml_valid ? 'mdi-check' : 'mdi-alert'
                            "
                          />
                          <v-tooltip bottom v-if="document.url_bill_xml">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                small
                                v-on="on"
                                :href="
                                  url_documents +
                                  '/rha_bill_statuses/' +
                                  document.url_bill_xml
                                "
                                target="_blank"
                              >
                                <v-icon small>mdi-eye</v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Ver'" />
                          </v-tooltip>
                        </v-col>
                        <v-col cols="12" sm="12" md="2">
                          <span
                            class="text-caption font-weight-bold mr-1"
                            v-text="'RFC y razon social'"
                          />
                          <v-icon
                            right
                            small
                            :color="
                              document.receiver_code_tax &&
                              document.receiver_name
                                ? 'success'
                                : 'warning'
                            "
                            v-text="
                              document.receiver_code_tax &&
                              document.receiver_name
                                ? 'mdi-check'
                                : 'mdi-alert'
                            "
                          />
                        </v-col>
                        <v-col cols="12" sm="12" md="3">
                          <span
                            class="text-caption font-weight-bold mr-1"
                            v-text="'Formato edo. cuenta monto con IVA'"
                          />
                          <span
                            :class="
                              document.status_account_valid === null ||
                              (document.status_account_valid &&
                                parseFloat(document.status_account_amount) ===
                                  parseFloat(document.xml_amount))
                                ? ''
                                : 'orange--text text--darken-3'
                            "
                            v-text="
                              numberFormat(document.status_account_amount)
                            "
                          />
                          <v-tooltip bottom v-if="document.url_status_account">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                small
                                v-on="on"
                                :href="
                                  url_documents +
                                  '/rha_bill_statuses/' +
                                  document.url_status_account
                                "
                                target="_blank"
                              >
                                <v-icon small>mdi-eye</v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Ver'" />
                          </v-tooltip>
                        </v-col>
                        <v-col cols="12" sm="12" md="3">
                          <span
                            class="text-caption font-weight-bold mr-1"
                            v-text="'Edo. cuenta monto con IVA'"
                          />
                          <span
                            :class="
                              parseFloat(document.amount_typed) ===
                              parseFloat(document.xml_amount)
                                ? ''
                                : 'orange--text text--darken-3'
                            "
                            v-text="numberFormat(document.amount_typed)"
                          />
                        </v-col>
                        <v-col cols="12" sm="12" md="2">
                          <span
                            class="text-caption font-weight-bold mr-1"
                            v-text="'Carta autorización'"
                          />
                          <v-icon
                            right
                            small
                            :color="
                              document.url_letter_auth ? 'success' : 'warning'
                            "
                            v-text="
                              document.url_letter_auth
                                ? 'mdi-check'
                                : 'mdi-alert'
                            "
                          />
                          <v-tooltip bottom v-if="document.url_letter_auth">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                small
                                v-on="on"
                                :href="
                                  url_documents +
                                  '/rha_bill_statuses/' +
                                  document.url_letter_auth
                                "
                                target="_blank"
                              >
                                <v-icon small>mdi-eye</v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Ver'" />
                          </v-tooltip>
                        </v-col>
                        <v-col cols="12" sm="12" md="2">
                          <span
                            class="text-caption font-weight-bold mr-1"
                            v-text="'Otros'"
                          />
                          <v-icon
                            right
                            small
                            v-text="
                              document.url_other ? 'mdi-check' : 'mdi-alert'
                            "
                          />
                          <v-tooltip bottom v-if="document.url_other">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                small
                                v-on="on"
                                :href="
                                  url_documents +
                                  '/rha_bill_statuses/' +
                                  document.url_other
                                "
                                target="_blank"
                              >
                                <v-icon small>mdi-eye</v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Ver'" />
                          </v-tooltip>
                        </v-col>
                        <v-col cols="12" sm="12" md="5">
                          <span
                            class="text-caption font-weight-bold mr-1"
                            v-text="'Descripción'"
                          />
                          <span
                            class="text-caption"
                            v-text="document.other_description"
                          />
                        </v-col>
                        <v-col cols="12" sm="12" md="3">
                          <span
                            class="text-caption font-weight-bold mr-1"
                            v-text="'Monto XML'"
                          />
                          <span v-text="numberFormat(document.xml_amount)" />
                        </v-col>
                        <v-col
                          cols="12"
                          v-for="(note, j) in document.notes"
                          :key="j"
                        >
                          <v-row dense>
                            <v-col cols="12">
                              <v-divider />
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <span
                                class="text-caption font-weight-bold mr-1"
                                v-text="
                                  `N. crédito PDF(${i + 1}.${j + 1}) ${
                                    note.folio ? `| ${note.folio}` : ''
                                  }`
                                "
                              />

                              <v-icon
                                right
                                small
                                :color="note.url ? 'success' : 'warning'"
                                v-text="note.url ? 'mdi-check' : 'mdi-alert'"
                              />
                              <v-tooltip bottom v-if="note.url">
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    icon
                                    small
                                    v-on="on"
                                    :href="
                                      url_documents +
                                      '/rha_bill_notes/' +
                                      note.url
                                    "
                                    target="_blank"
                                  >
                                    <v-icon small>mdi-eye</v-icon>
                                  </v-btn>
                                </template>
                                <span v-text="'Ver'" />
                              </v-tooltip>
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <span
                                class="text-caption font-weight-bold mr-1"
                                v-text="'N. crédito XML'"
                              />
                              <v-icon
                                right
                                small
                                :color="note.xml_valid ? 'success' : 'default'"
                                v-text="
                                  note.xml_valid ? 'mdi-check' : 'mdi-alert'
                                "
                              />
                              <v-tooltip bottom v-if="note.url_xml">
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    icon
                                    small
                                    v-on="on"
                                    :href="
                                      url_documents +
                                      '/rha_bill_notes/' +
                                      note.url_xml
                                    "
                                    target="_blank"
                                  >
                                    <v-icon small>mdi-eye</v-icon>
                                  </v-btn>
                                </template>
                                <span v-text="'Ver'" />
                              </v-tooltip>
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <span
                                class="text-caption font-weight-bold mr-1"
                                v-text="'Monto XML'"
                              />
                              <span
                                v-if="note.xml_amount"
                                v-text="numberFormat(note.xml_amount)"
                              />
                              <v-icon
                                v-else
                                right
                                small
                                color="default"
                                v-text="'mdi-alert'"
                              />
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <span
                                class="text-caption font-weight-bold mr-1"
                                v-text="'Monto escrito con IVA'"
                              />
                              <span
                                :class="
                                  parseFloat(note.amount_typed) ==
                                  parseFloat(note.xml_amount)
                                    ? ''
                                    : 'orange--text text--darken-3'
                                "
                                v-text="numberFormat(note.amount_typed)"
                              />
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" v-if="user_view && data.bill_generated">
              <v-card
                tile
                :loading="loading ? 'grey lighten-1' : false"
                :disabled="loading"
              >
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span
                      class="text-caption"
                      v-text="'FACTURA PARA ASEGURADORA'"
                    />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items>
                    <v-tooltip left v-if="data.stamp_cancel_id">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="error"
                          class="ml-1"
                          icon
                          @click.prevent="
                            downloadFile(data.stamp_cancel_id, 'pdf')
                          "
                        >
                          <v-icon v-text="'mdi-file-cancel'" />
                        </v-btn>
                      </template>
                      <span v-text="'Descargar PDF cancelado'" />
                    </v-tooltip>
                    <v-tooltip
                      left
                      v-if="rha && data.to_stamp && data.stamp_id == null"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="warning"
                          class="ml-1"
                          icon
                          @click.prevent="billDialog(data, true)"
                        >
                          <v-icon v-text="'mdi-file-check'" />
                        </v-btn>
                      </template>
                      <span v-text="'Generar factura'" />
                    </v-tooltip>
                    <v-tooltip left v-if="data.stamp_id">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="cyan darken-3"
                          class="ml-1"
                          icon
                          @click.prevent="downloadFile(data.stamp_id, 'pdf')"
                        >
                          <v-icon v-text="'mdi-file-download'" />
                        </v-btn>
                      </template>
                      <span v-text="'Descargar PDF'" />
                    </v-tooltip>
                    <v-tooltip left v-if="data.stamp_id">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="teal darken-2"
                          class="ml-1"
                          icon
                          @click.prevent="downloadFile(data.stamp_id, 'xml')"
                        >
                          <v-icon v-text="'mdi-xml'" />
                        </v-btn>
                      </template>
                      <span v-text="'Descargar XML'" />
                    </v-tooltip>
                  </v-toolbar-items>
                </v-toolbar>
                <v-card-text v-if="doc_bill">
                  <v-row dense>
                    <v-col cols="12">
                      <v-row dense>
                        <v-col cols="12">
                          <v-simple-table dense>
                            <template v-slot:default>
                              <thead></thead>
                              <tbody>
                                <tr>
                                  <th v-text="'Razón Social'" />
                                  <td v-text="doc_bill.customer.legal_name" />
                                </tr>
                                <tr>
                                  <th v-text="'RFC'" />
                                  <td v-text="doc_bill.customer.tax_id" />
                                </tr>
                                <tr>
                                  <th v-text="'Domicilio'" />
                                  <td
                                    v-text="
                                      doc_bill.customer.address.street +
                                      ' No.' +
                                      doc_bill.customer.address.exterior +
                                      ', ' +
                                      (doc_bill.customer.address.interior
                                        ? 'Int. ' +
                                          doc_bill.customer.address.interior +
                                          ', '
                                        : '') +
                                      'Col. ' +
                                      doc_bill.customer.address.neighborhood +
                                      ', ' +
                                      doc_bill.customer.address.zip +
                                      ', ' +
                                      doc_bill.customer.address.city +
                                      ', ' +
                                      doc_bill.customer.address.state +
                                      ', ' +
                                      doc_bill.customer.address.country
                                    "
                                  />
                                </tr>
                                <tr>
                                  <th v-text="'Uso del CFDI '" />
                                  <td
                                    v-text="
                                      doc_bill.use == 'G03'
                                        ? 'G03 - Gastos en general'
                                        : 'DESCONOCIDO'
                                    "
                                  />
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                        <v-col cols="12">
                          <v-simple-table dense>
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th>Clave ProdServ</th>
                                  <th>Concepto</th>
                                  <th>Cant.</th>
                                  <th>Unidad</th>
                                  <th>Descuento</th>
                                  <th>Precio Unitario</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(v, i) in doc_bill.products"
                                  :key="i"
                                >
                                  <td v-text="v.product.product_key" />
                                  <td v-text="v.product.description" />
                                  <td v-text="v.quantity" />
                                  <td v-text="v.product.unit_key" />
                                  <td v-text="numberFormat(v.discount)" />
                                  <td v-text="numberFormat(v.product.price)" />
                                </tr>
                                <tr>
                                  <td />
                                  <td />
                                  <td />
                                  <td />
                                  <td
                                    class="font-weight-bold"
                                    v-text="'Subtotal'"
                                  />
                                  <td
                                    class="font-weight-bold"
                                    v-text="
                                      numberFormat(doc_bill.calculates.subtotal)
                                    "
                                  />
                                </tr>
                                <tr v-if="doc_bill.calculates.discount > 0">
                                  <td />
                                  <td />
                                  <td />
                                  <td />
                                  <td
                                    class="font-weight-bold"
                                    v-text="'Descuento'"
                                  />
                                  <td
                                    class="font-weight-bold"
                                    v-text="
                                      numberFormat(doc_bill.calculates.discount)
                                    "
                                  />
                                </tr>
                                <tr>
                                  <td />
                                  <td />
                                  <td />
                                  <td />
                                  <td
                                    class="font-weight-bold"
                                    v-text="'IVA 16%'"
                                  />
                                  <td
                                    class="font-weight-bold"
                                    v-text="
                                      numberFormat(doc_bill.calculates.tax_16)
                                    "
                                  />
                                </tr>
                                <tr>
                                  <td />
                                  <td />
                                  <td />
                                  <td />
                                  <td
                                    class="font-weight-bold"
                                    v-text="'Total'"
                                  />
                                  <td
                                    class="font-weight-bold"
                                    v-text="
                                      numberFormat(doc_bill.calculates.total)
                                    "
                                  />
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                        <v-col cols="12">
                          <v-simple-table dense>
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th>Forma de pago</th>
                                  <th>Método de pago</th>
                                  <th>Moneda</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td
                                    v-text="
                                      doc_bill.payment_form == '99'
                                        ? '99 Por definir'
                                        : ''
                                    "
                                  />
                                  <td
                                    v-text="
                                      doc_bill.payment_method == 'PPD'
                                        ? 'PPD Pago en parcialidades o diferido'
                                        : ''
                                    "
                                  />
                                  <td v-text="'MXN'" />
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                        <v-col cols="12">
                          <v-simple-table dense>
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th>Observaciones</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td v-if="rha.insurance.bill_visible_insured">
                                    <div v-if="!insured_name_xml_edit">
                                      <b v-text="'Paciente: '" />
                                      <span
                                        v-text="doc_bill.observations.full_name"
                                      />
                                    </div>
                                    <v-form
                                      v-else
                                      ref="insured_name_xml_form"
                                      lazy-validation
                                    >
                                      <v-text-field
                                        label="Paciente*"
                                        v-model="insured_name_xml"
                                        type="text"
                                        maxlength="100"
                                        dense
                                        counter
                                        :rules="rules.required"
                                      />
                                    </v-form>
                                    <v-tooltip
                                      v-if="
                                        user_view &&
                                        rha &&
                                        data.to_stamp &&
                                        data.stamp_id == null &&
                                        doc_bill.observations
                                          .insured_name_xml &&
                                        !insured_name_xml_edit
                                      "
                                      top
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-btn
                                          icon
                                          color="info"
                                          small
                                          v-on="on"
                                          @click.prevent="
                                            insuredNameXmlEdit(
                                              doc_bill.observations.full_name
                                            )
                                          "
                                        >
                                          <v-icon small> mdi-pencil </v-icon>
                                        </v-btn>
                                      </template>
                                      <span v-text="'Editar'" />
                                    </v-tooltip>
                                    <v-tooltip v-if="insured_name_xml_edit" top>
                                      <template v-slot:activator="{ on }">
                                        <v-btn
                                          icon
                                          color="error"
                                          small
                                          v-on="on"
                                          @click.prevent="
                                            insured_name_xml_edit = false
                                          "
                                        >
                                          <v-icon small> mdi-close </v-icon>
                                        </v-btn>
                                      </template>
                                      <span v-text="'Cancelar'" />
                                    </v-tooltip>
                                    <v-tooltip v-if="insured_name_xml_edit" top>
                                      <template v-slot:activator="{ on }">
                                        <v-btn
                                          icon
                                          color="info"
                                          small
                                          v-on="on"
                                          @click.prevent="
                                            insuredNameXmlSave(data.id)
                                          "
                                        >
                                          <v-icon small> mdi-check </v-icon>
                                        </v-btn>
                                      </template>
                                      <span v-text="'Aceptar'" />
                                    </v-tooltip>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    v-if="rha.insurance.bill_visible_provider"
                                  >
                                    <b v-text="'Hospital: '" />
                                    <span
                                      v-text="doc_bill.observations.trade_name"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    v-if="rha.insurance.bill_visible_sinester"
                                  >
                                    <b v-text="'Número de siniestro: '" />
                                    <span
                                      v-text="
                                        last_amount
                                          ? doc_bill.observations.sinester
                                            ? doc_bill.observations.sinester
                                            : 'NO REGISTRADO'
                                          : 'MONTO AUTORIZADO NO REGISTRADO'
                                      "
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    v-if="
                                      rha.insurance.bill_visible_letter_auth
                                    "
                                  >
                                    <b
                                      v-text="'Número de carta autorización: '"
                                    />
                                    <span
                                      v-text="
                                        last_amount
                                          ? doc_bill.observations.letter_auth
                                            ? doc_bill.observations.letter_auth
                                            : 'NO REGISTRADO'
                                          : 'MONTO AUTORIZADO NO REGISTRADO'
                                      "
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    v-if="
                                      doc_bill.observations.discounts_amount &&
                                      rha.insurance
                                        .bill_visible_discounts_amount
                                    "
                                  >
                                    <b v-text="'Descuento: '" />
                                    <span
                                      v-text="
                                        '$' +
                                        doc_bill.observations.discounts_amount
                                      "
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    v-if="
                                      doc_bill.observations
                                        .deductibles_amount &&
                                      rha.insurance
                                        .bill_visible_deductibles_amount
                                    "
                                  >
                                    <b v-text="'Deducible: '" />
                                    <span
                                      v-text="
                                        '$' +
                                        doc_bill.observations.deductibles_amount
                                      "
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    v-if="
                                      doc_bill.observations
                                        .coinsurances_amount &&
                                      rha.insurance
                                        .bill_visible_coinsurances_amount
                                    "
                                  >
                                    <b v-text="'Coaseguro: '" />
                                    <span
                                      v-text="
                                        '$' +
                                        doc_bill.observations
                                          .coinsurances_amount
                                      "
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    v-if="
                                      doc_bill.observations.observation_bill
                                    "
                                  >
                                    <span
                                      v-text="
                                        doc_bill.observations.observation_bill
                                      "
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" v-if="user_view && data.stamp_id">
                      <v-btn
                        color="error"
                        x-small
                        @click.prevent="
                          BillCancel(data.id, data.stamp_id, '02')
                        "
                      >
                        Cancelar
                        <v-icon x-small right v-text="'mdi-file-cancel'" />
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              v-if="
                (login.role_id == 1 || login.role_id == 2) && !data.stamp_id
              "
            >
              <v-btn
                color="error"
                small
                @click.prevent="rhaBillDesactivate(data.id)"
              >
                Eliminar documentación
                <v-icon small right v-text="'mdi-file-remove'" />
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="documentation_insurance_dialog"
      scrollable
      persistent
      max-width="1200px"
    >
      <v-card
        tile
        :disabled="loading"
        :loading="loading"
        v-if="documentation_insurance_dialog"
      >
        <v-toolbar dark dense>
          <v-toolbar-title
            v-text="
              `${data ? data.rha_folio : ''} | Ingreso Hospitalario | Doc. ID ${
                data ? data.document : ''
              } | Documentación aseguradora`
            "
          />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="documentation_insurance_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="data">
          <v-row
            class="pt-3"
            v-if="!data.insurance_procedure || documentation_insurance_update"
          >
            <v-col cols="12">
              <v-card
                class="mx-auto"
                :disabled="
                  data.documentation_insurance ||
                  !data.rha.last_rha_letter_valid
                "
              >
                <v-card-title>
                  <h2 class="text-caption" v-text="'REGISTRO'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-form
                    v-on:submit.prevent
                    ref="form_documentation_insurance"
                    lazy-validation
                  >
                    <v-row dense>
                      <v-col cols="12" xs="12" md="3">
                        <DatePicker
                          label="Fecha*"
                          :model.sync="
                            documentation_insurance.insurance_reception_date
                          "
                          clean
                          :rules="rules.required"
                          :disabled="
                            !data.insurance_pay_date ||
                            (data.insurance_pay_date &&
                              data.insurance_payment_rejected)
                              ? false
                              : true
                          "
                          oneWeek
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <v-text-field
                          v-model="
                            documentation_insurance.insurance_reception_time
                          "
                          label="Hora*"
                          type="time"
                          dense
                          :rules="rules.required"
                          :disabled="
                            !data.insurance_pay_date ||
                            (data.insurance_pay_date &&
                              data.insurance_payment_rejected)
                              ? false
                              : true
                          "
                        />
                      </v-col>
                      <v-col
                        v-if="!documentation_insurance_update"
                        cols="12"
                        xs="12"
                        md="3"
                      >
                        <DatePicker
                          label="Promesa de pago*"
                          :model.sync="
                            documentation_insurance.insurance_pay_date
                          "
                          clean
                          all_date
                          :rules="rules.required"
                          :disabled="
                            !data.insurance_pay_date ||
                            (data.insurance_pay_date &&
                              data.insurance_payment_rejected)
                              ? false
                              : true
                          "
                        />
                      </v-col>
                      <v-col v-else cols="12" xs="12" md="3">
                        <DatePicker
                          label="Promesa de pago*"
                          :model.sync="
                            documentation_insurance.insurance_pay_date
                          "
                          clean
                          all_date
                          :rules="rules.required"
                          :disabled="
                            !data.insurance_pay_date ||
                            (data.insurance_pay_date &&
                              data.insurance_payment_rejected)
                              ? false
                              : true
                          "
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <v-text-field
                          v-model="documentation_insurance.insurance_procedure"
                          label="Núm. trámite*"
                          type="text"
                          dense
                          :rules="rules.required"
                          :disabled="
                            !data.insurance_pay_date ||
                            (data.insurance_pay_date &&
                              data.insurance_payment_rejected)
                              ? false
                              : true
                          "
                        />
                      </v-col>
                      <!-- <v-col cols="12" xs="12" md="3">
                        <v-text-field
                          v-model="documentation_insurance.insurance_bill_folio"
                          label="Factura folio"
                          type="text"
                          dense
                          maxlength="30"
                          :disabled="
                            !data.insurance_pay_date ||
                            (data.insurance_pay_date &&
                              data.insurance_payment_rejected)
                              ? false
                              : true
                          "
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <v-text-field
                          v-model="
                            documentation_insurance.insurance_restitution
                          "
                          label="Restitución"
                          type="text"
                          dense
                          maxlength="30"
                          :disabled="
                            !data.insurance_pay_date ||
                            (data.insurance_pay_date &&
                              data.insurance_payment_rejected)
                              ? false
                              : true
                          "
                        />
                      </v-col> -->
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-btn
                v-if="data.rha.last_rha_letter_valid"
                block
                color="success"
                small
                @click.prevent="documentationInsuranceHandle"
              >
                Guardar información
              </v-btn>
              <v-alert v-else dense type="warning">
                <v-row align="center">
                  <v-col class="grow">
                    No se cuenta con carta definitiva, favor de revisar el caso
                    y generarla para poder continuar con el proceso.
                  </v-col>
                  <v-col class="shrink">
                    <v-btn
                      v-if="login.role_id == 1"
                      x-small
                      color="primary"
                      @click.prevent="data.rha.last_rha_letter_valid = true"
                    >
                      Continuar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
          </v-row>
          <v-row class="pt-3" dense v-else>
            <v-col cols="12">
              <v-alert type="success" dense>
                <v-row align="center">
                  <v-col class="text-center">
                    <div>Documentación cargada en portal de aseguradora</div>
                    <div>
                      <small>
                        <b>Restitución: </b>
                        {{
                          data.restitution
                            ? data.restitution.folio +
                              " | " +
                              data.restitution.created_at
                            : "NO. REG."
                        }}
                      </small>
                    </div>
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <span
                class="text-caption font-weight-bold mr-1"
                v-text="'Fecha '"
              />
              <span
                v-text="
                  data.insurance_reception_date +
                  ' ' +
                  data.insurance_reception_time
                "
              />
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <span
                class="text-caption font-weight-bold mr-1"
                v-text="'Promesa de pago '"
              />
              <span v-text="data.insurance_pay_date" />
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <span
                class="text-caption font-weight-bold mr-1"
                v-text="'Núm. trámite '"
              />
              <span v-text="data.insurance_procedure" />
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <span
                class="text-caption font-weight-bold mr-1"
                v-text="'Monto factura '"
              />
              <span
                v-text="numberFormat(data.insurance_pay_amount)"
                :class="
                  parseFloat(data.insurance_pay_amount) !=
                  data.insurance_payments_amount
                    ? parseFloat(data.insurance_pay_amount) >
                      data.insurance_payments_amount
                      ? 'orange--text text--darken-3'
                      : 'green--text text--darken-3'
                    : ''
                "
              />
            </v-col>
          </v-row>
          <v-row
            dense
            v-if="
              data.insurance_payments.length > 0 &&
              !documentation_insurance_update
            "
          >
            <v-col cols="12" sm="12" md="3">
              <span
                class="text-caption font-weight-bold mr-1"
                v-text="'F. pago '"
              />
              <span v-text="data.insurance_paid_date" />
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <span
                class="text-caption font-weight-bold mr-1"
                v-text="'Pago acumulado '"
              />
              <span
                v-text="
                  numberFormat(
                    data.insurance_paid_amount ? data.insurance_paid_amount : 0
                  )
                "
                :class="
                  parseFloat(data.insurance_paid_amount) !=
                  parseFloat(data.insurance_pay_amount)
                    ? data.insurance_paid_amount == null ||
                      parseFloat(data.insurance_paid_amount) <
                        parseFloat(data.insurance_pay_amount)
                      ? 'orange--text text--darken-3'
                      : 'green--text text--darken-3'
                    : ''
                "
              />
            </v-col>
            <v-col cols="12">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">#</th>
                      <th class="text-left">Folio</th>
                      <th class="text-left">F. registro</th>
                      <th class="text-left">Payment ID</th>
                      <th class="text-left">F. pago</th>
                      <th class="text-left">Monto</th>
                      <th class="text-left"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(v, i) in data.insurance_payments"
                      :key="i"
                      :class="v.insurance_refused ? 'row-refused' : ''"
                    >
                      <td v-text="i + 1" />
                      <td>
                        <div>
                          {{ v.folio }}
                        </div>
                        <div v-if="v.insurance_refused">
                          <small v-text="'RECHAZADO'" />
                        </div>
                      </td>
                      <td v-text="v.created_at" />
                      <td v-text="v.payment_id" />
                      <td v-text="v.paid_date" />
                      <td>
                        <span
                          v-text="numberFormat(v.amount)"
                          :class="
                            parseFloat(v.amount) !=
                            parseFloat(data.insurance_pay_amount)
                              ? parseFloat(v.amount) <
                                parseFloat(data.insurance_pay_amount)
                                ? 'orange--text text--darken-3'
                                : 'green--text text--darken-3'
                              : ''
                          "
                        />
                      </td>
                      <td>
                        <v-tooltip
                          left
                          v-if="!v.stamp_id && !v.insurance_refused"
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              fab
                              x-small
                              color="warning"
                              class="mr-1"
                              @click.prevent="
                                insurancePaymentDialog(v, data.id)
                              "
                            >
                              <v-icon v-text="'mdi-cash-refund'" />
                            </v-btn>
                          </template>
                          <span v-text="'Generar complemento de pago'" />
                        </v-tooltip>
                        <v-tooltip
                          left
                          v-if="!v.stamp_id && !v.insurance_refused"
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              fab
                              x-small
                              color="error"
                              class="mr-1"
                              @click.prevent="
                                insurancePaymentDestroy(v, data.id)
                              "
                            >
                              <v-icon v-text="'mdi-close'" />
                            </v-btn>
                          </template>
                          <span v-text="'Eliminar pago'" />
                        </v-tooltip>
                        <v-tooltip left v-if="v.stamp_id">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              class="mr-1"
                              v-on="on"
                              fab
                              x-small
                              dark
                              color="cyan darken-3"
                              @click.prevent="downloadFile(v.stamp_id, 'pdf')"
                            >
                              <v-icon v-text="'mdi-file'" />
                            </v-btn>
                          </template>
                          <span v-text="'Descargar complemento PDF'" />
                        </v-tooltip>
                        <v-tooltip left v-if="v.stamp_id">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              class="mr-1"
                              v-on="on"
                              fab
                              x-small
                              dark
                              color="teal darken-2"
                              @click.prevent="downloadFile(v.stamp_id, 'xml')"
                            >
                              <v-icon v-text="'mdi-xml'" />
                            </v-btn>
                          </template>
                          <span v-text="'Descargar complemento XML'" />
                        </v-tooltip>
                        <v-tooltip left v-if="v.stamp_id">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              class="mr-1"
                              v-on="on"
                              fab
                              x-small
                              dark
                              :color="
                                data.insurance_complement ? 'info' : 'warning'
                              "
                              @click.prevent="insuranceComplementDialog"
                            >
                              <v-icon v-text="'mdi-file-upload'" />
                            </v-btn>
                          </template>
                          <span
                            v-text="
                              'Cargar complemento de pago en portal de aseguradora'
                            "
                          />
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row dense v-if="!documentation_insurance_update">
            <v-col cols="12" class="text-right">
              <!-- <v-tooltip
                v-if="
                  data.insurance_payment_rejected ||
                  (data.insurance_procedure &&
                    data.insurance_payments.length == 0)
                "
                left
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    fab
                    x-small
                    color="info"
                    class="mr-1"
                    @click.prevent="documentationInsuranceUpdate"
                  >
                    <v-icon v-text="'mdi-pencil'" />
                  </v-btn>
                </template>
                <span v-text="'Editar'" />
              </v-tooltip> -->
              <v-tooltip
                v-if="
                  data.insurance_procedure &&
                  data.insurance_payment_add &&
                  !data.insurance_payment_rejected
                "
                left
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    fab
                    x-small
                    color="success"
                    class="mr-1"
                    @click.prevent="insurancePaymentDialog(null, data.id)"
                  >
                    <v-icon v-text="'mdi-plus'" />
                  </v-btn>
                </template>
                <span v-text="'Añadir pago'" />
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="pay_dialog" scrollable persistent max-width="1800px">
      <v-card
        tile
        v-if="pay_dialog"
        :disabled="pay_dialog_loading"
        :loading="pay_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title v-text="`Pago hospital(es)`" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="pay_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <!-- <v-form v-on:submit.prevent ref="form_payments" lazy-validation> -->
          <v-row class="pt-3">
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'ASEGURADORAS'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nombre</th>
                              <th>Ingresos</th>
                              <th>Monto pago total</th>
                              <th>Monto factura SM total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(insurance, i) in data.insurances"
                              :key="i"
                            >
                              <td v-text="i + 1" />
                              <td v-text="insurance.name" />
                              <td v-text="insurance.payments.length" />
                              <td
                                v-text="numberFormat(insurance.payment_total)"
                              />
                              <td
                                v-text="
                                  numberFormat(insurance.bill_payment_total)
                                "
                              />
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="numberFormat(data.insurance_total)"
                              />
                              <td
                                class="font-weight-bold"
                                v-text="numberFormat(data.insurance_total_bill)"
                              />
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'HOSPITALES'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nombre</th>
                              <th>Ingresos</th>
                              <th>Monto pago total</th>
                              <th>Monto factura SM total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(provider, i) in data.providers"
                              :key="i"
                            >
                              <td v-text="i + 1" />
                              <td v-text="provider.name" />
                              <td v-text="provider.payments.length" />
                              <td
                                v-text="numberFormat(provider.payment_total)"
                              />
                              <td
                                v-text="
                                  numberFormat(provider.bill_payment_total)
                                "
                              />
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="numberFormat(data.provider_total)"
                              />
                              <td
                                class="font-weight-bold"
                                v-text="numberFormat(data.provider_total_bill)"
                              />
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" v-for="(brand, i) in data.brands" :key="i">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2
                    class="text-caption"
                    v-text="
                      `${brand.provider_brand}${
                        brand.provider_brand_id
                          ? ` | ${brand.bank_name} | ${
                              brand.format == 1
                                ? `Cuenta ${brand.account_number}`
                                : `CLABE ${brand.clabe}`
                            }`
                          : ''
                      }`
                    "
                  />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Folio</th>
                              <th>Aseguradora</th>
                              <th>Hospital</th>
                              <th>Doc. ID</th>
                              <th v-if="!brand.provider_brand_id">Banco</th>
                              <th
                                v-if="
                                  !brand.provider_brand_id && brand.format == 1
                                "
                              >
                                Cuenta
                              </th>
                              <th
                                v-if="
                                  !brand.provider_brand_id && brand.format == 2
                                "
                              >
                                CLABE
                              </th>
                              <th>F. registro</th>
                              <th>Monto factura SM</th>
                              <th>F. recepción</th>
                              <th>F. pago</th>
                              <th>Monto Nota C.</th>
                              <th>Obs.</th>
                              <th>Monto</th>
                              <th>Monto a pagar</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(payment, j) in brand.payments" :key="j">
                              <td v-text="j + 1" />
                              <td v-text="payment.rha_folio" />
                              <td v-text="payment.insurance_name" />
                              <td v-text="payment.hospital_name" />
                              <td v-text="payment.rha_bill_document" />
                              <td
                                v-if="!brand.provider_brand_id"
                                v-text="payment.bank_name"
                              />
                              <td
                                v-if="
                                  !brand.provider_brand_id && brand.format == 1
                                "
                                v-text="payment.account_number"
                              />
                              <td
                                v-if="
                                  !brand.provider_brand_id && brand.format == 2
                                "
                                v-text="payment.clabe"
                              />
                              <td v-text="payment.created_at" />
                              <td
                                v-text="
                                  numberFormat(payment.insurance_pay_amount)
                                "
                              />
                              <td v-text="payment.reception_date" />
                              <td v-text="payment.pay_date" />
                              <td v-text="numberFormat(payment.notes_amount)" />
                              <td
                                v-text="payment.rha_bill_observation_detail"
                              />
                              <td v-text="numberFormat(payment.pay_amount)" />
                              <td>
                                <v-text-field
                                  v-if="!brand.file_generated"
                                  v-model="payment.paid_amount"
                                  type="number"
                                  dense
                                />
                                <span
                                  v-else
                                  :class="
                                    parseFloat(payment.paid_amount) ==
                                    parseFloat(payment.pay_amount)
                                      ? ''
                                      : 'orange--text text--darken-3'
                                  "
                                  v-text="numberFormat(payment.paid_amount)"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />

                              <td v-if="!brand.provider_brand_id" />
                              <td
                                v-if="
                                  !brand.provider_brand_id && brand.format == 1
                                "
                              />
                              <td
                                v-if="
                                  !brand.provider_brand_id && brand.format == 2
                                "
                              />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="numberFormat(brand.payment_total_bill)"
                              />
                              <td />
                              <td />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="numberFormat(brand.payment_total)"
                              />
                              <td
                                class="font-weight-bold"
                                v-text="numberFormat(brand.payment_total_paid)"
                              />
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                    <v-col cols="12" v-if="!brand.file_generated">
                      <v-btn
                        block
                        small
                        color="primary"
                        :loading="brand.loading"
                        @click="brandFileGenerated(i)"
                      >
                        Corroborar orden de pago
                        <v-icon right v-text="'mdi-download'" />
                      </v-btn>
                    </v-col>
                    <v-col cols="12" v-if="brand.file_generated">
                      <v-btn
                        block
                        small
                        color="warning"
                        @click="brandFileRegenerated(i)"
                      >
                        Editar orden de pago
                        <v-icon right v-text="'mdi-refresh'" />
                      </v-btn>
                    </v-col>
                    <!-- <v-col
                        cols="12"
                        sm="12"
                        md="6"
                        v-if="brand.file_generated"
                      >
                        <v-file-input
                          v-model="data['document_receipt_' + i]"
                          accept=".pdf"
                          label="Comprobante de pago"
                          :rules="rules.required"
                          dense
                        />
                      </v-col> -->
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-btn
                block
                color="success"
                :disabled="!data.save"
                small
                @click.prevent="brandPayments"
              >
                Guardar orden(es) de pago
              </v-btn>
            </v-col>
          </v-row>
          <!-- </v-form> -->
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="insurance_pay_dialog"
      scrollable
      persistent
      max-width="1800px"
    >
      <v-card
        tile
        v-if="insurance_pay_dialog"
        :loading="insurance_pay_dialog_loading"
        :disabled="insurance_pay_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title
            v-text="
              `Aseguradora pagos${
                insurance_payments.length > 0
                  ? ` (${insurance_payments.length})`
                  : ''
              }`
            "
          />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="insurancePaymentClose">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row class="pt-3">
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'INFORMACIÓN'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" sm="12" md="4" v-if="!file_pay_data">
                      <v-autocomplete
                        label="Aseguradora*"
                        v-model="insurance_payment_analyze.insurance_id"
                        :items="insurances"
                        item-value="id"
                        :item-text="(item) => item.name"
                        :loading="insurances_loading"
                        dense
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="4" v-if="!file_pay_data">
                      <v-file-input
                        v-model="insurance_payment_analyze.document_file_0"
                        accept=".xlsx, .xls"
                        label="Archivo de pago"
                        dense
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="4" v-if="!file_pay_data">
                      <v-btn
                        block
                        small
                        color="primary"
                        @click.prevent="insurancePaymentAnalyze()"
                      >
                        Cargar
                        <v-icon right v-text="'mdi-check'" />
                      </v-btn>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" v-if="file_pay_data">
                      <v-btn
                        block
                        small
                        color=""
                        @click="insurancePaymentDefault"
                      >
                        Cargar otro archivo
                        <v-icon right> mdi-file </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="12" v-if="file_pay_data">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th v-text="'Fila'" />
                              <th v-text="'Núm. trámite'" />
                              <th v-text="'Fecha'" />
                              <th v-text="'Monto en archivo'" />
                              <th v-text="'Folio'" />
                              <th v-text="'Doc. ID'" />
                              <th v-text="'Pago Aseguradora'" />
                              <th v-text="'Pago acumulado'" />
                              <th v-text="'Mensaje'" />
                              <th />
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(v, i) in insurance_payments" :key="i">
                              <td v-text="v.row" />
                              <td v-text="v.procedure" />
                              <td>
                                <span
                                  v-if="v.success != null"
                                  v-text="v.date"
                                />
                              </td>
                              <td
                                :class="
                                  v.amount &&
                                  v.insurance_pay_amount &&
                                  parseFloat(v.amount) <
                                    parseFloat(v.insurance_pay_amount)
                                    ? ''
                                    : 'orange--text text--darken-3'
                                "
                                v-text="v.amount ? numberFormat(v.amount) : '-'"
                              />
                              <td v-text="v.rha_folio" />
                              <td v-text="v.rha_bill_folio" />
                              <td
                                v-text="
                                  v.insurance_pay_amount
                                    ? numberFormat(v.insurance_pay_amount)
                                    : '-'
                                "
                              />
                              <td
                                v-text="
                                  v.insurance_paid_amount
                                    ? numberFormat(v.insurance_paid_amount)
                                    : '-'
                                "
                              />
                              <td>
                                <span
                                  v-text="
                                    (v.insurance_refused
                                      ? 'RECHAZADO'
                                      : 'ACEPTADO') + '. '
                                  "
                                  :class="
                                    (v.insurance_refused ? 'red' : 'green') +
                                    '--text'
                                  "
                                />
                                <span
                                  v-if="v.success != null"
                                  v-text="v.message"
                                />
                              </td>
                              <td>
                                <v-icon
                                  :color="`${
                                    v.rha_bill_id ? 'green' : 'red'
                                  } darken-2`"
                                >
                                  mdi-file-find
                                </v-icon>
                              </td>
                              <td>
                                <v-simple-checkbox
                                  v-if="v.success != null"
                                  v-model="v.success"
                                  :ripple="false"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" v-if="file_pay_data">
              <!-- insurance_payments.length == 0 || !insurance_payments_proceed -->
              <v-btn
                :disabled="insurance_payments.length == 0"
                block
                color="success"
                small
                @click.prevent="insurancePaymentSave()"
              >
                Guardar información
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="complement_dialog"
      scrollable
      persistent
      max-width="1500px"
    >
      <v-card
        tile
        v-if="complement_dialog"
        :loading="complement_dialog_loading"
        :disabled="complement_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title v-text="'Hospital'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="complement_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row class="pt-3">
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'COMPLEMENTO DE PAGO'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row
                    dense
                    v-if="!complement_load && !complement.complement_folio"
                  >
                    <v-col cols="12" sm="12" md="5">
                      <v-file-input
                        v-model="complement.document_complement_pdf_0"
                        accept=".pdf"
                        label="PDF"
                        dense
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="5">
                      <v-file-input
                        v-model="complement.document_complement_xml_0"
                        accept=".xml"
                        label="XML"
                        dense
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="2">
                      <v-btn
                        block
                        small
                        color="primary"
                        @click.prevent="complementRead()"
                        :loading="complement_dialog_loading"
                      >
                        Cargar
                        <v-icon right v-text="'mdi-check'" />
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row
                    dense
                    v-if="complement_load && !complement.complement_folio"
                  >
                    <v-col cols="12">
                      <v-btn
                        block
                        small
                        color=""
                        @click="complement_load = false"
                      >
                        Cargar otro archivo
                        <v-icon right v-text="'mdi-file'" />
                      </v-btn>
                    </v-col>
                    <v-col cols="12">
                      <v-row dense>
                        <v-col cols="12">
                          <v-alert
                            v-if="!complement.xml_valid"
                            type="error"
                            dense
                          >
                            <div
                              class="text-center"
                              v-text="
                                'La factura XML no cuenta con un formato valido de lectura SAT'
                              "
                            />
                          </v-alert>

                          <v-alert
                            v-if="
                              complement.xml_valid &&
                              (!complement.sat_status || complement.bill_exist)
                            "
                            type="error"
                            dense
                          >
                            <div class="text-center">
                              <div
                                v-if="!complement.sat_status"
                                v-text="'Documento XML no valido ante el SAT'"
                              />
                              <div
                                v-if="complement.bill_exist"
                                v-text="
                                  'El folio de este complemento ya ha sido registrado'
                                "
                              />
                            </div>
                          </v-alert>
                          <v-alert
                            v-if="
                              complement.xml_valid &&
                              (!complement.receiver_code_tax ||
                                !complement.receiver_name ||
                                !complement.amount_valid)
                            "
                            type="warning"
                            dense
                          >
                            <div class="text-center">
                              <div
                                v-if="!complement.receiver_code_tax"
                                v-text="
                                  'El RFC del receptor no concuerda con el de Sinergia Médica'
                                "
                              />
                              <div
                                v-if="!complement.receiver_name"
                                v-text="
                                  'La razón social del receptor no concuerda con el de Sinergia Médica'
                                "
                              />
                              <div
                                v-if="!complement.amount_valid"
                                v-text="
                                  'El monto del complemento no concuerda con el registrado'
                                "
                              />
                            </div>
                          </v-alert>
                        </v-col>
                        <v-col cols="12" v-if="complement.xml_valid">
                          <v-row dense>
                            <v-col cols="12" sm="12" md="3">
                              <ViewData
                                label="Receptor"
                                :value="complement.xml.Receptor.attr.Nombre"
                              />
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <ViewData
                                label="RFC"
                                :value="complement.xml.Receptor.attr.Rfc"
                              />
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <ViewData
                                label="UUID"
                                :value="
                                  complement.xml.Complemento.TimbreFiscalDigital
                                    .attr.UUID
                                "
                              />
                            </v-col>
                            <v-col cols="12" sm="12" md="3"> </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <ViewData
                                label="Emisor"
                                :value="complement.xml.Emisor.attr.Nombre"
                              />
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <ViewData
                                label="RFC"
                                :value="complement.xml.Emisor.attr.Rfc"
                              />
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <ViewData
                                label="Folio"
                                :value="complement.xml.attr.Folio"
                              />
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <ViewData
                                label="Fecha y hora de expedición"
                                :value="complement.xml.attr.Fecha"
                              />
                            </v-col>
                            <v-col cols="12">
                              <span
                                class="text-caption font-weight-bold"
                                v-text="'XML'"
                              />
                              <small>
                                <v-simple-table dense>
                                  <template v-slot:default>
                                    <thead>
                                      <tr>
                                        <th v-text="'Complemento'" />
                                        <th v-text="'Monto'" />
                                        <th v-text="'UUID Relacionado'" />
                                        <th v-text="'Folio'" />
                                        <th v-text="'Parcialidad'" />
                                        <th v-text="'Saldo ant.'" />
                                        <th v-text="'Saldo insoluto'" />
                                        <th v-text="'Imp. pagado'" />
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td
                                          class="text-caption"
                                          v-text="
                                            complement.xml.Complemento.Pagos
                                              .Pago.attr.FechaPago
                                          "
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="
                                            complement.xml.Complemento.Pagos
                                              .Pago.attr.Monto
                                          "
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="
                                            complement.xml.Complemento.Pagos
                                              .Pago.DoctoRelacionado.attr
                                              .IdDocumento
                                          "
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="
                                            complement.xml.Complemento.Pagos
                                              .Pago.DoctoRelacionado.attr.Folio
                                          "
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="
                                            complement.xml.Complemento.Pagos
                                              .Pago.DoctoRelacionado.attr
                                              .NumParcialidad
                                          "
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="
                                            numberFormat(
                                              complement.xml.Complemento.Pagos
                                                .Pago.DoctoRelacionado.attr
                                                .ImpSaldoAnt
                                            )
                                          "
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="
                                            numberFormat(
                                              complement.xml.Complemento.Pagos
                                                .Pago.DoctoRelacionado.attr
                                                .ImpSaldoInsoluto
                                            )
                                          "
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="
                                            numberFormat(
                                              complement.xml.Complemento.Pagos
                                                .Pago.DoctoRelacionado.attr
                                                .ImpPagado
                                            )
                                          "
                                        />
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                                <v-simple-table dense>
                                  <template v-slot:default>
                                    <thead>
                                      <tr>
                                        <th v-text="'#'" />
                                        <th v-text="'Unidad'" />
                                        <th v-text="'Cantidad'" />
                                        <th v-text="'Clave'" />
                                        <th v-text="'Descripción'" />
                                        <th v-text="'Precio'" />
                                        <th v-text="'Importe'" />
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        v-for="(concept, j) in complement.xml
                                          .Conceptos.Concepto"
                                        :key="j"
                                      >
                                        <td
                                          class="text-caption font-weight-bold"
                                          v-text="j + 1"
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="concept.attr.ClaveUnidad"
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="concept.attr.Cantidad"
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="concept.attr.ClaveProdServ"
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="concept.attr.Descripcion"
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="
                                            numberFormat(
                                              concept.attr.ValorUnitario
                                            )
                                          "
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="
                                            numberFormat(concept.attr.Importe)
                                          "
                                        />
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td
                                          class="text-caption font-weight-bold"
                                          v-text="'Subtotal'"
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="
                                            numberFormat(
                                              complement.xml.attr.SubTotal
                                            )
                                          "
                                        />
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td
                                          class="text-caption font-weight-bold"
                                          v-text="'Descuento'"
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="
                                            numberFormat(
                                              complement.xml.attr.Descuento
                                                ? complement.xml.attr.Descuento
                                                : 0
                                            )
                                          "
                                        />
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td
                                          class="text-caption font-weight-bold"
                                          v-text="'Total'"
                                        />
                                        <td
                                          class="text-caption font-weight-bold"
                                          v-text="
                                            numberFormat(
                                              complement.xml.attr.Total
                                            )
                                          "
                                        />
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                              </small>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12">
                          <v-btn
                            block
                            small
                            color="success"
                            @click="complementSave"
                            :disabled="
                              !complement.xml_valid ||
                              !complement.sat_status ||
                              complement.bill_exist ||
                              !complement.receiver_code_tax ||
                              !complement.receiver_name ||
                              !complement.amount_valid
                            "
                          >
                            Cargar
                            <v-icon right v-text="'mdi-file'" />
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row
                    dense
                    v-if="!complement_load && complement.complement_folio"
                  >
                    <v-col cols="12">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th v-text="'Folio'" />
                              <th v-text="'Archivos'" width="110px" />
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                class="text-caption"
                                v-text="complement.complement_folio"
                              />
                              <td>
                                <v-tooltip left>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      class="mr-1"
                                      v-on="on"
                                      fab
                                      x-small
                                      dark
                                      color="cyan darken-3"
                                      :href="
                                        url_documents +
                                        '/complements/' +
                                        complement.url_complement_pdf
                                      "
                                      target="_blank"
                                    >
                                      <v-icon v-text="'mdi-file-download'" />
                                    </v-btn>
                                  </template>
                                  <span v-text="'Descargar PDF'" />
                                </v-tooltip>
                                <v-tooltip left>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      class="mr-1"
                                      v-on="on"
                                      fab
                                      x-small
                                      dark
                                      color="teal darken-2"
                                      :href="
                                        url_documents +
                                        '/complements/' +
                                        complement.url_complement_xml
                                      "
                                      target="_blank"
                                    >
                                      <v-icon v-text="'mdi-xml'" />
                                    </v-btn>
                                  </template>
                                  <span v-text="'Descargar XML'" />
                                </v-tooltip>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" v-if="file_pay_data">
              <!-- insurance_payments.length == 0 || !insurance_payments_proceed -->
              <v-btn
                :disabled="insurance_payments.length == 0"
                block
                color="success"
                small
                @click.prevent="insurancePaymentSave()"
              >
                Guardar información
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="bill_observation_dialog"
      scrollable
      persistent
      max-width="1200px"
    >
      <v-card
        tile
        :loading="bill_observation_dialog_loading"
        :disabled="bill_observation_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title v-text="'Observación rechazo'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="bill_observation_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row class="pt-3">
            <v-col cols="12">
              <v-textarea v-model="observation" label="" rows="1" dense />
            </v-col>
            <v-col cols="12">
              <v-btn
                block
                :color="observation ? 'error' : 'warning'"
                small
                @click.prevent="billObservationUpdate"
              >
                Guardar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="bill_provider_response_dialog"
      scrollable
      persistent
      max-width="1200px"
    >
      <v-card
        tile
        :loading="bill_provider_response_dialog_loading"
        :disabled="bill_provider_response_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title v-text="'Respuesta a rechazo'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="bill_provider_response_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row class="pt-3">
            <v-col cols="12">
              <v-textarea
                v-model="bill_provider_response"
                label=""
                rows="1"
                dense
              />
            </v-col>
            <v-col cols="12">
              <v-btn
                block
                color="warning"
                small
                @click.prevent="billProviderResponseUpdate"
              >
                Enviar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="bill_amount_dialog"
      scrollable
      persistent
      max-width="1000px"
    >
      <v-card
        tile
        :loading="bill_amount_dialog_loading"
        :disabled="bill_amount_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title v-text="'Montos'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="bill_amount_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="data">
          <v-form ref="form_bill_amount" v-on:submit.prevent lazy-validation>
            <v-row class="pt-5" dense>
              <v-col cols="12">
                <v-alert type="warning" dense>
                  <v-row dense>
                    <v-col cols="12" sm="12" md="6">
                      <b v-text="'Total a pagar '" />
                      <span v-text="numberFormat(data.pay_amount)" />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <b v-text="'Cobro Aseg. '" />
                      <span v-text="numberFormat(data.insurance_pay_amount)" />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <b v-text="'Total a pagar recomendado* '" />
                      <span
                        v-text="numberFormat(bill_amount_pay_amount_with_min)"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <b v-text="'Descuento mínimo '" />
                      <span v-text="numberFormat(data.min_discount_amount)" />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <b v-text="'Desc. factura | Condiciones '" />
                      <span v-text="numberFormat(data.bills_discount_amount)" />
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  label="Total a pagar*"
                  v-model="bill_amount_pay_amount"
                  :rules="rules.required"
                  type="number"
                  dense
                  min="0"
                  :max="data.bills_amount"
                />
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  label="Cobro Aseg."
                  v-model="bill_amount_insurance_pay_amount"
                  :rules="rules.required"
                  type="number"
                  dense
                  min="0"
                  :max="data.bills_amount"
                />
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  label="Deducible Obs."
                  v-model="bill_deductibles_amount"
                  :rules="rules.required"
                  type="number"
                  dense
                  min="0"
                />
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  label="Coaseguro Obs."
                  v-model="bill_coinsurances_amount"
                  :rules="rules.required"
                  type="number"
                  dense
                  min="0"
                />
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  label="Desc. Calc. Obs."
                  v-model="bill_amount_discounts_amount"
                  :rules="rules.required"
                  type="number"
                  dense
                  min="0"
                  :max="data.bills_amount"
                />
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-select
                  label="Tipo descuento"
                  v-model="bill_discount_type_id"
                  :items="amount_discount_types"
                  item-value="id"
                  :item-text="(v) => v.rha_bill_discount_type"
                  dense
                  :loading="amount_discount_types_loading"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="bill_amount_observation_bill"
                  label="Observación en factura"
                  rows="1"
                  dense
                />
              </v-col>
              <v-col v-if="login.role_id == 1" cols="12">
                <v-checkbox
                  v-model="bill_amount_use_insurance_pay_amount"
                  label="Utilizar Cobro Aseg. en factura"
                />
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  dark
                  color="pink"
                  small
                  @click.prevent="billAmountsHandle"
                >
                  Guardar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="video_dialog" scrollable persistent max-width="1200px">
      <v-card tile>
        <v-toolbar dark dense>
          <v-toolbar-title v-text="'Vídeo de ayuda'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="video_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row class="pt-3">
            <v-col cols="12" class="text-center">
              Presentamos el nuevo módulo de facturación, se anexa video con
              instrucciones de ingreso y uso.
              <br />
              <small> En caso de dudas comunicarse al 5552498383 </small>
            </v-col>
            <v-col cols="12">
              <div
                style="position: relative; padding-bottom: 56.25%; height: 0"
              >
                <iframe
                  src="https://www.loom.com/embed/7d0812857e9c4f37bd1562c7ff02ab0a"
                  frameborder="0"
                  webkitallowfullscreen
                  mozallowfullscreen
                  allowfullscreen
                  style="
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                  "
                />
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="rha_documents_dialog"
      scrollable
      persistent
      max-width="1400px"
    >
      <v-card
        tile
        :loading="rha_documents_dialog_loading"
        :disabled="rha_documents_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title
            v-text="
              `${
                rha_documents ? `${rha_documents.rha_folio} | ` : ''
              }Documentación de ingreso`
            "
          />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="rha_documents_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="rha_documents && survey">
          <v-row class="pt-3">
            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span
                      class="text-caption"
                      v-text="
                        `SOLICITUD DE INGRESO (${rha_documents.rha_documents.length})`
                      "
                    />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <v-card-text>
                  <v-simple-table
                    dense
                    v-if="rha_documents.rha_documents.length > 0"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th width="20px" v-text="'#'" />
                          <th width="200px" v-text="'Fecha'" />
                          <th width="300px" v-text="'Tipo'" />
                          <th v-text="'Descripción'" />
                          <th width="50px" v-text="'Visible'" />
                          <th width="50px" />
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, i) in rha_documents.rha_documents"
                          :key="`${i}_${item.url}`"
                        >
                          <td class="font-weight-bold" v-text="i + 1" />
                          <td v-text="item.created_at" />
                          <td v-text="item.document" />
                          <td v-text="item.description" />
                          <td>
                            <v-checkbox
                              v-model="item.external_display"
                              @change="docExtDiplay(item, 'rha_documents')"
                            />
                          </td>
                          <td>
                            <v-tooltip left>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  x-small
                                  fab
                                  outlined
                                  class="mr-1"
                                  color="warning"
                                  :href="url_documents + '/rhas/' + item.url"
                                  target="_blank"
                                >
                                  <v-icon v-text="'mdi-download'" />
                                </v-btn>
                              </template>
                              <span v-text="'Descargar'" />
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" v-if="rha_documents.express_letter">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption" v-text="'CARTA EXPRÉS (1)'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <v-card-text>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th width="20px" v-text="'#'" />
                          <th width="200px" v-text="'Fecha'" />
                          <th v-text="'Tipo'" />
                          <th width="50px" />
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="font-weight-bold" v-text="'1'" />
                          <td v-text="rha.created_at" />
                          <td v-text="'CARTA EXPRÉS'" />
                          <td>
                            <v-tooltip left>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  x-small
                                  fab
                                  outlined
                                  class="mr-1"
                                  color="warning"
                                  :href="
                                    url_pdf +
                                    '/cartaexpres/' +
                                    rha_documents.rha_id
                                  "
                                  target="_blank"
                                >
                                  <v-icon v-text="'mdi-download'" />
                                </v-btn>
                              </template>
                              <span v-text="'Descargar'" />
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span
                      class="text-caption"
                      v-text="
                        `MONTOS (${rha_documents.rha_amount_documents.length})`
                      "
                    />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <v-card-text>
                  <v-simple-table
                    dense
                    v-if="rha_documents.rha_amount_documents.length > 0"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th width="20px" v-text="'#'" />
                          <th width="200px" v-text="'Fecha'" />
                          <th width="300px" v-text="'Tipo'" />
                          <th v-text="'Descripción'" />
                          <th width="50px" v-text="'Visible'" />
                          <th width="50px" />
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(
                            item, i
                          ) in rha_documents.rha_amount_documents"
                          :key="`${i}_${item.url}`"
                        >
                          <td class="font-weight-bold" v-text="i + 1" />
                          <td v-text="item.created_at" />
                          <td v-text="item.document" />
                          <td v-text="item.description" />
                          <td>
                            <v-checkbox
                              v-model="item.external_display"
                              @change="
                                docExtDiplay(item, 'rha_amount_documents')
                              "
                            />
                          </td>
                          <td>
                            <v-tooltip left>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  x-small
                                  fab
                                  outlined
                                  class="mr-1"
                                  color="warning"
                                  :href="
                                    url_documents + '/rha_amounts/' + item.url
                                  "
                                  target="_blank"
                                >
                                  <v-icon v-text="'mdi-download'" />
                                </v-btn>
                              </template>
                              <span v-text="'Descargar'" />
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span
                      class="text-caption"
                      v-text="
                        `NOTAS DE EVOLUCIÓN (${rha_documents.rha_evolution_documents.length})`
                      "
                    />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <v-card-text>
                  <v-simple-table
                    dense
                    v-if="rha_documents.rha_evolution_documents.length > 0"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th width="20px" v-text="'#'" />
                          <th width="200px" v-text="'Fecha'" />
                          <th width="300px" v-text="'Tipo'" />
                          <th v-text="'Descripción'" />
                          <th width="50px" v-text="'Visible'" />
                          <th width="50px" />
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(
                            item, i
                          ) in rha_documents.rha_evolution_documents"
                          :key="`${i}_${item.url}`"
                        >
                          <td class="font-weight-bold" v-text="i + 1" />
                          <td v-text="item.created_at" />
                          <td v-text="item.document" />
                          <td v-text="item.description" />
                          <td>
                            <v-checkbox
                              v-model="item.external_display"
                              @change="
                                docExtDiplay(item, 'rha_evolution_documents')
                              "
                            />
                          </td>
                          <td>
                            <v-tooltip left>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  x-small
                                  fab
                                  outlined
                                  class="mr-1"
                                  color="warning"
                                  :href="
                                    url_documents +
                                    '/rha_evolutions/' +
                                    item.url
                                  "
                                  target="_blank"
                                >
                                  <v-icon v-text="'mdi-download'" />
                                </v-btn>
                              </template>
                              <span v-text="'Descargar'" />
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span
                      class="text-caption"
                      v-text="
                        `NOTAS QUIRÚRGICAS (${rha_documents.rha_surgical_documents.length})`
                      "
                    />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <v-card-text>
                  <v-simple-table
                    dense
                    v-if="rha_documents.rha_surgical_documents.length > 0"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th width="20px" v-text="'#'" />
                          <th width="200px" v-text="'Fecha'" />
                          <th width="300px" v-text="'Tipo'" />
                          <th v-text="'Descripción'" />
                          <th width="50px" v-text="'Visible'" />
                          <th width="50px" />
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(
                            item, i
                          ) in rha_documents.rha_surgical_documents"
                          :key="`${i}_${item.url}`"
                        >
                          <td class="font-weight-bold" v-text="i + 1" />
                          <td v-text="item.created_at" />
                          <td v-text="item.document" />
                          <td v-text="item.description" />
                          <td>
                            <v-checkbox
                              v-model="item.external_display"
                              @change="
                                docExtDiplay(item, 'rha_surgical_documents')
                              "
                            />
                          </td>
                          <td>
                            <v-tooltip left>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  x-small
                                  fab
                                  outlined
                                  class="mr-1"
                                  color="warning"
                                  :href="
                                    url_documents + '/rha_surgicals/' + item.url
                                  "
                                  target="_blank"
                                >
                                  <v-icon v-text="'mdi-download'" />
                                </v-btn>
                              </template>
                              <span v-text="'Descargar'" />
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption" v-text="`ENCUESTA`" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <v-card-text>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th width="20px" v-text="'#'" />
                          <th width="200px" v-text="'Fecha'" />
                          <th v-text="'Tipo'" />
                          <th width="50px" />
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="font-weight-bold" v-text="'1'" />
                          <td
                            v-text="survey.created_at ? survey.created_at : '-'"
                          />
                          <td
                            v-text="
                              survey.survey_stadistic_id
                                ? survey.survey_stadistic
                                : 'SIN CONTESTAR'
                            "
                          />
                          <td>
                            <v-tooltip left>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  x-small
                                  fab
                                  outlined
                                  class="mr-1"
                                  color="warning"
                                  :href="
                                    survey_file
                                      ? url_documents +
                                        '/rha_surveys/' +
                                        survey.url
                                      : url_pdf +
                                        '/encuesta/' +
                                        rha_documents.rha_id
                                  "
                                  target="_blank"
                                >
                                  <v-icon v-text="'mdi-download'" />
                                </v-btn>
                              </template>
                              <span v-text="'Descargar'" />
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span
                      class="text-caption"
                      v-text="
                        `APERTURAS (${rha_documents.rha_aperturas.length})`
                      "
                    />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <v-card-text>
                  <v-simple-table
                    dense
                    v-if="rha_documents.rha_aperturas.length > 0"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th width="20px" v-text="'#'" />
                          <th width="200px" v-text="'Fecha'" />
                          <th v-text="'Descripción'" />
                          <th width="50px" v-text="'Visible'" />
                          <th width="50px" />
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, i) in rha_documents.rha_aperturas"
                          :key="`${i}_${item.url}`"
                        >
                          <td class="font-weight-bold" v-text="i + 1" />
                          <td v-text="item.created_at" />
                          <td v-text="item.description" />
                          <td>
                            <v-checkbox
                              v-model="item.external_display"
                              @change="
                                docExtDiplay(item, 'rha_opening_documents')
                              "
                            />
                          </td>
                          <td>
                            <v-tooltip left>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  x-small
                                  fab
                                  outlined
                                  class="mr-1"
                                  color="warning"
                                  :href="
                                    url_documents + '/rha_opening/' + item.url
                                  "
                                  target="_blank"
                                >
                                  <v-icon v-text="'mdi-download'" />
                                </v-btn>
                              </template>
                              <span v-text="'Descargar'" />
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span
                      class="text-caption"
                      v-text="`LOAS (${rha_documents.rha_loas.length})`"
                    />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <v-card-text>
                  <v-simple-table
                    dense
                    v-if="rha_documents.rha_loas.length > 0"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th width="20px" v-text="'#'" />
                          <th width="200px" v-text="'Fecha'" />
                          <th width="300px" v-text="'Tipo'" />
                          <th v-text="'Descripción'" />
                          <th width="50px" v-text="'Visible'" />
                          <th width="50px" />
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, i) in rha_documents.rha_loas"
                          :key="`${i}_${item.url}`"
                        >
                          <td class="font-weight-bold" v-text="i + 1" />
                          <td v-text="item.created_at" />
                          <td v-text="item.document" />
                          <td v-text="item.description" />
                          <td>
                            <v-checkbox
                              v-model="item.external_display"
                              @change="docExtDiplay(item, 'rha_loa_documents')"
                            />
                          </td>
                          <td>
                            <v-tooltip left>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  x-small
                                  fab
                                  outlined
                                  class="mr-1"
                                  color="warning"
                                  :href="
                                    url_documents + '/rha_loas/' + item.url
                                  "
                                  target="_blank"
                                >
                                  <v-icon v-text="'mdi-download'" />
                                </v-btn>
                              </template>
                              <span v-text="'Descargar'" />
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="calc_pay_day_dialog"
      scrollable
      persistent
      max-width="500px"
    >
      <v-card
        tile
        :loading="calc_pay_day_dialog_loading"
        :disabled="calc_pay_day_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title v-text="'Recalcular'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="calc_pay_day_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="data">
          <v-row class="pt-3">
            <v-col cols="12" class="text-center">
              <p>
                Esta función recalculara el dia de recepción y pago al hospital
                tomando en cuenta las siguientes opciones.
              </p>
              <p>
                <b v-text="'Fecha de registro: '" />
                <span v-text="data.created_at" />
              </p>
            </v-col>
            <v-col cols="6">
              <v-btn
                small
                color="warning"
                block
                v-text="'Fecha de registro'"
                @click="calcPayDay(data.id, 1)"
              />
            </v-col>
            <v-col cols="6">
              <v-btn
                small
                color="info"
                block
                v-text="'Fecha y hora actual'"
                @click="calcPayDay(data.id, 0)"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="bill_observation_detail_dialog"
      scrollable
      persistent
      max-width="1200px"
    >
      <v-card
        tile
        :loading="bill_observation_detail_dialog_loading"
        :disabled="bill_observation_detail_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title v-text="'Observación administrativa'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="bill_observation_detail_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row class="pt-3">
            <v-col cols="12">
              <v-textarea
                v-model="observation_detail"
                label=""
                rows="1"
                dense
              />
            </v-col>
            <v-col cols="12">
              <v-btn
                block
                color="lime darken-3"
                dark
                small
                @click.prevent="billObservationDetailUpdate"
              >
                Guardar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="insurance_payment_dialog"
      scrollable
      persistent
      max-width="900px"
    >
      <v-card
        v-if="insurance_payment_dialog"
        tile
        :loading="insurance_payment_dialog_loading"
        :disabled="insurance_payment_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title v-text="`Pago aseguradora`" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="insurance_payment_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form_insurance_payment" lazy-validation>
            <v-row dense>
              <v-col cols="12">
                <v-spacer />
              </v-col>
              <v-col cols="12" xs="12" md="12" v-if="insurance_payment_receipt">
                <ViewData label="Folio" :value="insurance_payment.folio" />
              </v-col>
              <v-col cols="12" xs="12" md="4" v-if="insurance_payment_receipt">
                <ViewData
                  label="Payment ID"
                  :value="insurance_payment.payment_id"
                />
              </v-col>
              <v-col cols="12" xs="12" md="4" v-if="insurance_payment_receipt">
                <ViewData
                  label="F. Pago"
                  :value="insurance_payment.paid_date_show"
                />
              </v-col>
              <v-col cols="12" xs="12" md="4" v-if="insurance_payment_receipt">
                <ViewData
                  label="Monto"
                  :value="numberFormat(insurance_payment.amount_show)"
                />
              </v-col>
              <v-col cols="12" xs="12" md="4">
                <v-text-field
                  label="Payment ID*"
                  v-model="insurance_payment.payment_id"
                  type="text"
                  dense
                  :rules="rules.required"
                />
              </v-col>
              <v-col cols="12" xs="12" md="4">
                <DatePicker
                  label="F. Pago*"
                  :model.sync="insurance_payment.paid_date"
                  :rules="rules.required"
                />
              </v-col>
              <v-col cols="12" xs="12" md="4">
                <v-text-field
                  label="Monto*"
                  v-model="insurance_payment.amount"
                  type="number"
                  dense
                  min="0"
                  :rules="rules.required"
                />
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  :color="insurance_payment_receipt ? 'warning' : 'success'"
                  dark
                  small
                  @click.prevent="insurancePaymentSubmit"
                  v-text="
                    insurance_payment_receipt
                      ? 'Generar complemento de pago'
                      : 'Añadir pago'
                  "
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="provider_type_dialog"
      scrollable
      persistent
      max-width="600px"
    >
      <v-card
        v-if="provider_type_dialog"
        tile
        :loading="provider_type_dialog_loading"
        :disabled="provider_type_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title v-text="`Tipo de servicio`" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="provider_type_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form_provider_types" lazy-validation>
            <v-row class="pt-3">
              <v-col cols="12" xs="12" md="12">
                <v-autocomplete
                  label="Tipo*"
                  v-model="provider_type.provider_type_id"
                  :items="provider_types"
                  item-value="id"
                  :item-text="(v) => v.provider_type"
                  :loading="provider_types_loading"
                  dense
                  :rules="rules.required"
                />
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  color="success"
                  dark
                  small
                  @click.prevent="providerType"
                  v-text="'Guardar'"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="rha_report_dialog"
      scrollable
      persistent
      max-width="600px"
    >
      <v-card
        v-if="rha_report_dialog"
        tile
        :loading="rha_report_dialog_loading"
        :disabled="rha_report_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title v-text="`Editar fechas linea 12`" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="rha_report_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form_rha_report" lazy-validation>
            <v-row class="pt-5">
              <v-col cols="12" xs="12" md="6">
                <DatePicker
                  label="Fecha ingreso reporte"
                  :model.sync="rha_admission_date_report"
                  clean
                  all_date
                />
              </v-col>
              <v-col cols="12" xs="12" md="6">
                <DatePicker
                  label="Fecha alta reporte"
                  :model.sync="rha_discharge_report"
                  clean
                  all_date
                />
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  color="success"
                  dark
                  small
                  @click.prevent="rhaReport"
                  v-text="'Guardar'"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="insurance_complement_dialog"
      scrollable
      persistent
      max-width="600px"
    >
      <v-card
        v-if="insurance_complement_dialog"
        tile
        :loading="insurance_complement_dialog_loading"
        :disabled="insurance_complement_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title
            v-text="`Cargar complemento de pago en portal de aseguradora`"
          />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="insurance_complement_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form_insurance_complement" lazy-validation>
            <v-row class="pt-5">
              <v-col cols="12" xs="12" md="12">
                <DatePicker
                  label="Fecha"
                  :model.sync="insurance_complement"
                  :rules="rules.required"
                />
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  color="success"
                  dark
                  small
                  @click.prevent="insuranceComplement"
                  v-text="'Guardar'"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="rha_store_dialog"
      scrollable
      persistent
      max-width="2000px"
    >
      <v-card
        v-if="rha_store_dialog"
        tile
        :loading="rha_store_dialog_loading"
        :disabled="rha_store_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title v-text="`Ingreso hospitalario | Crear`" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="rha_store_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form_rha_store" lazy-validation>
            <v-row class="pt-3">
              <v-col cols="12">
                <v-card tile>
                  <v-toolbar elevation="1" dense>
                    <v-toolbar-title>
                      <span class="text-caption" v-text="'GENERAL'" />
                    </v-toolbar-title>
                    <v-spacer />
                    <v-toolbar-items> </v-toolbar-items>
                  </v-toolbar>
                  <v-spacer />
                  <v-card-text>
                    <v-row dense>
                      <v-col
                        cols="12"
                        xs="12"
                        md="4"
                        v-if="login.role_id === 1"
                      >
                        <v-autocomplete
                          label="Usuario*"
                          v-model="rha_store.user_id"
                          :rules="rules.required"
                          :items="rha_users"
                          item-value="id"
                          :item-text="(v) => v.email"
                          :loading="rha_users_loading"
                          v-on:change="rhaUserInsurances()"
                          dense
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4" v-if="rha_store.user_id">
                        <v-autocomplete
                          label="Aseguradora*"
                          v-model="rha_store.insurance_id"
                          :rules="rules.required"
                          :items="rha_insurances"
                          item-value="insurance_id"
                          :item-text="(v) => v.name"
                          :loading="rha_insurances_loading"
                          dense
                          @change="rhaUserProviders"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        md="4"
                        v-if="
                          rha_store.user_id &&
                          rha_store.insurance_id &&
                          rha_store.insurance_id != 2
                        "
                      >
                        <v-autocomplete
                          label="Hospital*"
                          v-model="rha_store.provider_id"
                          :rules="rules.required"
                          :items="rha_providers"
                          item-value="id"
                          :item-text="(v) => v.trade_name"
                          :loading="rha_providers_loading"
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                v-if="
                  rha_store.insurance_id &&
                  rha_store.insurance_id != 2 &&
                  rha_store.provider_id &&
                  !rha_store.insured_id
                "
              >
                <v-card
                  tile
                  :loading="rha_insured_loading"
                  :disabled="rha_insured_loading"
                >
                  <v-toolbar elevation="1" dense>
                    <v-toolbar-title>
                      <span class="text-caption" v-text="'ASEGURADO'" />
                      <small
                        class="text-caption"
                        v-text="' | Pulsa enter sobre un campo para buscar'"
                      />
                    </v-toolbar-title>
                    <v-spacer />
                    <v-toolbar-items>
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            color="info"
                            icon
                            @click.prevent="rhaInsuredSearchHandle"
                          >
                            <v-icon v-text="'mdi-account-search'" />
                          </v-btn>
                        </template>
                        <span v-text="'Buscar asegurado'" />
                      </v-tooltip>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-spacer />
                  <v-card-text>
                    <v-row dense>
                      <v-col
                        cols="12"
                        xs="12"
                        :md="find_full_last_name ? 3 : 4"
                      >
                        <v-text-field
                          label="ID SM / Póliza"
                          v-model="rha_insured_search.enrollment"
                          type="text"
                          v-on:keyup.enter="rhaInsuredSearchHandle"
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        :md="find_full_last_name ? 3 : 4"
                      >
                        <v-text-field
                          label="Nombre"
                          v-model="rha_insured_search.name"
                          type="text"
                          v-on:keyup.enter="rhaInsuredSearchHandle"
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        :md="find_full_last_name ? 3 : 4"
                      >
                        <v-text-field
                          label="A. Paterno"
                          v-model="rha_insured_search.first_surname"
                          type="text"
                          v-on:keyup.enter="rhaInsuredSearchHandle"
                          dense
                        />
                      </v-col>
                      <v-col
                        v-if="find_full_last_name"
                        cols="12"
                        xs="12"
                        md="3"
                      >
                        <v-text-field
                          label="A. Materno"
                          v-model="rha_insured_search.second_surname"
                          type="text"
                          v-on:keyup.enter="valitySearchInsureds"
                          dense
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-btn
                          block
                          color="info"
                          small
                          @click.prevent="rhaInsuredSearchHandle"
                        >
                          <v-icon left v-text="'mdi-account-search'" />
                          Buscar asegurado
                        </v-btn>
                      </v-col>
                      <v-col
                        cols="12"
                        v-if="rha_insured_search_fail && !rha_insured_form"
                      >
                        <v-alert type="warning" dense>
                          <v-row align="center">
                            <v-col class="text-center">
                              Asegurado no encontrado en sistema, ¿Desea
                              registrar manualmente la información?
                            </v-col>
                            <v-col class="shrink">
                              <v-btn
                                small
                                v-text="'Aceptar'"
                                @click.prevent="rhaInsuredForm"
                              />
                            </v-col>
                          </v-row>
                        </v-alert>
                      </v-col>
                      <v-col cols="12" v-if="rha_insured_search_error">
                        <v-alert type="warning" dense>
                          <v-row align="center">
                            <v-col class="text-center">
                              Los parámetros de busqueda no son suficientes para
                              encontrar un registro. Asegúrese de llenar
                              correctamente la información.
                            </v-col>
                          </v-row>
                        </v-alert>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" v-if="rha_store.insured_id">
                <v-card tile>
                  <v-toolbar elevation="1" dense>
                    <v-toolbar-title>
                      <span class="text-caption" v-text="'ASEGURADO'" />
                    </v-toolbar-title>
                    <v-spacer />
                    <v-toolbar-items>
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            color="warning"
                            icon
                            @click.prevent="rhaInsuredRemove"
                          >
                            <v-icon v-text="'mdi-account-reactivate'" />
                          </v-btn>
                        </template>
                        <span v-text="'Volver a buscar'" />
                      </v-tooltip>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-spacer />
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" xs="12" md="3">
                        <v-text-field
                          label="ID SM / Póliza"
                          v-model="rha_store.enrollment"
                          maxlength="10"
                          type="text"
                          :disabled="!rha_insured_form"
                          dense
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <v-text-field
                          label="Nombre*"
                          v-model="rha_store.name"
                          :rules="rules.required"
                          maxlength="50"
                          type="text"
                          :disabled="!rha_insured_form"
                          dense
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <v-text-field
                          label="A. Paterno*"
                          v-model="rha_store.first_surname"
                          :rules="rules.required"
                          maxlength="25"
                          type="text"
                          :disabled="!rha_insured_form"
                          dense
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <v-text-field
                          label="A. Materno"
                          v-model="rha_store.second_surname"
                          maxlength="25"
                          type="text"
                          :disabled="!rha_insured_form"
                          dense
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="9">
                        <v-text-field
                          label="Contratante*"
                          v-model="rha_store.contractor"
                          :rules="rules.required"
                          maxlength="255"
                          type="text"
                          :disabled="!rha_insured_form"
                          dense
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <v-switch
                          class="p-0"
                          label="Titular"
                          v-model="rha_store.policyholder"
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        md="3"
                        v-if="!rha_store.policyholder"
                      >
                        <v-text-field
                          label="Nombre (dependiente)*"
                          v-model="rha_store.dependent_name"
                          :rules="rules.required"
                          maxlength="50"
                          type="text"
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        md="3"
                        v-if="!rha_store.policyholder"
                      >
                        <v-text-field
                          label="A. Paterno (dependiente)*"
                          v-model="rha_store.dependent_first_surname"
                          :rules="rules.required"
                          maxlength="25"
                          type="text"
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        md="3"
                        v-if="!rha_store.policyholder"
                      >
                        <v-text-field
                          label="A. Materno (dependiente)"
                          v-model="rha_store.dependent_second_surname"
                          maxlength="25"
                          type="text"
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        v-if="rha_insured_expired"
                        class="text-center"
                      >
                        <v-alert type="error" dense>
                          El asegurado tiene la vigencia vencida.
                        </v-alert>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" v-if="rha_store.insured_id">
                <v-card tile>
                  <v-toolbar elevation="1" dense>
                    <v-toolbar-title>
                      <span class="text-caption" v-text="'INGRESO'" />
                    </v-toolbar-title>
                    <v-spacer />
                    <v-toolbar-items> </v-toolbar-items>
                  </v-toolbar>
                  <v-spacer />
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" xs="12" md="3">
                        <v-text-field
                          label="Núm. episodio*"
                          v-model="rha_store.episode"
                          maxlength="30"
                          type="text"
                          dense
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <v-autocomplete
                          label="Tipo*"
                          v-model="rha_store.rha_admission_type_id"
                          :rules="rules.required"
                          :items="rha_admission_types"
                          item-value="id"
                          :item-text="(v) => v.rha_admission_type"
                          :loading="rha_admission_types_loading"
                          dense
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <DatePicker
                          label="Fecha*"
                          :model.sync="rha_store.admission_date"
                          :rules="rules.required"
                          clean
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <v-text-field
                          v-model="rha_store.admission_time"
                          label="Hora*"
                          :rules="rules.required"
                          type="time"
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" v-if="rha_store.insurance_id != 2">
                <v-btn
                  block
                  color="success"
                  dark
                  small
                  @click.prevent="rhaStore"
                  v-text="'Guardar'"
                />
              </v-col>
              <v-col cols="12" v-else>
                <v-alert type="info" dense>
                  Si no localiza el numero de folio y desea facturar favor de
                  ingresar a
                  <b>
                    <a
                      class="white--text"
                      href="https://sinergiamedica.info"
                      target="_BLANK"
                    >
                      sinergiamedica.info
                    </a>
                  </b>
                  con su mismo usuario y contraseña, en caso de ser necesario
                  comunicarse al 55 52 49 8383.
                </v-alert>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="rha_coinsidents_dialog"
      scrollable
      persistent
      max-width="1200"
    >
      <v-card tile>
        <v-toolbar dark dense>
          <v-toolbar-title v-text="'Coincidencias encontradas en ingresos'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="rha_coinsidents_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <td v-text="'Folio'" />
                      <td v-text="'ID SM / Póliza'" />
                      <td v-text="'Asegurado'" />
                      <td v-text="'Vigencia'" />
                      <td v-text="'Hospital'" />
                      <td v-text="'Municipio'" />
                      <td v-text="'Alta'" />
                      <td v-text="'F. ingreso'" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(rha_coinsident, i) in rha_coinsidents" :key="i">
                      <td v-text="415000 + rha_coinsident.id" />
                      <td
                        v-text="rha_coinsident.enrollment"
                        :class="
                          rha_coinsident.insured_id
                            ? ''
                            : 'orange--text text--darken-3'
                        "
                      />
                      <td
                        v-text="
                          `${rha_coinsident.name} ${rha_coinsident.first_surname} ${rha_coinsident.second_surname}`
                        "
                        :class="
                          rha_coinsident.insured_id
                            ? ''
                            : 'orange--text text--darken-3'
                        "
                      />
                      <td
                        v-text="
                          rha_coinsident.insured_id
                            ? rha_coinsident.insured.validity
                            : ''
                        "
                      />
                      <td v-text="rha_coinsident.provider.trade_name" />
                      <td
                        v-text="
                          rha_coinsident.provider.municipality.municipality +
                          ', ' +
                          rha_coinsident.provider.entity.abbreviation +
                          '.'
                        "
                      />
                      <td
                        v-text="
                          rha_coinsident.discharge
                            ? rha_coinsident.discharge
                            : 'Pendiente'
                        "
                      />
                      <td v-text="rha_coinsident.admission_date" />
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" class="text-right">
              <v-btn depressed color="primary" @click="rhaInsuredSearch">
                Seguir con la busqueda
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="rha_insureds_dialog"
      scrollable
      persistent
      max-width="1000"
    >
      <v-card
        tile
        :loading="rha_insureds_dialog_loading"
        :disabled="rha_insureds_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title v-text="'Asegurados encontrados'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="rha_insureds_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <td v-text="'ID'" />
                      <td v-text="'Asegurado'" />
                      <td />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(insured, i) in rha_insureds" :key="i">
                      <td v-text="insured.enrollment" />
                      <td
                        v-text="
                          `${insured.name} ${insured.first_surname} ${insured.second_surname}`
                        "
                      />
                      <td>
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              icon
                              small
                              color="warning"
                              @click="rhaInsuredData(insured.id)"
                            >
                              <v-icon v-text="'mdi-check'" />
                            </v-btn>
                          </template>
                          <span v-text="'Seleccionar'" />
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12">
              <v-alert type="warning" dense>
                <v-row align="center">
                  <v-col class="text-center">
                    Asegurado no encontrado, ¿Desea registrar manualmente la
                    información?
                  </v-col>
                  <v-col class="shrink">
                    <v-btn
                      small
                      v-text="'Aceptar'"
                      @click.prevent="rhaInsuredForm"
                    />
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="insurance_payments_unknowns_dialog"
      scrollable
      persistent
      max-width="1000"
    >
      <v-card
        tile
        :loading="insurance_payments_unknowns_dialog_loading"
        :disabled="insurance_payments_unknowns_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title v-text="'Núm. trámites no identificados'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="insurance_payments_unknowns_dialog = false"
            >
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="insurance_payments_unknowns">
          <v-row dense>
            <v-col cols="12">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <td v-text="'Núm. trámite'" />
                      <td v-text="'Fecha'" />
                      <td v-text="'Monto'" />
                      <td v-text="'Eliminar'" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v, i) in insurance_payments_unknowns" :key="i">
                      <td v-text="v.procedure" />
                      <td v-text="v.date" />
                      <td v-text="numberFormat(v.amount)" />
                      <td>
                        <v-simple-checkbox v-model="v.select" :ripple="false" />
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12">
              <v-tooltip right v-if="insurance_payments_unknowns.length > 0">
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    v-on="on"
                    fab
                    x-small
                    color="error"
                    @click.prevent="insurancePaymentsUnknownsDelete()"
                  >
                    <v-icon v-text="'mdi-trash-can'" />
                  </v-btn>
                </template>
                <span v-text="'Eliminar seleccionados'" />
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="rejects_payments_dialog"
      scrollable
      persistent
      max-width="1000"
    >
      <v-card tile>
        <v-toolbar dark dense>
          <v-toolbar-title
            v-text="'Historial de documentación aseguradora eliminada'"
          />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="rejects_payments_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text
          v-if="
            rejects_payments_dialog &&
            data.rha_bill_insurance_rejecteds.length > 0
          "
        >
          <v-row dense>
            <v-col cols="12" />
            <v-col
              v-for="(rejected, i) in data.rha_bill_insurance_rejecteds"
              :key="i"
              cols="12"
            >
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span
                      class="text-caption"
                      v-text="`NÚM. TRÁMITE ${rejected.insurance_procedure}`"
                    />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <v-spacer />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Fecha"
                        :value="
                          rejected.insurance_reception_date +
                          ' ' +
                          rejected.insurance_reception_time
                        "
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Promesa de pago"
                        :value="rejected.insurance_pay_date"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="F. eliminado"
                        :value="rejected.created_at"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <td v-text="'#'" />
                              <td v-text="'Folio'" />
                              <td v-text="'F. registro'" />
                              <td v-text="'F. pago'" />
                              <td v-text="'Monto'" />
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                payment, j
                              ) in rejected.rha_bill_insurance_payments"
                              :key="j"
                            >
                              <td v-text="j + 1" />
                              <td v-text="payment.folio" />
                              <td v-text="payment.created_at" />
                              <td v-text="payment.paid_date" />
                              <td v-text="numberFormat(payment.amount)" />
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="pending_dlg" scrollable persistent max-width="600px">
      <v-card tile>
        <v-toolbar color="deep-orange darken-2" dark dense>
          <v-toolbar-title> PENDIENTES </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="pending_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="mt-3 text-center">
              <div v-if="bill_comments_pending > 0">
                Fact. | Comentarios aseguradoras ({{ bill_comments_pending }})
              </div>
              <div v-if="provider_comments_pending > 0">
                Fact. Ingresos | Preguntas o comentarios ({{
                  provider_comments_pending
                }})
              </div>
              <div>
                <b>
                  ¡Favor de atender est{{
                    bill_comments_pending + provider_comments_pending > 0
                      ? "os "
                      : "e "
                  }}
                  pendientes lo antes posible!
                </b>
              </div>
              <v-icon color="deep-orange darken-1">mdi-alert</v-icon>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="rha_bill_clone_dialog"
      scrollable
      persistent
      max-width="600"
    >
      <v-card
        tile
        :disabled="rha_bill_clone_dialog_loading"
        :loading="rha_bill_clone_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> GENERAR NUEVO PAGO </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="rha_bill_clone_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="rha_bill_clone">
          <v-form v-on:submit.prevent ref="rha_bill_clone_form" lazy-validation>
            <v-row>
              <v-col cols="12" class="mt-3" />
              <v-col cols="12">
                <v-card tile>
                  <v-toolbar elevation="1" dense>
                    <v-toolbar-title>
                      <span class="text-caption" v-text="'DETALLE'" />
                    </v-toolbar-title>
                    <v-spacer />
                    <v-toolbar-items />
                  </v-toolbar>
                  <v-spacer />
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" sm="12" md="6">
                        <ViewData
                          label="Folio"
                          :value="rha_bill_clone.rha_folio"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <ViewData
                          label="Doc. ID"
                          :value="rha_bill_clone.rha_bill_folio"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="12">
                        <v-text-field
                          :label="`Monto (Máx ${numberFormat(
                            rha_bill_clone.rha_bill_clone_amount
                          )})*`"
                          v-model="rha_bill_clone.pay_amount"
                          type="number"
                          dense
                          min="0"
                          max="1000000"
                          :rules="rules.required"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  color="warning"
                  @click.prevent="rhaBillCloneSubmit"
                >
                  Generar pago
                  <v-icon right> mdi-send </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="rha_amount_dlg" scrollable persistent max-width="600px">
      <v-card
        v-if="rha_amount_dlg"
        tile
        :loading="rha_amount_dlg_ldg"
        :disabled="rha_amount_dlg_ldg"
      >
        <v-toolbar dark dense>
          <v-toolbar-title>EDO CUENTA FACTURA</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="rha_amount_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-form ref="rha_amount_form" lazy-validation>
            <v-row class="pt-5">
              <v-col cols="12" xs="12" md="12">
                <v-text-field
                  label="Monto gastado"
                  v-model="rha_amount.used_amount"
                  type="number"
                  dense
                  min="0"
                  :rules="rules.required"
                />
              </v-col>
              <v-col cols="12" xs="12" md="12">
                <v-file-input
                  v-model="rha_amount.documents[0].file"
                  accept=".xlsx"
                  label="Archivo"
                  dense
                  :rules="rules.fileLmtReq"
                  show-size
                  prepend-icon=""
                  append-icon="$file"
                />
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  color="success"
                  dark
                  small
                  @click.prevent="rhaAmountAdd"
                >
                  Agregar
                  <v-icon right small> mdi-cash-plus </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="rha_status_account_dlg"
      scrollable
      persistent
      max-width="600px"
    >
      <v-card
        v-if="rha_status_account_dlg"
        tile
        :loading="rha_status_account_dlg_ldg"
        :disabled="rha_status_account_dlg_ldg"
      >
        <v-toolbar dark dense>
          <v-toolbar-title>EDO CUENTA FACTURA</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="rha_status_account_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-form ref="rha_status_account_form" lazy-validation>
            <v-row class="pt-5">
              <v-col cols="12" xs="12" md="12">
                <v-autocomplete
                  label="Formato*"
                  v-model="rha_status_account.status_account_format_id"
                  :items="status_account_formats"
                  :loading="status_account_formats_ldg"
                  item-value="id"
                  :item-text="(item) => item.status_account_format"
                  :rules="rules.required"
                  dense
                />
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  color="success"
                  dark
                  small
                  @click.prevent="rhaStatusAccountView"
                >
                  Ver
                  <v-icon right small> mdi-cash-check </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="taxed_dlg" scrollable persistent max-width="300px">
      <v-card tile :disabled="taxed_dlg_ldg" :loading="taxed_dlg_ldg">
        <v-toolbar dark dense>
          <v-toolbar-title> Gravado y Exento </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="taxed_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="taxed" class="pt-5">
          <v-form v-on:submit.prevent ref="taxed_form" lazy-validation>
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  label="Monto gravado"
                  v-model="taxed.taxed_amount"
                  :rules="rules.required"
                  type="number"
                  dense
                  min="0"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Monto excento"
                  v-model="taxed.zero_rate_amount"
                  :rules="rules.required"
                  type="number"
                  dense
                  min="0"
                />
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  dark
                  small
                  color="light-green darken-3"
                  @click.prevent="taxedSave"
                >
                  <v-icon small left> mdi-send </v-icon>
                  Continuar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  rules,
  msgConfirm,
  msgAlert,
  URL_DOCUMENTS,
  URL_PDF,
  dateTimeNow,
  dateTimeToFile,
  base64ToArrayBuffer,
} from "../../control";
import { index, show, storeUpdate } from "../../requests/pageRequest";
import { catalog } from "../../requests/catalogRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import DatePicker from "../../components/DatePicker.vue";
import RhaData from "../../components/RhaData.vue";
import ViewData from "../../components/ViewData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    DatePicker,
    RhaData,
    ViewData,
    FaqDlg,
  },
  data() {
    return {
      user_view: false,
      api: "bills",
      login: this.$store.getters.getLogin,
      url_documents: URL_DOCUMENTS,
      url_pdf: URL_PDF,
      loading: false,
      rules: rules(),
      data_table: [],
      data: null,
      headers: [
        { text: "Folio", filterable: true, value: "folio", width: "50" },
        {
          text: "F. ingreso",
          filterable: true,
          value: "admission_date_time",
          width: "105px",
        },
        { text: "Aseguradora", filterable: true, value: "insurance_name" },
        { text: "Episodio", filterable: true, value: "episode" },
        { text: "ID SM / Póliza", filterable: true, value: "enrollment" },
        { text: "Asegurado", filterable: true, value: "full_name" },
        { text: "Hospital", filterable: true, value: "trade_name" },
        {
          text: "Doc. ID",
          filterable: true,
          value: "rha_bill_document",
        },
        {
          text: "Factura SM",
          filterable: true,
          value: "rha_bill_stamp_folio",
        },
        {
          text: "Obs. admin.",
          filterable: true,
          value: "rha_bill_observation_detail",
        },
        {
          text: "F. registro",
          filterable: true,
          value: "rha_bill_created_at",
        },
        {
          text: "Factura Hospital",
          filterable: true,
          value: "rha_bill_folio",
        },
        {
          text: "F. recepción",
          filterable: true,
          value: "rha_bill_reception_date",
        },
        {
          text: "Monto Factura",
          filterable: true,
          value: "rha_bill_bills_amount",
        },
        {
          text: "Monto N. crédito",
          filterable: true,
          value: "rha_bill_notes_amount",
        },
        {
          text: "Monto a pagar",
          filterable: true,
          value: "rha_bill_pay_amount",
        },
        {
          text: "Fecha promesa de pago",
          filterable: true,
          value: "rha_bill_pay_date",
        },
        {
          text: "Fecha de Pago",
          filterable: true,
          value: "rha_bill_paid_date",
        },
        {
          text: "Monto pagado",
          filterable: true,
          value: "rha_bill_paid_amount",
        },
        {
          text: "Clave rastreo",
          filterable: true,
          value: "rha_bill_payment_item.tracking_code",
        },
        {
          text: "A. Pago",
          filterable: true,
          value: "rha_bill_insurance_pay_amount",
        },
        {
          text: "A. F. registro",
          filterable: true,
          value: "rha_bill_insurance_reception_date_time",
        },
        {
          text: "A. F. Prom. pago",
          filterable: true,
          value: "rha_bill_insurance_pay_date",
        },
        {
          text: "A. F. pago",
          filterable: true,
          value: "rha_bill_insurance_payment_paid_date",
        },
        {
          text: "A. F. rechazo pago",
          filterable: true,
          value: "rha_bill_insurance_payment_refused_date",
        },
        {
          text: "A. N. trámite",
          filterable: true,
          value: "rha_bill_insurance_procedure",
        },
        {
          text: "A. F. pago edo. cuenta",
          filterable: true,
          value: "rha_bill_insurance_paid_date",
        },
        {
          text: "A. pago acumulado",
          filterable: true,
          value: "rha_bill_insurance_paid_amount",
        },
        {
          text: "N. siniestro",
          filterable: true,
          value: "sinester",
        },
        {
          text: "Contratante",
          filterable: true,
          value: "contractor",
        },
        {
          text: "Obs. rechazo",
          filterable: true,
          value: "rha_bill_observation",
        },
        {
          text: "Resp. rechazo",
          filterable: true,
          value: "rha_bill_provider_response",
        },
        {
          text: "Restitución",
          filterable: true,
          value: "insurance_restitution",
        },
        { text: "", filterable: true, value: "select" },
        {
          text: "",
          value: "action",
          sortable: false,
          width: "300px",
        },
        { text: "Descargar", filterable: true, value: "download_docs" },
      ],
      headers_search: [
        {
          text: "F. ingreso",
          filterable: true,
          value: "admission_date_time",
          width: "105px",
        },
        { text: "Asegurado", filterable: true, value: "full_name" },
        { text: "Hospital", filterable: true, value: "trade_name" },
        {
          text: "Doc. ID",
          filterable: true,
          value: "rha_bill_document",
        },
        {
          text: "F. registro",
          filterable: true,
          value: "rha_bill_created_at",
        },
        {
          text: "Factura",
          filterable: true,
          value: "rha_bill_folio",
        },
        {
          text: "Monto factura",
          filterable: true,
          value: "rha_bill_pay_amount",
        },
        {
          text: "F. recepción",
          filterable: true,
          value: "rha_bill_reception_date",
        },
        {
          text: "F. pago",
          filterable: true,
          value: "rha_bill_pay_date",
        },
      ],
      search: {
        folio: "",
        episode: "",
        enrollment: "",
      },
      documentation_dialog: false,
      documentation_dialog_loading: false,
      search_table: "",
      documentation_insurance: null,
      documentation_insurance_dialog: false,
      documentation_insurance_update: false,
      pay_dialog: false,
      pay_dialog_loading: false,
      insurance_pay_dialog: false,
      file_pay_data: false,
      file_pay_generate: false,
      id: null,
      rha_folio: null,
      reception_date: null,
      pay_date: null,
      insurances: [],
      insurances_loading: true,
      insurance_payment_analyze: {
        insurance_id: null,
        document_file_0: null,
      },
      insurance_payments: {},
      filter: null,
      filter_options: [
        { id: 1, value: "RECHAZADOS" },
        { id: 16, value: "RECHAZADOS POR OBSERVACIÓN" },
        { id: 2, value: "ASEGURADORA: PENDIENTES DE FACTURAR | VALIDAR" },
        { id: 3, value: "ASEGURADORA: PENDIENTE DE ENVIO" },
        { id: 4, value: "ASEGURADORA: PAGO PENDIENTE" },
        { id: 15, value: "ASEGURADORA: PAGO RECHAZADO" },
        { id: 5, value: "ASEGURADORA: PENDIENTE COMPLEMENTO DE PAGO" },
        {
          id: 13,
          value: "ASEGURADORA: PENDIENTES DE CARGAR COMPLEMENTO DE PAGO",
        },
        { id: 14, value: "ASEGURADORA: COMPLEMENTO DE PAGO CARGADO" },
        { id: 6, value: "HOSPITAL: PAGOS AL DIA" },
        {
          id: 9,
          value: "HOSPITAL: FACTURAS ENVIADAS A LA ASEGURADORA Y NO PAGADAS",
        },
        { id: 10, value: "PENDIENTES" },
        { id: 8, value: "HOSPITAL: EN PROCESO DE PAGO" },
        { id: 7, value: "CASOS NO TERMINADOS" },
        { id: 12, value: "PENDIENTE COMPLEMENTO DE PAGO" },
        { id: 11, value: "CASOS TERMINADOS" },
      ],
      bill_error: "",
      bill_error_alert: false,
      bill_error_0x006: false,
      bill_error_0x007: false,
      bill_error_id: null,
      amount: null,
      amount_store: false,
      amount_dialog: false,
      amount_dialog_loading: false,
      amount_cpts: [],
      amount_cpts_loading: false,
      amount_documents: [],
      amount_documents_loading: false,
      insurance_pay_dialog_loading: false,
      search_dialog: false,
      search_dialog_loading: false,
      search_data_table: [],
      rha: null,
      rha_id: null,
      complement_dialog: false,
      complement_dialog_loading: false,
      complement: null,
      complement_load: false,
      observation: null,
      bill_observation_dialog: false,
      bill_observation_dialog_loading: false,
      bill_provider_response: null,
      bill_provider_response_dialog: false,
      bill_provider_response_dialog_loading: false,
      provider_validation: false,
      bill_amount_dialog_loading: false,
      bill_amount_dialog: false,
      bill_amount_pay_amount: null,
      bill_amount_pay_amount_with_min: null,
      bill_amount_insurance_pay_amount: null,
      bill_amount_discounts_amount: null,
      bill_amount_observation_bill: null,
      bill_deductibles_amount: null,
      bill_coinsurances_amount: null,
      bill_discount_type_id: null,
      bill_amount_use_insurance_pay_amount: null,
      last_amount: null,
      doc_bill: null,
      video_dialog: false,
      rha_documents_dialog: false,
      rha_documents_dialog_loading: false,
      rha_documents: null,
      survey: null,
      survey_file: false,
      calc_pay_day_dialog_loading: false,
      calc_pay_day_dialog: false,
      observation_detail: null,
      bill_observation_detail_dialog: false,
      bill_observation_detail_dialog_loading: false,
      insurances_multi: [],
      insurances_multi_loading: true,
      insurances_selected: [],
      select_all: true,
      alert_cancel_msg: null,
      alert_cancel: false,
      insurance_payment_dialog_loading: false,
      insurance_payment_dialog: false,
      insurance_payment: null,
      insurance_payment_receipt: null,
      provider_types: [],
      provider_types_loading: true,
      provider_type: null,
      provider_type_dialog: false,
      provider_type_dialog_loading: false,
      rha_admission_types: [],
      rha_admission_types_loading: true,
      rha_bill_search_alert: false,
      rha_bill_search_message: null,
      rha_users: [],
      rha_users_loading: false,
      rha_insurances: [],
      rha_insurances_loading: false,
      rha_providers: [],
      rha_providers_loading: false,
      rha_insured_loading: false,
      rha_store: null,
      rha_insureds: [],
      rha_insureds_dialog: false,
      rha_insureds_dialog_loading: false,
      rha_insured_search_error: false,
      rha_insured_search_fail: false,
      rha_insured_search: null,
      rha_insured_expired: false,
      rha_insured_form: false,
      rha_coinsidents: null,
      rha_coinsidents_dialog: false,
      rha_store_dialog: false,
      rha_store_dialog_loading: false,
      amount_discount_types: [],
      amount_discount_types_loading: true,
      insurance_payments_unknowns: null,
      insurance_payments_unknowns_dialog: false,
      insurance_payments_unknowns_dialog_loading: false,
      rha_admission_date_report: null,
      rha_discharge_report: null,
      rha_report_dialog: false,
      rha_report_dialog_loading: false,
      insurance_complement: null,
      insurance_complement_dialog: false,
      insurance_complement_dialog_loading: false,
      daily_payments: [],
      daily_payments_get: false,
      daily_payments_multi: [
        {
          id: 1,
          name: "PAGADOS POR ASEGURADORA",
        },
        {
          id: 2,
          name: "ENVIADOS Y NO PAGADOS POR ASEGURADORA",
        },
        {
          id: 3,
          name: "SIN ENVIAR A LA ASEGURADORA",
        },
      ],
      rejects_payments_dialog: false,
      insured_name_xml_edit: false,
      insured_name_xml: null,
      bill_comments_pending: 0,
      bill_comments_pending_ldg: true,
      provider_comments_pending: 0,
      provider_comments_pending_ldg: true,
      pending_dlg: false,
      rha_bill_clone: null,
      rha_bill_clone_dialog_loading: false,
      rha_bill_clone_dialog: false,
      find_full_last_name: false,
      rha_amount: null,
      rha_amount_dlg: false,
      rha_amount_dlg_ldg: false,
      status_account_formats: [],
      status_account_formats_ldg: true,
      rha_status_account: null,
      rha_status_account_dlg: false,
      rha_status_account_dlg_ldg: false,
      taxed: null,
      taxed_dlg: false,
      taxed_dlg_ldg: true,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    handleTableColumns() {
      if (!this.user_view) {
        this.headers.splice(
          this.headers.findIndex((v) => v.value === "insurance_name"),
          1
        );
        this.headers.splice(
          this.headers.findIndex(
            (v) => v.value === "rha_bill_insurance_pay_amount"
          ),
          1
        );
        this.headers.splice(
          this.headers.findIndex(
            (v) => v.value === "rha_bill_insurance_reception_date_time"
          ),
          1
        );
        this.headers.splice(
          this.headers.findIndex(
            (v) => v.value === "rha_bill_insurance_pay_date"
          ),
          1
        );
        this.headers.splice(
          this.headers.findIndex(
            (v) => v.value === "rha_bill_insurance_procedure"
          ),
          1
        );
        this.headers.splice(
          this.headers.findIndex(
            (v) => v.value === "rha_bill_insurance_paid_date"
          ),
          1
        );
        this.headers.splice(
          this.headers.findIndex(
            (v) => v.value === "rha_bill_insurance_paid_amount"
          ),
          1
        );
        this.headers.splice(
          this.headers.findIndex(
            (v) => v.value === "rha_bill_observation_detail"
          ),
          1
        );
        this.headers.splice(
          this.headers.findIndex((v) => v.value === "rha_bill_stamp_folio"),
          1
        );
        this.headers.splice(
          this.headers.findIndex((v) => v.value === "contractor"),
          1
        );
        this.headers.splice(
          this.headers.findIndex((v) => v.value === "select"),
          1
        );
        this.headers.splice(
          this.headers.findIndex((v) => v.value === "download_docs"),
          1
        );
      }
    },
    getData() {
      this.loading = true;

      if (this.filter == 6) {
        if (!this.daily_payments_get) {
          this.daily_payments = [1, 2, 3];
          this.daily_payments_get = true;
        }
      } else {
        this.daily_payments = [];
        this.daily_payments_get = false;
      }

      index(`bills/rha`, this.login.token, [
        { name: "user_id", value: this.login.id },
        { name: "filter", value: this.filter },
        { name: "daily_payments", value: this.daily_payments },
        { name: "insurances", value: this.insurances_selected },
      ]).then((response) => {
        this.data_table = response.data;
        this.loading = false;
      });
    },
    billDialog(item, detail) {
      this.$swal
        .fire(
          msgConfirm(
            `¿Confirma la generación de la factura para el ingreso (${
              detail ? item.rha_folio : item.folio
            })?`
          )
        )
        .then((response) => {
          if (response.isConfirmed) {
            this.billStamp(detail ? item.id : item.rha_bill_id, detail);
          }
        });
    },
    billStamp(rha_bill_id, detail) {
      this.loading = true;
      this.bill_error_alert = false;
      this.bill_error_0x006 = false;
      this.bill_error_0x007 = false;
      this.bill_error_id = null;
      this.amount = null;
      this.rha = null;
      this.documentation_dialog_loading = true;

      Axios.get(
        URL_API + "/bills/stamp/" + rha_bill_id,
        headersToken(this.login.token)
      ).then((response) => {
        //console.log("FACTURE: " + response);
        this.$swal.fire(
          msgAlert(
            response.data.status ? "success" : "error",
            response.data.message
          )
        );

        if (response.data.status) {
          this.getData();

          if (detail) {
            this.documentationDialog(rha_bill_id);
          }
        } else {
          if (response.data.facturapi_error) {
            console.log(response.data.facturapi_data);
            this.$swal.fire(
              msgAlert("error", "FACTURAPI: " + response.data.message)
            );
          }

          this.documentation_dialog = false;

          this.bill_error = response.data.message;
          this.bill_error_alert = true;

          if (response.data.code == "0x006" || response.data.code == "0x007") {
            this.bill_error_0x006 =
              response.data.code == "0x006" ? true : false;
            this.bill_error_0x007 =
              response.data.code == "0x007" ? true : false;
            this.bill_error_id = response.data.rha_bill_id;

            const rha_last_amount_id = response.data.rha_last_amount_id;

            show("rha", this.login.token, response.data.rha_id).then(
              (response) => {
                this.rha = response;

                show("rha/amount", this.login.token, rha_last_amount_id).then(
                  (response) => {
                    this.amount = response;
                    this.amount_store = false;
                  }
                );
              }
            );
          }
        }

        this.loading = false;
      });
    },
    documentationDialog(rha_bill_id) {
      this.insured_name_xml_edit = false;
      this.data = null;
      this.rha = null;
      this.last_amount = null;
      this.doc_bill = null;
      this.documentation_dialog_loading = true;
      this.documentation_dialog = true;
      this.provider_validation = false;

      show("bills/rha", this.login.token, rha_bill_id).then((response) => {
        //console.log(response);
        this.data = response;

        if (this.data.stamp_id && !this.data.stamp_folio) {
          Axios.post(
            URL_API + "/bills/folio",
            {
              rha_bill_id: rha_bill_id,
            },
            headersToken(this.login.token)
          ).then((response) => {
            if (response.data.success) {
              console.log(response.data);
              //this.getData();
            }
          });
        }

        if (
          (this.data.provider_validation_1 == 0 ||
            this.data.provider_validation_2 == 0) &&
          this.data.valid_amounts &&
          this.data.valid_receiver_code_taxs &&
          this.data.valid_receiver_names &&
          this.data.authorized_amount &&
          //this.data.bills_with_notes != 0 &&
          this.data.to_stamp == 0 &&
          !this.data.stamp_id
        ) {
          this.provider_validation = true;
        }

        show("rha", this.login.token, this.data.rha_id).then((response) => {
          this.rha = response;

          show("rha/amount/" + this.rha.id + "/last", this.login.token, 1).then(
            (response) => {
              this.last_amount = response;

              if (this.last_amount) {
                show("rha/amount", this.login.token, this.last_amount.id).then(
                  (response) => {
                    this.last_amount = response;
                    this.documentationBillDetail(rha_bill_id);
                  }
                );
              } else {
                this.documentationBillDetail(rha_bill_id);
              }
            }
          );
        });
      });
    },
    documentationBillDetail(rha_bill_id) {
      Axios.get(
        URL_API + "/bills/get/stamp/object/" + rha_bill_id,
        headersToken(this.login.token)
      ).then((response) => {
        //console.log(response.data);

        if (response.data.success) {
          this.doc_bill = response.data.data;
          this.documentation_dialog_loading = false;
        } else {
          this.$swal.fire(msgAlert("error", response.data.message));
        }
      });
    },
    amountUpdate() {
      this.amount = this.last_amount;
      this.amount_store = false;
      this.amount_dialog = true;
    },
    amountStore() {
      this.amount = null;
      this.amount_store = true;
      this.amount_dialog_loading = true;
      this.amount_dialog = true;

      this.amount = {
        rha_id: this.data.rha_id,
        type: 1,
        rha_amount_response_id: 1,
        rha_amount_response: {
          rha_amount_response: "PROCEDE PAGO DIRECTO",
        },
        authorized_amount: "",
        sinester: this.last_amount
          ? this.last_amount.sinester
          : this.rha.sinister_number,
        letter_auth: "",
        cpt_id: this.rha.cpt_id,
        documents: [],
        cpt_letter: this.rha.cpt_letter,
        rha_folio: this.rha.folio,
      };

      this.amount_dialog_loading = false;
    },
    amountDocumentAdd() {
      this.amount.documents.push({
        id: null,
        active: true,
        document_id: null,
        description: "",
        file: null,
      });
    },
    amountDocumentDelete() {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar el documento (${i + 1})?`))
        .then((response) => {
          if (response.isConfirmed) {
            if (this.data.documents[i].id === null) {
              this.data.documents.splice(i, 1);
            } else {
              this.data.documents[i].active = false;
            }
          }
        });
    },
    amountSubmit() {
      if (this.$refs.form_amount.validate()) {
        this.$swal
          .fire(
            msgConfirm(
              `¿Confirma la ${
                this.amount_store ? "creación" : "edición"
              } del registro?`
            )
          )
          .then((response) => {
            if (response.isConfirmed) {
              this.amount_dialog_loading = true;

              storeUpdate(
                "rha/amount",
                this.login.token,
                this.amount.id,
                this.amount,
                this.login.id
              ).then((response) => {
                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );

                if (response.success) {
                  //console.log(response);
                  this.amount = null;
                  this.amount_dialog = false;
                  this.amount_dialog_loading = false;

                  if (this.amount_store && response.success) {
                    this.documentation_dialog_loading = true;
                    const rha_bill_id = this.data.id;
                    this.data.authorized_amount = true;

                    if (
                      this.data.valid_amounts &&
                      this.data.valid_receiver_code_taxs &&
                      this.data.valid_receiver_names &&
                      this.data.authorized_amount &&
                      this.data.provider_validation_1 !== false &&
                      this.data.provider_validation_2 !== false &&
                      this.data.bills_with_notes !== false
                    ) {
                      this.data.to_stamp = true;
                    }

                    storeUpdate(
                      `bills/rha`,
                      this.login.token,
                      null,
                      this.data,
                      this.login.id
                    ).then((response) => {
                      //console.log(response);
                      this.getData();

                      if (response.success) {
                        show(`bills/rha`, this.login.token, rha_bill_id).then(
                          (response) => {
                            //console.log(response);
                            this.data = response;
                            this.documentation_dialog_loading = false;
                          }
                        );
                      } else {
                        console.log(response.message);
                      }
                    });
                  } else {
                    //this.billStamp(this.bill_error_id, false);
                    this.getData();
                  }

                  this.amount_store = false;
                } else {
                  console.log(response.message);
                }
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    documentationInsuranceDialog(rha_bill_id) {
      this.data = null;
      const dateTime = dateTimeNow();

      this.documentation_insurance = {
        rha_bill_id: null,
        insurance_reception_date: dateTime.substring(0, 10),
        insurance_reception_time: dateTime.substring(11, 16),
        insurance_pay_date: dateTime.substring(0, 10),
        insurance_procedure: "",
        insurance_bill_folio: "",
        insurance_restitution: "",
        updated_by_id: this.login.id,
      };
      this.documentation_insurance_update = false;

      show("bills/rha", this.login.token, rha_bill_id).then((response) => {
        //console.log(response);
        this.data = response;
        this.data.insurance_payment_add =
          parseFloat(this.data.insurance_pay_amount) -
            (this.data.insurance_paid_amount
              ? parseFloat(this.data.insurance_paid_amount)
              : 0) >
          0;

        if (this.data.insurance_pay_date) {
          this.data.insurance_pay_date = this.data.insurance_pay_date.substring(
            0,
            10
          );
        }

        this.data.insurance_payments_amount = 0;
        for (const insurance_payment of this.data.insurance_payments) {
          //console.log(insurance_payment.amount);
          this.data.insurance_payments_amount += parseFloat(
            insurance_payment.amount
          );
        }

        this.documentation_insurance.rha_bill_id = rha_bill_id;

        if (this.data.consignment) {
          this.documentation_insurance.insurance_procedure =
            this.data.consignment;
        }

        this.documentation_insurance_dialog = true;
      });
    },
    documentationInsuranceHandle() {
      if (this.$refs.form_documentation_insurance.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma guardar la información?`))
          .then((response) => {
            if (response.isConfirmed) {
              this.loading = true;

              Axios.post(
                URL_API + `/bills/insurance/reception`,
                this.documentation_insurance,
                headersToken(this.login.token)
              ).then((response) => {
                //console.log(response.data);
                this.$swal.fire(
                  msgAlert(
                    response.data.success ? "success" : "error",
                    response.data.message
                  )
                );

                if (response.data.success) {
                  this.getData();
                  this.documentation_insurance_dialog = false;
                } else {
                  console.log(response.data.message);
                }

                this.loading = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    documentationInsuranceUpdate() {
      this.documentation_insurance_update = true;
      this.documentation_insurance = {
        rha_bill_id: this.data.id,
        insurance_reception_date: this.data.insurance_reception_date,
        insurance_reception_time: this.data.insurance_reception_time,
        insurance_pay_date: this.data.insurance_pay_date,
        insurance_procedure: this.data.insurance_procedure,
        insurance_bill_folio: this.data.insurance_bill_folio,
        insurance_restitution: this.data.insurance_restitution,
        updated_by_id: this.login.id,
      };
    },
    payDialog() {
      this.pay_dialog_loading = true;
      this.data = {
        brands: [],
        save: false,
        insurances: [],
        insurance_total: 0,
        insurance_total_bill: 0,
        providers: [],
        provider_total: 0,
        provider_total_bill: 0,
      };

      for (let item of this.data_table) {
        if (item.select) {
          let brand_exist = false;

          let payment = {
            id: null,
            rha_folio: item.folio,
            insurance_name: item.insurance_name,
            hospital_name: item.trade_name,
            created_at: item.rha_bill_created_at,
            reception_date: item.rha_bill_reception_date,
            pay_date: item.rha_bill_pay_date,
            pay_amount: item.rha_bill_pay_amount,
            insurance_pay_amount: item.rha_bill_insurance_pay_amount,
            paid_amount: item.rha_bill_pay_amount,
            rha_bill_id: item.rha_bill_id,
            bank_id: item.provider.bank_id,
            bank_name: item.provider.bank ? item.provider.bank.bank : null,
            account_number: item.provider.bank_id
              ? item.provider.account_number
              : null,
            clabe: item.provider.bank_id ? item.provider.clabe : null,
            rha_bill_document: item.rha_bill_document,
            insurance_id: item.insurance_id,
            provider_id: item.provider_id,
            provider_name: item.provider.trade_name,
            rha_bill_observation_detail: item.rha_bill_observation_detail,
            notes_amount: item.rha_bill_notes_amount,
          };

          for (let i = 0; i < this.data.brands.length; i++) {
            const provider_brand_id = item.provider_brand
              ? item.provider_brand.id
              : payment.bank_id == 1
              ? -1
              : -2;

            if (provider_brand_id == this.data.brands[i].provider_brand_id) {
              brand_exist = true;

              if (item.provider_brand) {
                payment.bank_id = this.data.brands[i].bank_id;
                payment.bank_name = this.data.brands[i].bank_name;
                payment.account_number = this.data.brands[i].account_number;
                payment.clabe = this.data.brands[i].clabe;
              }
              payment.beneficiary_account =
                this.data.brands[i].format == 1
                  ? payment.account_number
                  : payment.clabe;

              this.data.brands[i].payments.push(payment);
              break;
            }
          }

          if (!brand_exist) {
            this.data.brands.push({
              id: null,
              provider_brand_id: item.provider_brand
                ? item.provider_brand.id
                : payment.bank_id == 1
                ? -1
                : -2,
              provider_brand: item.provider_brand
                ? item.provider_brand.provider_brand
                : `SIN CONCENTRADOR (${
                    payment.bank_id == 1 ? "BBVA" : "OTROS"
                  })`,
              payments: [],
              payment_total: 0,
              payment_total_paid: 0,
              payment_total_bill: 0,
              file_generated: false,
              loading: false,
              bank_id: item.provider_brand ? item.provider_brand.bank_id : null,
              bank_name:
                item.provider_brand && item.provider_brand.bank
                  ? item.provider_brand.bank.bank
                  : null,
              account_number: item.provider_brand
                ? item.provider_brand.account_number
                : null,
              clabe: item.provider_brand ? item.provider_brand.clabe : null,
              format: item.provider_brand
                ? item.provider_brand.bank_id == 1
                  ? 1
                  : 2
                : payment.bank_id == 1
                ? 1
                : 2,
            });

            const index = this.data.brands.length - 1;

            if (item.provider_brand) {
              payment.bank_id = this.data.brands[index].bank_id;
              payment.bank_name = this.data.brands[index].bank_name;
              payment.account_number = this.data.brands[index].account_number;
              payment.clabe = this.data.brands[index].clabe;
            }
            payment.beneficiary_account =
              this.data.brands[index].format == 1
                ? payment.account_number
                : payment.clabe;

            this.data.brands[index].payments.push(payment);
          }
        }
      }

      this.payDialogTotals();

      this.data.brands = this.data.brands.sort(
        (a, b) => b.provider_brand_id - a.provider_brand_id
      );

      for (let i = 0; i < this.data.brands.length; i++) {
        if (this.data.brands[i].provider_brand_id < 0) {
          this.data.brands[i].provider_brand_id = null;
        }
      }

      this.pay_dialog = true;
    },
    payDialogTotals() {
      this.pay_dialog_loading = true;

      this.data.insurances = [];
      this.data.insurance_total = 0;
      this.data.insurance_total_bill = 0;
      this.data.providers = [];
      this.data.provider_total = 0;
      this.data.provider_total_bill = 0;

      for (let brand of this.data.brands) {
        brand.payment_total = 0;
        brand.payment_total_paid = 0;
        brand.payment_total_bill = 0;

        for (const payment of brand.payments) {
          brand.payment_total += parseFloat(payment.pay_amount);
          brand.payment_total_paid += parseFloat(payment.paid_amount);
          brand.payment_total_bill += parseFloat(payment.insurance_pay_amount);

          let insurance_exist = false;

          for (let j = 0; j < this.data.insurances.length; j++) {
            if (this.data.insurances[j].id == payment.insurance_id) {
              insurance_exist = true;
              this.data.insurances[j].payment_total += parseFloat(
                payment.paid_amount
              );
              this.data.insurances[j].bill_payment_total += parseFloat(
                payment.insurance_pay_amount
              );

              this.data.insurance_total += parseFloat(payment.paid_amount);
              this.data.insurance_total_bill += parseFloat(
                payment.insurance_pay_amount
              );

              this.data.insurances[j].payments.push(payment);
              break;
            }
          }

          if (!insurance_exist) {
            this.data.insurances.push({
              id: payment.insurance_id,
              name: payment.insurance_name,
              payments: [],
              payment_total: parseFloat(payment.paid_amount),
              bill_payment_total: parseFloat(payment.insurance_pay_amount),
            });

            this.data.insurance_total += parseFloat(payment.paid_amount);
            this.data.insurance_total_bill += parseFloat(
              payment.insurance_pay_amount
            );

            const index = this.data.insurances.length - 1;
            this.data.insurances[index].payments.push(payment);
          }

          let provider_exist = false;

          for (let k = 0; k < this.data.providers.length; k++) {
            if (this.data.providers[k].id == payment.provider_id) {
              provider_exist = true;
              this.data.providers[k].payment_total += parseFloat(
                payment.paid_amount
              );
              this.data.providers[k].bill_payment_total += parseFloat(
                payment.insurance_pay_amount
              );

              this.data.provider_total += parseFloat(payment.paid_amount);
              this.data.provider_total_bill += parseFloat(
                payment.insurance_pay_amount
              );

              this.data.providers[k].payments.push(payment);
              break;
            }
          }

          if (!provider_exist) {
            this.data.providers.push({
              id: payment.provider_id,
              name: payment.provider_name,
              payments: [],
              payment_total: parseFloat(payment.paid_amount),
              bill_payment_total: parseFloat(payment.insurance_pay_amount),
            });

            this.data.provider_total += parseFloat(payment.paid_amount);
            this.data.provider_total_bill += parseFloat(
              payment.insurance_pay_amount
            );

            const index = this.data.providers.length - 1;
            this.data.providers[index].payments.push(payment);
          }
        }
      }

      this.pay_dialog_loading = false;
    },
    downloadFile(stamped_id, extension) {
      this.loading = true;

      Axios.get(
        URL_API + `/bills/stamped/${stamped_id}/files/${extension}/1`,
        headersToken(this.login.token)
      ).then((response) => {
        this.$swal.fire(
          msgAlert(
            response.data.success ? "success" : "error",
            response.data.message
          )
        );

        if (response.data.success) {
          const linkSource = `data:application/${extension};base64,${response.data.data}`;
          const downloadLink = document.createElement("a");
          const fileName = `sm_bill_${stamped_id}.${extension}`;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        } else {
          console.log(response.data.message);
        }

        this.loading = false;
      });
    },
    insurancePaymentDefault() {
      this.insurance_pay_dialog_loading = false;
      this.insurance_payments = [];
      this.insurance_payment_analyze = {
        insurance_id: null,
        document_file_0: null,
      };
      this.file_pay_data = false;
    },
    insurancePaymentClose() {
      this.insurance_pay_dialog = false;
      this.insurancePaymentDefault();
    },
    insurancePaymentAnalyze() {
      this.file_pay_data = false;
      this.insurance_payments = {};

      if (
        this.insurance_payment_analyze.insurance_id != null &&
        this.insurance_payment_analyze.document_file_0 != null
      ) {
        this.insurance_pay_dialog_loading = true;

        let obj = {
          insurance_id: this.insurance_payment_analyze.insurance_id,
          document_file_0: this.insurance_payment_analyze.document_file_0,
        };

        storeUpdate(
          "bills/insurance/payment/analyze",
          this.login.token,
          null,
          obj,
          null
        )
          .then((response) => {
            if (response.success) {
              this.insurance_payments = response.data;

              this.insurance_payments_proceed = true;

              for (let payment of this.insurance_payments) {
                if (!payment.rha_bill_id) {
                  this.insurance_payments_proceed = false;
                  break;
                }
              }

              this.file_pay_data = true;
            } else {
              console.log(e);
              this.$swal.fire(
                msgAlert("error", "Formato de documento invalido")
              );
            }
            this.insurance_pay_dialog_loading = false;
          })
          .catch((e) => {
            console.log(e);
            this.$swal.fire(msgAlert("error", "Formato de documento invalido"));
            this.insurance_pay_dialog_loading = false;
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Selecciona una aseguradora y carga un formato de pago"
          )
        );
      }
    },
    insurancePaymentSave() {
      this.$swal
        .fire(
          msgConfirm(`¿Confirma la actualización de los pagos de aseguradora?`)
        )
        .then((response) => {
          if (response.isConfirmed) {
            this.insurance_pay_dialog_loading = true;
            storeUpdate(
              "bills/insurance/payment/save",
              this.login.token,
              null,
              { payments: this.insurance_payments },
              null
            ).then((response) => {
              //console.log(response);
              this.$swal.fire(
                msgAlert(
                  response.success ? "success" : "error",
                  response.message
                )
              );

              if (response.success) {
                if (this.filter != null) {
                  this.getData();
                }
                this.insurancePaymentClose();
              } else {
                this.bill_error_alert = true;
                console.log(response.message);
              }

              this.insurance_pay_dialog_loading = false;
            });
          }
        });
    },
    insurancePaymentDestroy(v, rha_bill_id) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar el pago ${v.folio}?`))
        .then((response) => {
          if (response.isConfirmed) {
            this.loading = true;

            Axios.post(
              URL_API + `/bills/rha/insurance/payment/destroy`,
              {
                id: v.id,
              },
              headersToken(this.login.token)
            ).then((response) => {
              //console.log(response.data);
              this.$swal.fire(
                msgAlert(
                  response.data.success ? "success" : "error",
                  response.data.message
                )
              );

              if (response.data.success) {
                this.documentationInsuranceDialog(rha_bill_id);
                this.loading = false;
              } else {
                console.log(response.data.message);
              }
            });
          }
        });
    },
    insurancePaymentDialog(v, rha_bill_id) {
      //this.$refs.form_insurance_payment.resetValidation();
      this.insurance_payment_receipt = v != null;
      this.insurance_payment_dialog_loading = false;
      this.insurance_payment_dialog = true;
      this.insurance_payment = {
        payment_check: v ? true : null,
        rha_bill_insurance_payment_id: v ? v.id : null,
        paid_date: v ? v.paid_date : null,
        paid_date_show: v ? v.paid_date : null,
        amount: v ? v.amount : null,
        amount_show: v ? v.amount : null,
        folio: v ? v.folio : null,
        rha_bill_id: rha_bill_id,
        payment_id: v ? v.payment_id : "",
      };
    },
    insurancePaymentSubmit() {
      if (this.$refs.form_insurance_payment.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma esta acción?`))
          .then((response) => {
            if (response.isConfirmed) {
              this.insurance_payment_dialog_loading = true;

              const api = `${URL_API}/bills/${
                this.insurance_payment_receipt
                  ? "stamp/receipt"
                  : "rha/insurance/payment/store"
              }`;

              Axios.post(
                api,
                this.insurance_payment,
                headersToken(this.login.token)
              ).then((response) => {
                //console.log(response.data);
                this.$swal.fire(
                  msgAlert(
                    response.data.success ? "success" : "error",
                    response.data.message
                  )
                );

                if (response.data.success) {
                  this.insurance_payment_dialog = false;
                  this.documentationInsuranceDialog(
                    this.insurance_payment.rha_bill_id
                  );
                  this.getData();
                } else {
                  if (response.data.facturapi_error) {
                    console.log(response.data.facturapi_data);
                    this.$swal.fire(
                      msgAlert("error", "FACTURAPI: " + response.data.message)
                    );
                    this.insurance_payment_dialog_loading = false;
                  }

                  console.log(response.data);
                }
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    brandFileGenerated(index) {
      this.data.brands[index].loading = true;
      this.payDialogTotals();
      this.data.brands[index].file_generated = true;
      this.data.save = true;

      for (const brand of this.data.brands) {
        if (!brand.file_generated) {
          this.data.save = false;
          break;
        }
      }
    },
    brandFileRegenerated(index) {
      this.data.brands[index].file_generated = false;
      this.data.brands[index].loading = false;
      this.data.save = false;
    },
    brandPayments() {
      this.pay_dialog_loading = true;

      Axios.post(
        URL_API + `/bills/rha/payment`,
        this.data,
        headersToken(this.login.token)
      ).then((response) => {
        //console.log(response.data);
        this.$swal.fire(
          msgAlert(
            response.data.success ? "success" : "error",
            response.data.message
          )
        );

        if (response.data.success) {
          this.data = null;
          this.pay_dialog = false;
          this.getData();
        } else {
          console.log(response.data.message);
        }

        this.pay_dialog_loading = false;
      });
    },
    searchDialog() {
      this.rha_bill_search_alert = false;
      this.rha_bill_search_message = null;
      this.search.folio = "";
      this.search.episode = "";
      this.search.enrollment = "";

      this.search_data_table = [];
      this.rha_id = null;

      this.search_dialog = true;
    },
    searchRhaBills() {
      this.search_data_table = [];
      this.rha_id = null;
      this.rha_bill_search_message = null;
      this.rha_bill_search_alert = false;
      let valid = true;

      if (
        this.search.folio == "" &&
        this.search.episode == "" &&
        this.search.enrollment == ""
      ) {
        valid = false;
      }
      if (this.search.folio != "" && this.search.folio.length <= 3)
        valid = false;
      if (this.search.episode != "" && this.search.episode.length <= 3)
        valid = false;
      if (this.search.enrollment != "" && this.search.enrollment.length <= 3)
        valid = false;

      if (valid) {
        this.search_dialog_loading = true;

        index(`bills/rha/search`, this.login.token, [
          { name: "user_id", value: this.login.id },
          { name: "folio", value: this.search.folio },
          { name: "episode", value: this.search.episode },
          { name: "enrollment", value: this.search.enrollment },
        ]).then((response) => {
          this.rha_id = response.rha_id;

          if (this.rha_id) {
            this.search_data_table = response.data;
          } else {
            this.rha_bill_search_message =
              "No se encontraron coincidencias con los parametros ingresados";
            this.rha_bill_search_alert = true;
          }

          this.search_dialog_loading = false;
        });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Al buscar el campo de folio, número de episodio o ID SM / Póliza debe ser mayor a 3 caracteres"
          )
        );
      }
    },
    complementDialog(item) {
      this.complement = {
        rha_bill_id: item.rha_bill_id,
        document_complement_pdf_0: null,
        document_complement_xml_0: null,
        xml: null,
        xml_valid: null,
        sat_status: null,
        bill_exist: null,
        receiver_code_tax: null,
        receiver_name: null,
        amount_valid: null,
        folio: null,
        complement_folio: item.rha_bill_complement_folio,
        url_complement_pdf: item.rha_bill_url_complement_pdf,
        url_complement_xml: item.rha_bill_url_complement_xml,
      };

      this.complement_load = false;
      this.complement_dialog = true;
    },
    complementRead() {
      this.complement_dialog_loading = true;

      storeUpdate(
        `bills/insurance/complement/read`,
        this.login.token,
        null,
        this.complement,
        this.login.id
      ).then((response) => {
        //console.log(response);
        this.complement.xml = response.data.xml;
        this.complement.xml_valid = response.data.xml_valid;
        this.complement.sat_status = response.data.sat_status;
        this.complement.bill_exist = response.data.bill_exist;
        this.complement.receiver_code_tax = response.data.receiver_code_tax;
        this.complement.receiver_name = response.data.receiver_name;
        this.complement.amount_valid = response.data.amount_valid;
        this.complement.folio = response.data.folio;

        this.complement_load = true;
        this.complement_dialog_loading = false;
      });
    },
    complementSave() {
      this.$swal
        .fire(msgConfirm(`¿Confirma la carga del complemento de pago?`))
        .then((response) => {
          if (response.isConfirmed) {
            this.complement_dialog_loading = true;

            storeUpdate(
              `bills/insurance/complement/save`,
              this.login.token,
              null,
              this.complement,
              this.login.id
            ).then((response) => {
              //console.log(response);
              this.$swal.fire(
                msgAlert(
                  response.success ? "success" : "error",
                  response.message
                )
              );

              if (response.success) {
                this.getData();
                this.complement_dialog_loading = false;
                this.complement_dialog = false;
              } else {
                console.log(response.message);
              }
            });
          }
        });
    },
    billObservation() {
      this.bill_observation_dialog_loading = false;
      this.bill_observation_dialog = true;
      this.observation = this.data.observation;
    },
    billObservationUpdate() {
      this.$swal
        .fire(
          msgConfirm(`¿Confirma la actualización de la observación rechazo?`)
        )
        .then((response) => {
          if (response.isConfirmed) {
            this.bill_observation_dialog_loading = true;

            Axios.post(
              URL_API + "/bills/rha/provider/rejection",
              {
                id: this.data.id,
                observation: this.observation,
              },
              headersToken(this.login.token)
            ).then((response) => {
              //console.log(response);
              this.$swal.fire(
                msgAlert(
                  response.data.success ? "success" : "error",
                  response.data.message
                )
              );

              if (response.data.success) {
                this.documentationDialog(this.data.id);
                this.getData();
                this.bill_observation_dialog = false;
                this.bill_observation_dialog_loading = false;
              } else {
                console.log(response.data.message);
              }
            });
          }
        });
    },
    billProviderResponse() {
      this.bill_provider_response_dialog_loading = false;
      this.bill_provider_response_dialog = true;
      this.bill_provider_response = this.data.provider_response;
    },
    billProviderResponseUpdate() {
      this.$swal
        .fire(msgConfirm(`¿Confirma el envio de la respuesta?`))
        .then((response) => {
          if (response.isConfirmed) {
            this.bill_provider_response_dialog_loading = true;

            Axios.post(
              URL_API + "/bills/rha/provider/response",
              {
                id: this.data.id,
                provider_response: this.bill_provider_response,
              },
              headersToken(this.login.token)
            ).then((response) => {
              //console.log(response);
              this.$swal.fire(
                msgAlert(
                  response.data.success ? "success" : "error",
                  response.data.message
                )
              );

              if (response.data.success) {
                this.documentationDialog(this.data.id);
                this.getData();
                this.bill_provider_response_dialog = false;
                this.bill_provider_response_dialog_loading = false;
              } else {
                console.log(response.data.message);
              }
            });
          }
        });
    },
    billAmounts() {
      this.bill_amount_dialog_loading = false;
      this.bill_amount_insurance_pay_amount = this.data.insurance_pay_amount;
      let pay_amount_with_min =
        parseFloat(this.data.bills_amount) -
        parseFloat(this.data.min_discount_amount);

      // pay_amount_with_min = pay_amount_with_min.toString();
      // this.bill_amount_pay_amount_with_min = parseFloat(
      //   pay_amount_with_min.slice(0, pay_amount_with_min.indexOf(".") + 3)
      // );

      this.bill_amount_pay_amount_with_min =
        this.data.rha.provider.bill_discount ||
        this.data.rha.provider.credit_note_after
          ? this.data.pay_amount
          : pay_amount_with_min;

      this.bill_amount_pay_amount = this.bill_amount_pay_amount_with_min;
      this.bill_amount_discounts_amount = this.data.discounts_amount;
      this.bill_amount_observation_bill = this.data.observation_bill;
      this.bill_deductibles_amount = this.data.deductibles_amount;
      this.bill_coinsurances_amount = this.data.coinsurances_amount;
      this.bill_discount_type_id = this.data.rha_bill_discount_type_id;
      this.bill_amount_use_insurance_pay_amount =
        this.data.use_insurance_pay_amount;
      this.bill_amount_dialog = true;
    },
    billAmountsHandle() {
      if (this.$refs.form_bill_amount.validate()) {
        let max_insurance_pay_amount =
          parseFloat(this.bill_amount_insurance_pay_amount) <=
            parseFloat(this.data.bills_amount) &&
          parseFloat(this.bill_amount_pay_amount) <=
            parseFloat(this.data.bills_amount);

        const deductibles_coinsurances =
          parseFloat(this.bill_deductibles_amount) +
          parseFloat(this.bill_coinsurances_amount);

        if (
          parseFloat(this.data.bills_discount_amount) ==
          deductibles_coinsurances
        ) {
          if (max_insurance_pay_amount) {
            this.billAmountsUpdate();
          } else {
            this.maxInsurancePayAmount();
          }
        } else {
          this.$swal
            .fire(
              msgConfirm(
                `La suma del Deducible Obs. y Coaseguro Obs. (${this.numberFormat(
                  deductibles_coinsurances
                )}) es ${
                  parseFloat(this.data.bills_discount_amount) <
                  deductibles_coinsurances
                    ? "mayor"
                    : "menor"
                } al Desc. factura | Condiciones (${this.numberFormat(
                  this.data.bills_discount_amount
                )}) ¿desea continuar?`
              )
            )
            .then((response) => {
              if (response.isConfirmed) {
                if (max_insurance_pay_amount) {
                  this.billAmountsUpdate();
                } else {
                  this.maxInsurancePayAmount();
                }
              }
            });
        }
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    maxInsurancePayAmount() {
      this.$swal
        .fire(
          msgConfirm(
            `El cobro aseguradora y/o el pago hospital supera el monto total de la(s) factura(s) ¿desea continuar?`
          )
        )
        .then((response) => {
          if (response.isConfirmed) {
            this.billAmountsUpdate();
          }
        });
    },
    billAmountsUpdate() {
      this.$swal
        .fire(msgConfirm(`¿Confirma la actualización de los montos?`))
        .then((response) => {
          if (response.isConfirmed) {
            this.bill_amount_dialog_loading = true;
            this.provider_validation = false;

            Axios.post(
              `${URL_API}/rha/bills/change/amounts`,
              {
                rha_bill_id: this.data.id,
                pay_amount: this.bill_amount_pay_amount,
                insurance_pay_amount: this.bill_amount_insurance_pay_amount,
                discounts_amount: this.bill_amount_discounts_amount,
                observation_bill: this.bill_amount_observation_bill,
                deductibles_amount: this.bill_deductibles_amount,
                coinsurances_amount: this.bill_coinsurances_amount,
                rha_bill_discount_type_id: this.bill_discount_type_id,
                use_insurance_pay_amount:
                  this.bill_amount_use_insurance_pay_amount,
              },
              headersToken(this.login.token)
            ).then((resp) => {
              this.$swal.fire(
                msgAlert(
                  resp.data.success ? "success" : "error",
                  resp.data.message
                )
              );

              if (resp.data.success) {
                this.bill_amount_dialog = false;
                this.bill_amount_dialog_loading = false;
                this.documentationDialog(this.data.id);
                this.getData();
              } else {
                console.log(resp.data.message);
                this.bill_amount_dialog_loading = false;
              }
            });

            // this.data.pay_amount = this.bill_amount_pay_amount;
            // this.data.insurance_pay_amount =
            //   this.bill_amount_insurance_pay_amount;
            // this.data.discounts_amount = this.bill_amount_discounts_amount;
            // this.data.observation_bill = this.bill_amount_observation_bill;
            // this.data.deductibles_amount = this.bill_deductibles_amount;
            // this.data.coinsurances_amount = this.bill_coinsurances_amount;
            // this.data.rha_bill_discount_type_id = this.bill_discount_type_id;
            // this.data.to_stamp = 1;

            // storeUpdate(
            //   `bills/rha`,
            //   this.login.token,
            //   null,
            //   this.data,
            //   this.login.id
            // ).then((response) => {
            //   //console.log(response);
            //   this.$swal.fire(
            //     msgAlert(
            //       response.success ? "success" : "error",
            //       response.message
            //     )
            //   );

            //   if (response.success) {
            //     this.bill_amount_dialog = false;
            //     this.bill_amount_dialog_loading = false;
            //     this.documentationDialog(this.data.id);
            //     //this.billStamp(this.data.id, false);
            //     this.getData();
            //   } else {
            //     console.log(response.message);
            //   }
            // });
          }
        });
    },
    rhaDocumentsDialog(rha_id, rha_folio) {
      this.survey = null;
      this.survey_file = false;
      this.rha_documents = null;
      this.rha_documents_dialog_loading = true;
      this.rha_documents_dialog = true;

      show("rha", this.login.token, rha_id).then((response) => {
        this.rha = response;

        show("insureds", this.login.token, this.rha.insured_id).then(
          (response) => {
            const express_letter =
              response && response.contractor
                ? response.contractor.express_letter === 1
                  ? true
                  : false
                : false;

            index(`rha/documents/${rha_id}`, this.login.token, []).then(
              (response) => {
                this.rha_documents = response.data;
                this.rha_documents.rha_folio = rha_folio;
                this.rha_documents.rha_id = rha_id;
                this.rha_documents.express_letter = express_letter;

                show(
                  "rha/visit/survey/response/survey",
                  this.login.token,
                  rha_id
                ).then((response) => {
                  //console.log(response);
                  this.survey = response;

                  if (this.survey.id) {
                    this.survey.survey_stadistic = null;
                    switch (this.survey.survey_stadistic_id) {
                      case 1:
                        this.survey.survey_stadistic = "SE CUENTA CON ENCUESTA";
                        break;
                      case 2:
                        this.survey.survey_stadistic = "RECHAZO Y/O REEMBOLSO";
                        break;
                      case 3:
                        this.survey.survey_stadistic = "CASO AMBULATORIO";
                        break;
                      case 4:
                        this.survey.survey_stadistic =
                          "ALTA EN HORAS INHABILES";
                        break;
                      case 5:
                        this.survey.survey_stadistic =
                          "NO SE CONTESTO ENCUESTA";
                        break;
                      case 6:
                        this.survey.survey_stadistic = "ENCUESTA DIGITAL";
                        break;
                    }

                    if (
                      (this.survey.url &&
                        this.survey.survey_stadistic_id === 1) ||
                      this.survey.survey_stadistic_id === 6
                    ) {
                      this.survey_file = true;
                    }
                  }

                  this.rha_documents_dialog_loading = false;
                });
              }
            );
          }
        );
      });
    },
    calcPayDayDialog() {
      this.calc_pay_day_dialog_loading = false;
      this.calc_pay_day_dialog = true;
    },
    calcPayDay(rha_bill_id, recalculate) {
      this.$swal
        .fire(
          msgConfirm(
            `¿Confirma recalcular el día de pago y recepción al hospital tomando en cuenta la ${
              recalculate ? "fecha de registro" : "fecha y hora actual"
            }?`
          )
        )
        .then((response) => {
          if (response.isConfirmed) {
            //console.log(rha_bill_id + " - " + recalculate);
            this.calc_pay_day_dialog_loading = true;

            Axios.get(
              `${URL_API}/bills/pay/day/${rha_bill_id}/${recalculate}`,
              headersToken(this.login.token)
            ).then((response) => {
              //console.log(response);

              this.$swal.fire(
                msgAlert(
                  response.data.success ? "success" : "error",
                  response.data.message
                )
              );

              if (response.data.success) {
                this.calc_pay_day_dialog = false;
                this.documentationDialog(rha_bill_id);
                this.getData();
              } else {
                this.calc_pay_day_dialog_loading = false;
                console.log(response.data.message);
              }
            });
          }
        });
    },
    billObservationDetail() {
      this.bill_observation_detail_dialog_loading = false;
      this.bill_observation_detail_dialog = true;
      this.observation_detail = this.data.observation_detail;
    },
    billObservationDetailUpdate() {
      this.$swal
        .fire(
          msgConfirm(
            `¿Confirma la actualización de la observación administrativa?`
          )
        )
        .then((response) => {
          if (response.isConfirmed) {
            this.bill_observation_detail_dialog_loading = true;

            this.data.observation_detail = this.observation_detail;

            storeUpdate(
              `bills/rha`,
              this.login.token,
              null,
              this.data,
              this.login.id
            ).then((response) => {
              //console.log(response);
              this.$swal.fire(
                msgAlert(
                  response.success ? "success" : "error",
                  response.message
                )
              );

              if (response.success) {
                this.getData();
                this.bill_observation_detail_dialog = false;
                this.bill_observation_detail_dialog_loading = false;
              } else {
                console.log(response.message);
              }
            });
          }
        });
    },
    BillCancel(rha_bill_id, stamp_id, motive) {
      this.$swal
        .fire(msgConfirm(`¿Confirma la cancelación de la factura?`))
        .then((response) => {
          if (response.isConfirmed) {
            this.documentation_dialog_loading = true;
            Axios.post(
              URL_API + "/bills/stamped/cancel",
              {
                stamp_id: stamp_id,
                motive: motive,
              },
              headersToken(this.login.token)
            ).then((response) => {
              //console.log(response.data);
              this.$swal.fire(
                msgAlert(
                  response.data.success ? "success" : "error",
                  response.data.message
                )
              );
              if (response.data.success) {
                this.alert_cancel_msg = response.data.message;
                this.alert_cancel = true;
                this.documentationDialog(rha_bill_id);
                this.getData();
              }
            });
          }
        });
    },
    selectAll() {
      this.select_all = !this.select_all;

      for (let item of this.data_table) {
        if (!item.rha_bill_payment_item) {
          item.select = this.select_all;
        }
      }
    },
    rhaBillDesactivate(rha_bill_id) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar la documentación?`))
        .then((response) => {
          if (response.isConfirmed) {
            this.documentation_dialog_loading = true;
            Axios.delete(
              URL_API + "/bills/rha/" + rha_bill_id,
              headersToken(this.login.token)
            ).then((response) => {
              // console.log(response.data);
              this.$swal.fire(
                msgAlert(
                  response.data.success ? "success" : "error",
                  response.data.message
                )
              );
              if (response.data.success) {
                this.alert_cancel_msg = response.data.message;
                this.alert_cancel = true;
                this.documentation_dialog = false;
                this.getData();
              }
            });
          }
        });
    },
    providerTypeDialog() {
      this.provider_type = {
        id: this.data.id,
        provider_type_id: this.data.provider_type_id,
      };
      this.provider_type_dialog_loading = false;
      this.provider_type_dialog = true;
    },
    providerType() {
      if (this.$refs.form_provider_types.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma la guardar el cambio?`))
          .then((response) => {
            if (response.isConfirmed) {
              this.provider_type_dialog_loading = true;

              Axios.post(
                URL_API + "/bills/set/provider_type",
                this.provider_type,
                headersToken(this.login.token)
              ).then((response) => {
                // console.log(response.data);
                this.$swal.fire(
                  msgAlert(
                    response.data.success ? "success" : "error",
                    response.data.message
                  )
                );
                if (response.data.success) {
                  this.documentationDialog(this.data.id);
                  this.provider_type_dialog = false;
                  this.provider_type_dialog_loading = false;
                }
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    rhaUserInsurances() {
      index(
        "user/" + this.rha_store.user_id + "/insurances",
        this.login.token,
        []
      ).then((response) => {
        this.rha_insurances = response.data;

        if (this.rha_insurances.length == 1) {
          this.rha_store.insurance_id = this.rha_insurances[0].id;
        }

        this.rha_insurances_loading = false;
      });

      //this.rhaUserProviders();
    },
    rhaUserProviders() {
      this.rha_providers_loading = true;
      this.find_full_last_name = false;

      Axios.get(
        URL_API + "/insurances/" + this.rha_store.insurance_id,
        headersToken(this.login.token)
      ).then((response) => {
        this.find_full_last_name = response.data.data.find_full_last_name;

        index(
          "user/" + this.rha_store.user_id + "/providers",
          this.login.token,
          []
        ).then((response) => {
          this.rha_providers = response.data;

          if (this.rha_providers.length == 1) {
            this.rha_store.provider_id = this.rha_providers[0].id;
          }

          this.rha_providers_loading = false;
        });
      });
    },
    rhaStoreDialog() {
      this.rha_store = {
        id: null,
        user_id: null,
        insurance_id: null,
        provider_id: null,
        insured_id: null,
        insured: null,
        enrollment: "",
        name: "",
        first_surname: "",
        second_surname: "",
        policy: "",
        credential: "",
        contractor: "",
        policyholder: true,
        dependent_name: "",
        dependent_first_surname: "",
        dependent_second_surname: "",

        doctor_id: null,
        doctor_name: "",
        doctor_first_surname: "",
        doctor_second_surname: "",
        specialty_type_id: -1,
        rha_admission_type_id: null,
        admission_date: "",
        admission_time: "",
        diagnosis: "",
        process: "",
        room: "",
        email: "",
        phone: "",
        programmed: 0,
        programmed_active: 0,
        documents: [],
        petition: 0,
        sinister_number: "",
        episode: this.search.episode,
        rha_type_id: 1,
      };

      this.rha_insured_search = {
        enrollment: "",
        name: "",
        first_surname: "",
        second_surname: "",
        insurance_id: null,
        user_id: this.login.id,
      };

      this.rha_insured_search_error = false;
      this.rha_insured_search_fail = false;
      this.rha_coinsidents = null;
      this.rha_coinsidents_dialog = false;
      this.rha_insureds = [];
      this.rha_insureds_dialog = false;
      this.rha_insureds_dialog_loading = false;
      this.rha_insured_expired = false;
      this.rha_insured_form = false;

      if (this.login.role_id === 1) {
        index("general/users", this.login.token, []).then((response) => {
          this.rha_users = response.data;
          this.rha_users_loading = false;
        });
      } else {
        this.rha_store.user_id = this.login.id;
        this.rhaUserInsurances();
        this.$forceUpdate();
      }

      this.rha_store_dialog_loading = false;
      this.rha_store_dialog = true;
    },
    rhaInsuredData(id) {
      this.rha_insureds_dialog = false;
      this.rha_coinsidents_dialog = false;
      this.rha_insured_loading = true;

      show("insureds", this.login.token, id).then((response) => {
        this.rha_store.insured = response;
        this.rha_store.insured_id = response.id;
        this.rha_store.enrollment = response.enrollment;
        this.rha_store.name = response.name;
        this.rha_store.first_surname = response.first_surname;
        this.rha_store.second_surname = response.second_surname;
        this.rha_store.policy = response.policy;
        this.rha_store.credential = response.credential;
        this.rha_store.contractor = response.contractor.name;
        this.rha_insured_expired = response.expired;

        this.rha_insured_loading = false;
      });
    },
    rhaInsuredForm() {
      this.rha_insureds_dialog = false;
      this.rha_store.enrollment = this.rha_insured_search.enrollment;
      this.rha_store.name = this.rha_insured_search.name;
      this.rha_store.first_surname = this.rha_insured_search.first_surname;
      this.rha_store.second_surname = this.rha_insured_search.second_surname;
      this.rha_store.insured_id = -1;

      this.rha_insured_form = true;
    },
    rhaInsuredSearch() {
      this.rha_coinsidents_dialog = false;

      if (
        this.rha_insured_search.enrollment != "" ||
        this.rha_insured_search.name != "" ||
        this.rha_insured_search.first_surname != "" ||
        this.rha_insured_search.second_surname != ""
      ) {
        this.rha_insured_loading = true;
        this.rha_insured_search_fail = false;
        this.rha_insured_search.insurance_id = this.rha_store.insurance_id;

        Axios.post(
          URL_API + "/insureds/search",
          this.rha_insured_search,
          headersToken(this.login.token)
        ).then((response) => {
          this.rha_insureds = response.data.data;

          if (this.rha_insureds.length === 1) {
            this.rhaInsuredData(this.rha_insureds[0].id);
          } else {
            if (this.rha_insureds.length === 0) {
              this.rha_insured_search_fail = true;
            } else {
              this.rha_insureds_dialog = true;
            }
          }

          this.rha_insured_loading = false;
        });
      } else {
        this.$swal.fire(
          msgAlert(
            "warning",
            "Utiliza al menos un campo para la búsqueda de asegurado"
          )
        );
      }
    },
    rhaSearchRhaCoinsidents() {
      this.rha_insured_search.insurance_id = this.rha_store.insurance_id;

      Axios.post(
        URL_API + "/rha/insured/search",
        this.rha_insured_search,
        headersToken(this.login.token)
      ).then((response) => {
        //console.log(response.data.data);
        this.rha_insured_search_error = false;
        this.rha_coinsidents = response.data.data;

        if (this.rha_coinsidents.length > 0) {
          this.rha_coinsidents_dialog = true;
          this.rha_insured_loading = false;
        } else {
          this.rhaInsuredSearch();
        }
      });
    },
    rhaInsuredRemove() {
      this.rha_insured_form = false;

      this.rha_store.insured = null;
      this.rha_store.insured_id = null;
      this.rha_store.enrollment = "";
      this.rha_store.name = "";
      this.rha_store.first_surname = "";
      this.rha_store.second_surname = "";
      this.rha_store.policy = "";
      this.rha_store.credential = "";
      this.rha_store.contractor = "";
      this.rha_store.policyholder = true;
      this.rha_store.dependent_name = "";
      this.rha_store.dependent_first_surname = "";
      this.rha_store.dependent_second_surname = "";
    },
    rhaInsuredSearchHandle() {
      this.rha_insured_search_error = false;
      this.rha_insured_search_fail = false;
      this.rha_insured_loading = true;

      if (this.rha_insured_search.enrollment) {
        this.rhaSearchRhaCoinsidents();
      } else {
        if (
          this.rha_insured_search.name &&
          this.rha_insured_search.first_surname
        ) {
          if (this.find_full_last_name) {
            if (this.rha_insured_search.second_surname) {
              this.rhaSearchRhaCoinsidents();
            } else {
              this.rha_insured_search_error = true;
              this.rha_insured_loading = false;
            }
          } else {
            this.rhaSearchRhaCoinsidents();
          }
        } else {
          this.rha_insured_search_error = true;
          this.rha_insured_loading = false;
        }
      }
    },
    rhaStore() {
      if (this.$refs.form_rha_store.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma crear el ingreso hospitalario?`))
          .then((response) => {
            if (response.isConfirmed) {
              this.rha_store_dialog_loading = true;

              storeUpdate(
                "rha/store/bills",
                this.login.token,
                null,
                this.rha_store,
                this.login.id
              ).then((response) => {
                //console.log(response);
                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );
                if (response.success) {
                  this.search = {
                    folio: "",
                    episode: this.rha_store.episode,
                    enrollment: "",
                  };
                  this.searchRhaBills();

                  this.rha_store_dialog_loading = false;
                  this.rha_store_dialog = false;
                }
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    insurancePaymentsUnknownsGet() {
      Axios.get(
        URL_API + "/bills/rha/insurance/payments/unknowns",
        headersToken(this.login.token)
      ).then((response) => {
        // console.log(response.data);
        this.insurance_payments_unknowns = response.data.data;
        this.insurance_payments_unknowns_dialog_loading = false;
      });
    },
    insurancePaymentsUnknowns() {
      this.insurance_payments_unknowns = null;
      this.insurance_payments_unknowns_dialog = true;
      this.insurance_payments_unknowns_dialog_loading = true;

      this.insurancePaymentsUnknownsGet();
    },
    insurancePaymentsUnknownsDelete() {
      let insurance_payments_unknowns = [];

      for (const insurance_payment_unknown of this
        .insurance_payments_unknowns) {
        if (insurance_payment_unknown.select) {
          insurance_payments_unknowns.push({
            id: insurance_payment_unknown.id,
          });
        }
      }

      if (insurance_payments_unknowns.length > 0) {
        this.$swal
          .fire(msgConfirm(`¿Confirma eliminar los elementos seleccionados?`))
          .then((response) => {
            if (response.isConfirmed) {
              this.insurance_payments_unknowns_dialog_loading = true;
              this.insurance_payments_unknowns = null;

              Axios.post(
                URL_API + "/bills/rha/insurance/payments/unknowns/delete",
                {
                  insurance_payments_unknowns: insurance_payments_unknowns,
                },
                headersToken(this.login.token)
              ).then((response) => {
                console.log(response.data.data);

                this.$swal.fire(
                  msgAlert(
                    response.data.success ? "success" : "error",
                    response.data.success
                      ? "Acción ejecutada correctamente"
                      : "ERROR: " + response.data.message
                  )
                );

                this.insurancePaymentsUnknownsGet();
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Ningun elemento ha sido seleccionado para eliminar"
          )
        );
      }
    },
    rhaReportDialog() {
      this.rha_admission_date_report = this.data.rha.admission_date_report;
      this.rha_discharge_report = this.data.rha.discharge_report;
      this.rha_report_dialog = true;
      this.rha_report_dialog_loading = false;
    },
    rhaReport() {
      if (this.$refs.form_rha_report.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma la guardar los cambios?`))
          .then((response) => {
            if (response.isConfirmed) {
              this.rha_report_dialog_loading = true;

              let obj = Object.assign({}, this.data.rha);
              obj.admission_date_report = this.rha_admission_date_report;
              obj.discharge_report = this.rha_discharge_report;

              storeUpdate(
                "rha",
                this.login.token,
                obj.id,
                obj,
                this.login.id
              ).then((response) => {
                // console.log(response.data);
                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );
                if (response.success) {
                  this.documentationDialog(this.data.id);
                  this.rha_report_dialog = false;
                  this.rha_report_dialog_loading = false;
                }
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    insuranceComplementDialog() {
      this.insurance_complement = null;
      this.insurance_complement_dialog = true;
      this.insurance_complement_dialog_loading = false;
    },
    insuranceComplement() {
      if (this.$refs.form_insurance_complement.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma la guardar los cambios?`))
          .then((response) => {
            if (response.isConfirmed) {
              this.insurance_complement_dialog_loading = true;

              Axios.post(
                URL_API + "/bills/rha/provider/complement",
                {
                  id: this.data.id,
                  insurance_complement: this.insurance_complement,
                },
                headersToken(this.login.token)
              ).then((response) => {
                // console.log(response.data);
                this.$swal.fire(
                  msgAlert(
                    response.data.success ? "success" : "error",
                    response.data.message
                  )
                );
                if (response.data.success) {
                  this.getData();
                  this.documentationInsuranceDialog(this.data.id);
                  this.insurance_complement_dialog = false;
                  this.insurance_complement_dialog_loading = false;
                }
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    setConsigmentVerify() {
      this.$swal
        .fire(msgConfirm(`¿Confirma la validación de la documentación?`))
        .then((response) => {
          if (response.isConfirmed) {
            this.documentation_dialog_loading = true;

            Axios.post(
              URL_API + "/bills/rha/consignment/verify",
              {
                id: this.data.id,
                consignment_verify: 1,
              },
              headersToken(this.login.token)
            ).then((resp) => {
              this.$swal.fire(
                msgAlert(
                  resp.data.success ? "success" : "error",
                  resp.data.message
                )
              );

              if (resp.data.success) {
                this.documentationDialog(this.data.id);
              } else {
                console.log(resp.data.message);
                this.documentation_dialog_loading = false;
              }
            });
          }
        });
    },
    deleteRhaBillInsurancePayment() {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar la documentación aseguradora?`))
        .then((response) => {
          if (response.isConfirmed) {
            this.loading = true;

            Axios.post(
              URL_API + "/bills/rha/insurance/payment/delete",
              { id: this.data.id },
              headersToken(this.login.token)
            ).then((response) => {
              this.$swal.fire(
                msgAlert(
                  response.data.success ? "success" : "error",
                  response.data.message
                )
              );
              if (response.data.success) {
                this.getData();
                this.documentationDialog(this.data.id);
              } else {
                this.loading = false;
              }
            });
          }
        });
    },
    downloadZip() {
      let rha_bills = [];

      for (let item of this.data_table) {
        if (item.download_docs) {
          rha_bills.push(item.rha_bill_id);
        }
      }

      if (rha_bills.length > 0) {
        this.loading = true;

        Axios.post(
          URL_API + "/rha/bills/zip",
          {
            rha_bills: rha_bills,
            filter: this.filter,
          },
          headersToken(this.login.token)
        ).then((response) => {
          this.$swal.fire(
            msgAlert(
              response.data.success ? "success" : "error",
              response.data.message
            )
          );
          if (response.data.success) {
            window.open(
              this.url_documents + "/zips/" + response.data.data,
              "_blank"
            );
          }

          this.loading = false;
        });
      } else {
        this.$swal.fire(
          msgAlert("error", "Selecciona al menos un caso para descargar")
        );
      }
    },
    insuredNameXmlEdit(insured_name_xml) {
      this.insured_name_xml = insured_name_xml;
      this.insured_name_xml_edit = true;
    },
    insuredNameXmlSave(rha_bill_id) {
      if (this.$refs.insured_name_xml_form.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma actualizar el nombre?`))
          .then((resp) => {
            if (resp.isConfirmed) {
              this.documentation_dialog_loading = true;

              Axios.post(
                `${URL_API}/bills/rha/change/insured/name/xml`,
                {
                  rha_bill_id: rha_bill_id,
                  insured_name_xml: this.insured_name_xml,
                },
                headersToken(this.login.token)
              ).then((resp) => {
                this.$swal.fire(
                  msgAlert(
                    resp.data.success ? "success" : "error",
                    resp.data.message
                  )
                );

                if (resp.data.success) {
                  this.doc_bill.observations.full_name = this.insured_name_xml
                    .trim()
                    .toUpperCase();
                  this.insured_name_xml = null;
                  this.insured_name_xml_edit = false;
                } else {
                  console.log(resp.data.message);
                }

                this.documentation_dialog_loading = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    docExtDiplay(item, table_name) {
      this.rha_documents_dialog_loading = true;

      Axios.post(
        URL_API + "/rha/documents/external/display",
        {
          id: item.id,
          table_name: table_name,
          external_display: item.external_display,
        },
        headersToken(this.login.token)
      ).then((resp) => {
        this.$swal.fire(
          msgAlert(resp.data.success ? "success" : "error", resp.data.message)
        );

        this.rha_documents_dialog_loading = false;
      });
    },
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    createXlsx() {
      this.loading = true;

      if (this.filter == 6) {
        if (!this.daily_payments_get) {
          this.daily_payments = [1, 2, 3];
          this.daily_payments_get = true;
        }
      } else {
        this.daily_payments = [];
        this.daily_payments_get = false;
      }

      index(`bills/rha/filter/report`, this.login.token, [
        { name: "user_id", value: this.login.id },
        { name: "filter", value: this.filter },
        { name: "daily_payments", value: this.daily_payments },
        { name: "insurances", value: this.insurances_selected },
      ]).then((res) => {
        if (res.success) {
          this.fileDownload(res.data, "xlsx", "ingresos");
          this.loading = false;
        } else {
          this.loading = false;
          this.$swal.fire(msgAlert("error", "Error al generar archixo XLSX"));
        }
      });
    },
    fileDownload(base64, ext, name) {
      const blob = new Blob([base64ToArrayBuffer(base64)], {
        type: "application/" + ext,
      });
      let downloadLink = document.createElement("a");
      downloadLink.setAttribute("target", "_blank");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = "SM_" + name + "_" + dateTimeToFile() + "." + ext;
      downloadLink.click();
    },
    rhaBillCloneDialog(item) {
      this.rha_bill_clone = {
        rha_folio: item.folio,
        rha_bill_folio: item.rha_bill_document,
        rha_bill_clone_amount: parseFloat(item.rha_bill_clone_amount),
        rha_bill_id: item.rha_bill_id,
        pay_amount: "0",
      };
      this.rha_bill_clone_dialog_loading = false;
      this.rha_bill_clone_dialog = true;
    },
    rhaBillCloneSubmit() {
      if (this.$refs.rha_bill_clone_form.validate()) {
        if (
          parseFloat(this.rha_bill_clone.pay_amount) <=
          this.rha_bill_clone.rha_bill_clone_amount + 1
        ) {
          this.$swal
            .fire(msgConfirm(`¿Confirma la generación del nuevo pago?`))
            .then((resp) => {
              if (resp.isConfirmed) {
                this.rha_bill_clone_dialog_loading = true;

                Axios.post(
                  `${URL_API}/rha/bills/clone`,
                  this.rha_bill_clone,
                  headersToken(this.login.token)
                ).then((resp) => {
                  this.$swal.fire(
                    msgAlert(
                      resp.data.success ? "success" : "error",
                      resp.data.message
                    )
                  );

                  if (resp.data.success) {
                    this.rha_bill_clone_dialog = false;
                    this.filter = 6;
                    this.getData();
                  } else {
                    console.log(resp.data.message);
                  }

                  this.rha_bill_clone_dialog_loading = false;
                });
              }
            });
        } else {
          this.$swal.fire(
            msgAlert(
              "warning",
              "El monto máximo a pago es de " +
                this.numberFormat(this.rha_bill_clone.rha_bill_clone_amount)
            )
          );
        }
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    rhaAmountDlg() {
      this.rha_amount = {
        rha_id: this.data.rha.id,
        cpt_id: null,
        icd_id: null,
        sinester: this.data.rha.sinister_number,
        documents: [
          {
            id: null,
            active: true,
            rha_amount_document_type_id: "",
            document_id: 18,
            description: "EDO. CUENTA FACTURA",
            file: null,
          },
        ],
        type: 2,
        used_amount: "",
      };
      this.rha_amount_dlg_ldg = false;
      this.rha_amount_dlg = true;
    },
    rhaAmountAdd() {
      if (this.$refs.rha_amount_form.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma agregar el monto gastado?`))
          .then((res) => {
            if (res.isConfirmed) {
              this.rha_amount_dlg_ldg = true;

              storeUpdate(
                "rha/amount",
                this.login.token,
                null,
                this.rha_amount,
                this.login.id
              ).then((res) => {
                this.$swal.fire(
                  msgAlert(res.success ? "success" : "error", res.message)
                );

                if (res.success) {
                  this.data.rha_amount_bill_status_account =
                    res.data.rha_amount_bill_status_account;
                  this.rha_amount_dlg = false;
                } else {
                  console.log(res.message);
                }

                this.rha_amount_dlg_ldg = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    rhaStatusAccountDlg() {
      this.rha_status_account = {
        rha_amount_id: this.data.rha_amount_bill_status_account.rha_amount_id,
        status_account_format_id: null,
      };
      this.rha_status_account_dlg_ldg = false;
      this.rha_status_account_dlg = true;
    },
    rhaStatusAccountView() {
      if (this.$refs.rha_status_account_form.validate()) {
        window.open(
          this.url_pdf +
            "/rha/amount/" +
            this.rha_status_account.rha_amount_id +
            "/benefits/format/" +
            this.rha_status_account.status_account_format_id,
          "_blank"
        );
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    taxedDlg() {
      this.taxed = {
        id: this.data.id,
        taxed_amount:
          this.data.taxed_amount == null ? "0" : this.data.taxed_amount + "",
        zero_rate_amount:
          this.data.zero_rate_amount == null
            ? "0"
            : this.data.zero_rate_amount + "",
      };
      this.taxed_dlg_ldg = false;
      this.taxed_dlg = true;
    },
    taxedSave() {
      if (this.$refs.taxed_form.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma guardar la información?`))
          .then((res) => {
            if (res.isConfirmed) {
              this.taxed_dlg_ldg = true;

              Axios.post(
                URL_API + "/rhas/bills/taxed/amount",
                this.taxed,
                headersToken(this.login.token)
              ).then((res) => {
                this.$swal.fire(
                  msgAlert(
                    res.data.success ? "success" : "error",
                    res.data.message
                  )
                );

                if (res.data.success) {
                  const is_null =
                    parseFloat(this.taxed.taxed_amount) == 0 &&
                    parseFloat(this.taxed.zero_rate_amount) == 0;

                  this.data.taxed_amount = is_null
                    ? null
                    : this.taxed.taxed_amount;
                  this.data.zero_rate_amount = is_null
                    ? null
                    : this.taxed.zero_rate_amount;
                  this.taxed_dlg = false;
                } else {
                  console.log(res.data.message);
                }

                this.taxed_dlg_ldg = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    catalog("status_account_formats", this.login.token).then((res) => {
      this.status_account_formats = res;
      this.status_account_formats_ldg = false;
    });

    index("insurances", this.login.token, []).then((response) => {
      this.insurances = response.data;
      this.insurances_multi = response.data;

      this.insurances_loading = false;
      this.insurances_multi_loading = false;
    });
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.rha_folio = this.$route.params.rha_folio;
      this.pay_date = this.$route.params.pay_date;
      this.reception_date = this.$route.params.reception_date;
    }

    catalog("provider_types ", this.login.token).then((response) => {
      this.provider_types = response;
      this.provider_types_loading = false;
    });

    catalog("rha_admission_types", this.login.token).then((response) => {
      this.rha_admission_types = response;
      this.rha_admission_types.splice(4, 1);
      this.rha_admission_types_loading = false;
    });

    this.user_view = this.login.role_id < 4 ? true : false;

    this.handleTableColumns();

    if (this.user_view) {
      let type_bill = this.$route.name;
      type_bill = type_bill.split(".");
      type_bill = type_bill[0];
      type_bill = type_bill == "facturacion" ? 1 : 2;

      Axios.get(
        URL_API + "/bill_comments/total_pending?type_bill=" + type_bill,
        headersToken(this.login.token)
      ).then((res) => {
        this.bill_comments_pending = res.data.total_pending;
        this.bill_comments_pending_ldg = false;

        Axios.get(
          URL_API + "/providers/comments/total_pending",
          headersToken(this.login.token)
        ).then((resp) => {
          this.provider_comments_pending = resp.data.total_pending;
          this.provider_comments_pending_ldg = false;

          if (
            this.bill_comments_pending > 0 ||
            this.provider_comments_pending > 0
          ) {
            this.pending_dlg = true;
          }
        });
      });

      index("insurances", this.login.token, [
        {
          name: "active",
          value: 1,
        },
        {
          name: "id",
          value: this.login.id,
        },
      ]).then((response) => {
        this.insurances = response.data;
        this.insurances_loading = false;
      });

      this.amount_cpts_loading = true;
      catalog("cpts", this.login.token).then((response) => {
        this.amount_cpts = response;
        this.amount_cpts_loading = false;
      });

      catalog("rha_bill_discount_types", this.login.token).then((response) => {
        this.amount_discount_types = response;
        this.amount_discount_types_loading = false;
      });

      this.amount_documents_loading = true;
      index("documents/2", this.login.token, []).then((response) => {
        this.amount_documents = response.data;
        this.amount_documents_loading = false;
      });
    } else {
      this.filter_options = [
        { id: 1, value: "EN REVISIÓN Y RECHAZADOS" },
        { id: 16, value: "RECHAZADOS POR OBSERVACIÓN" },
        { id: 10, value: "PENDIENTES" },
        { id: 7, value: "PAGADOS | PENDIENTE COMPLEMENTO DE PAGO" },
        { id: 11, value: "TERMINADOS" },
      ];

      this.filter = 10;

      this.getData();
    }
  },
};
</script>

<style scoped>
.row-refused {
  background-color: rgba(255, 0, 0, 0.752);
}
</style>